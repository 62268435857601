import React from "react";
import { Link, useParams } from "react-router-dom";
import Search from "../components/Search";
import { useNavigate } from "react-router-dom";
import BlueButton from "../components/BlueButton";
import { addGuide } from "../../../constants/urls";
import { useState, useEffect } from "react";
import CopyId from "../components/CopyId";
import { guideDetail, hobbies } from "../../../constants/urls";
import FourBoxDetails1 from "../components/FourBoxDetails1";
import Description from "../components/Description";
import LanguageProvider from "../sepcomponents/LanguageDropdownController";
import LanguageDropdown from "../components/LanguageDropdown";
import { PlaceListProvider } from "../../../controllers/PlaceList_controller";
import PlaceDropdownRelatedCity from "../components/PlaceDropdownRelatedCity";
import Popup from "../components/Popup";
import { CityList } from "../../../constants/urls";
import CityDropdown from "../components/CityDropdown";
import { CityProvider } from "../../../controllers/city_controllers";

export default function GuideDetails() {
  const { guide_id } = useParams();
  const [guideData, setGuideData] = useState([]);
  const [description, setCityDescription] = useState("");
  const [hobbiesArray, setHobbiesArray] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [Hobbies, setHobbies] = useState([]);
  const [places, setPlaces] = useState([]);
  const [clickGuide, setClickGuide] = useState(false);
  const [cityId, setCityId] = useState("");
  const [cityName, setCityName] = useState("");

  const apiUrl = `${guideDetail}${guide_id}`;

  const [isEditing, setIsEditing] = useState(false);
  const handleSaveButtonClick = () => {
    setIsEditing(false);
  };

  const handleCityNameChange = (e) => {
    setCityName(e.target.value);
  };

  const handlePlacesChange = (selectedPlaces) => {
    const updatedTags = selectedPlaces.filter((selectedOption) => {
      return selectedPlaces.some(
        (option) => option.value === selectedOption.value
      );
    });
    setPlaces(updatedTags);
    console.log(places);
  };
  const handleSaveAndClose = () => {
    saveDetails();
  };
  const handleLanguagesChange = (selectedLanguage) => {
    setLanguages(selectedLanguage);
  };

  const closeForm = () => {
    setClickGuide(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // setIsLoading(true);

    // await postImages();
    // if (placeId === "") {
    //   randomPlaceId = generateRandomPlaceId();
    //   setPlaceId(randomPlaceId);
    // }
    // // handlePlaceIdChange(e);
    // const formData = new FormData();
    // formData.append("city", cityId);
    // formData.append("name", placeName);
    // formData.append("place_id", placeId || randomPlaceId);
    // formData.append("description", cityDescription);
    // formData.append("meta_data", metaData);

    // formData.append("lat", lat);
    // formData.append("lon", lon);
    // // formData.append("image", imageIds);
    // imageIds.forEach((tag) => formData.append("image", tag));
    // tagsArray.forEach((tag) => formData.append("tags", tag));
    // actArray.forEach((activity) => formData.append("activities", activity));

    // const requestOptions = {
    //   method: "POST",
    //   headers: {
    //     // "Content-Type": "application/json",
    //     Authorization: `Token ${localStorage.getItem("token")}`,
    //   },
    //   body: formData,
    //   redirect: "follow",
    // };

    // try {
    //   const response = await fetch(
    //     "https://orca-app-pgxkr.ondigitalocean.app/data/place/",
    //     requestOptions
    //   );

    //   if (response.ok) {
    //     // Handle a successful response here
    //     const result = await response.text();
    //     console.log("Success:", result);
    //     setIsLoading(false);
    //     navigate("/places");
    //   } else {
    //     const errorText = await response.text();
    //     console.error("Error:", errorText);
    //   }
    // } catch (error) {
    //   console.error("Error:", error);
    // }
  };

  const placeArray = places.map((tag) => tag.value);
  const hobbyArray = Hobbies.map((tag) => tag.value);
  const langArray = languages.map((tag) => tag.value);
  const handleHobbiesChange = (selectedHobbies) => {
    setHobbies(selectedHobbies);
    console.log(Hobbies);
  };
  const saveDetails = (event) => {
    const formData = {
      bio: description,
      knows_languages: langArray,
      places_to_visit: placeArray,
      // hobbies: hobbyArray,
    };
    console.log(formData);

    fetch(`${addGuide}${guide_id}/`, {
      method: "PATCH",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        fetchInfo();
        console.log("Posted");
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setIsEditing(false);
  };
  let navigate = useNavigate();

  async function fetchInfo() {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        `Token ${localStorage.getItem("token")}`
      );
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(apiUrl, requestOptions);
      const result = await response.json();
      const hobbiesString = result.hobbies;
      const hobbies_array = JSON.parse(hobbiesString);
      if (response.ok) {
        setGuideData(result);
        console.log(result);
        setCityDescription(result.bio);
        setHobbiesArray(hobbies_array);
        setPlaces(result.places);
        setLanguages(result.languages);
      } else {
        console.log(`Error: ${result.error}`);
      }
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }
  useEffect(() => {
    let isMounted = true;
    fetchInfo();

    return () => {
      isMounted = false;
    };
  }, [apiUrl]);


  const handleFormSubmit2 = (formData) => {
    fetch(`${CityList}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Data posted:", data);
        console.log("formdata", formData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="handleBack">
      <CityProvider>
        <div className="ml-60 ">
          <div className="container pt-5 px-12">
            <div className="flex justify-between">
              <div className="">
                <div className="flex">
                  <div>
                    <h1 className="handleHeader">Guide Details</h1>
                    <span className="handleDescription">Details of Guide.</span>
                  </div>
                  {/* <div className="editbtn">
                  <Link
                    to="/guide"
                    className="bg-[#03A9F5] flex text-white px-4 py-2 my-auto rounded"
                  >
                    <span className="mt-2 mr-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M0 11.247V13.6112C0 13.8289 0.171087 14 0.388835 14H2.75295C2.85405 14 2.95514 13.9611 3.02514 13.8833L11.5173 5.39897L8.60103 2.48271L0.116651 10.9671C0.0388836 11.0449 0 11.1382 0 11.247ZM13.7725 3.14373C14.0758 2.84044 14.0758 2.35051 13.7725 2.04722L11.9528 0.227468C11.6495 -0.0758228 11.1596 -0.0758228 10.8563 0.227468L9.43313 1.6506L12.3494 4.56687L13.7725 3.14373Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                    Edit Guide
                  </Link>
                </div> */}
                </div>
                <div className="flex flex-row">
                  <Search text={"Search for Guide Details"} />
                </div>
              </div>
            </div>

            <div className="flex flex-row">
              <div className="py-4 pr-4 w-1/2">
                <div className="border-2 border-[#1d242e4d] rounded bg-white">
                  <h2 className="py-3 px-6 font-bold">Details</h2>
                  <div className="border-t-2 border-[#1d242e4d] py-3 px-6">
                    <div className="flex flex-row justify-between">
                      <p className="py-2">Guide Id</p>
                      <p className="font-bold py-2">
                        <CopyId id={guideData.guide_id} />
                      </p>
                    </div>
                    <div className="flex flex-row justify-between">
                      <p className="py-2">Name</p>
                      <p className="font-bold py-2">{guideData.name}</p>
                    </div>
                    <div className="flex flex-row justify-between">
                      <p className="py-2">Gender</p>
                      <p className="font-bold py-2">{guideData.gender}</p>
                    </div>
                    <div className="flex flex-row justify-between">
                      <p className="py-2">Date of Birth</p>
                      <p className="font-bold py-2">{guideData.dob}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-4 pl-4 w-1/2">
                <div className="border-2 border-[#1d242e4d] rounded bg-white">
                  <h2 className="py-3 px-6 font-bold">Contact</h2>
                  <div className="border-t-2 border-[#1d242e4d]">
                    <div className="flex flex-row ">
                      <div className="flex flex-col py-2 px-7 w-1/2">
                        <p className="py-2">Contact Number</p>
                        <p className="py-2">WhatsApp Number</p>
                        <p className="py-2">Email Id</p>
                        <p className="py-2">City</p>
                      </div>
                      <div className="flex flex-col py-3  w-1/2">
                        <p className="font-bold py-2">{guideData.phone_number}</p>
                        <p className="font-bold py-2">
                          {guideData.whatsapp_number}
                        </p>
                        <p className="font-bold py-2">{guideData.email}</p>
                        <p className="font-bold py-2">{guideData.city}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <FourBoxDetails1
              heading={"Details"}
              fitop={guideData.experience}
              fibottom="Experience"
              stop={guideData.lat || "NA"}
              sbottom={"Latitude"}
              ttop={guideData.lon || "NA"}
              tbottom="Longitude"
              fotop={guideData.price_per_day || "NA"}
              fobottom="Price per day"
              id={guideData.guide_id}
              fetchInfo={fetchInfo}
            />
            <div className="mb-4 border-2 border-[#1d242e4d] rounded bg-white">
              <div className=" border-b-2 border-[#1d242e4d] flex justify-between">
                <h2 className="pl-6 py-3 my-auto font-bold">About Guide </h2>
                <button
                  className="text-white px-4 py-2"
                  onClick={() => setIsEditing(!isEditing)}
                >
                  {isEditing ? (
                    <div className="px-3" onClick={handleSaveButtonClick}>
                      <i
                        className="fa-solid fa-check"
                        style={{ color: "black" }}
                        onClick={handleSaveAndClose}
                      ></i>
                    </div>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M0 11.247V13.6112C0 13.8289 0.171087 14 0.388835 14H2.75295C2.85405 14 2.95514 13.9611 3.02514 13.8833L11.5173 5.39897L8.60103 2.48271L0.116651 10.9671C0.0388836 11.0449 0 11.1382 0 11.247ZM13.7725 3.14373C14.0758 2.84044 14.0758 2.35051 13.7725 2.04722L11.9528 0.227468C11.6495 -0.0758228 11.1596 -0.0758228 10.8563 0.227468L9.43313 1.6506L12.3494 4.56687L13.7725 3.14373Z"
                        fill="black"
                      />
                    </svg>
                  )}
                </button>
              </div>

              <div>
                <>
                  {isEditing ? (
                    <>
                      <Description
                        text="Bio"
                        placeholder="Bio...."
                        value={description}
                        onChange={(e) => {
                          setCityDescription(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <div className="pb-1 px-6">
                      <h2 className=" pt-3 pb-1 my-auto font-bold text-sm">
                        Bio
                      </h2>
                      <p className="text-sm">{guideData.bio}</p>
                    </div>
                  )}
                </>
                {isEditing ? (
                  <div className="">
                    {/* <div className="flex font-bold text-md ml-6">Places</div> */}
                    <div className="flex pl-4 ">
                      <PlaceListProvider>
                        <div className="mb-4 ml-6 w-1/2 pr-2">
                          <PlaceDropdownRelatedCity
                            editing={isEditing}
                            ids={guideData.places}
                            city_id={guideData.city_id}
                            onSelect={handlePlacesChange}
                            selectedPlaces={places}
                          />
                        </div>
                      </PlaceListProvider>
                    </div>
                    {/* <div className="flex font-bold text-md ml-6">Languages</div> */}
                    <LanguageProvider>
                      <div className="flex pl-4 w-1/2 ml-6 mb-6 ">
                        <LanguageDropdown
                          editing={isEditing}
                          ids={guideData.languages}
                          selectedLanguage={languages}
                          onSelect={handleLanguagesChange}
                        />
                      </div>
                    </LanguageProvider>
                    {/* <div className="flex font-bold text-md ml-6">Hobbies</div> */}
                    {/* <div className="flex pl-4 ">
                    <HobbiesProvider>
                      <HobbiesDropdown
                        editing={isEditing}
                        ids={guideData.hobbies}
                        selectedHobbies={Hobbies}
                        onSelect={handleHobbiesChange}
                      />
                    </HobbiesProvider>
                  </div> */}
                  </div>
                ) : (
                  <>
                    <div className="flex font-bold text-md ml-6">Places</div>
                    <div className="flex pl-4 ">
                      {guideData.places &&
                        guideData.places.map((place, index) => (
                          <BlueButton text={place.name} />
                        ))}
                    </div>
                    <div className="flex font-bold text-md ml-6">Languages</div>
                    <div className="flex pl-4 ">
                      {guideData.languages &&
                        guideData.languages.map((place, index) => (
                          <BlueButton text={place.name} />
                        ))}
                    </div>
                    {/* <div className="flex font-bold text-md ml-6">Hobbies</div>
                  <div className="flex pl-4 ">
                    {hobbiesArray &&
                      hobbiesArray.map((place, index) => (
                        <BlueButton text={place} />
                      ))}
                  </div> */}
                  </>
                )}
              </div>
            </div>

            {/* <div className="mb-2 border-2  border-[#1d242e4d] rounded bg-white">
            <h2 className="py-3 px-4 font-bold">Package</h2>
            <div className=" border-t-2 border-[#1d242e4d]">
              <div className="flex flex-row py-2 w-1/2">
                <div className="flex flex-col  px-7 w-1/2">
                  <p className="py-2">Price Per Day</p>
                </div>
                <div className="flex flex-col   w-1/2">
                  <p className="font-bold py-2">{guideData.price_per_day}</p>
                </div>
              </div>
            </div>
          </div> */}
            {/*
              <div className="flex font-bold text-md ml-6">Packages</div>

              <table className="w-full text-sm  border-t-2  border-[#1d242e4d] text-left text-gray-800 bg-white shadow-lg">
                <thead className="text-sm  text-gray-700">
                  <tr>
                    <th scope="col" className="py-2 w-1/8">
  guideDatar pl-6">
                        Package ID
                      </div>
                    </th>
                    <th scope="col" className="w-1/8 ">
                      <div className="flex items-center justify-center">
                        No of Days
                      </div>
                    </th>
                    <th scope="col" className="w-1/8">
                      <div className="flex items-center justify-center">
                        Price
                      </div>
                    </th>

                    <th scope="col" className="w-1/8">
                      <div className="flex items-center justify-center">
                        City
                      </div>
                    </th>
                    <th scope="col" className="w-1/8">
                      <div className="flex items-center justify-center">
                        Places
                      </div>
                    </th>
                    <th scope="col" className="w-1/8">
                      <div className="flex items-center justify-center">
                        Activities
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-t border-gray-400">
                    <td className="text-center w-1/6 pl-6  py-2">101</td>
                    <td className="text-center w-1/6 py-2 items-center justify-center"></td>
                    <td className="text-center w-1/6 py-2"> 232</td>
                    <td className="text-center w-1/6  py-2"> 232</td>
                    <td className="text-center w-1/6 ">
                      <MultiSelectDropdow />
                    </td>
                    <td className="text-center w-1/6  ">
                      <MultiSelectDropdow />{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
                */}

            {/* <FourBoxDetails
            heading={"Account Details (Coming soon)..."}
          // fitop={"SBI"}
          // fibottom="Bank Name"
          // stop={"bfg"}
          // sbottom="Account Holder Name"
          // ttop={"658774"}
          // tbottom="Account Number"
          // fotop={"4578"}
          // fobottom="IFSC Number"
          /> */}
            <div className="redbutton">
              <button
                type="button"
                className="flex bg-red-500 hover:bg-red-700 text-white py-3 px-3 rounded"
                onClick={() => setClickGuide(true)}
              >
                Duplicate Guide
              </button>
            </div>

            {clickGuide &&
              <div className="flex justify-center self-center items-center">
                <form
                  onSubmit={handleFormSubmit}
                  encType="multipart/form-data"
                >
                  <div className="modal-content">
                    <h1 style={{ fontWeight: "bold", fontSize: "1.1rem" }}>Duplicate Guide</h1>
                    <hr className="my-4" />
                    <div className="flex w-full flex-col">
                      <div className="w-full">
                        <CityDropdown
                          value={cityName}
                          setCityId={setCityId}
                          onChange={handleCityNameChange}
                        />
                      </div>
                      <div className="flex  bottomButtom">
                        <button
                          type="button"
                          className="px-2 btn cancel "
                          onClick={closeForm}
                        >
                          Cancel
                        </button>
                        <button type="submit" className="px-2 btn2">
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            }
          </div>
        </div>
      </CityProvider>
    </div>
  );
}
