import React from "react";
import { Link } from "react-router-dom";
import { useBookingContext } from "../../../controllers/Booking_controllers";
import CopyId from "./CopyId";
import PaginationNo from "./PaginationNo";
export default function BookingTableComponent() {
  const BookingData = useBookingContext();
  let bookingdata = BookingData?.state?.booking?.booking || [];
  const { state, getNextPage, getPrevPage } = useBookingContext();
  return (
    <div>
      {console.log(BookingData)}
      <div className="flex justify-between py-6">
        <div className="px-2">
          <h1 className="font-bold text-lg handleHeader">Booking</h1>
          <span className="text-sm handleDescription">
            Manage all your booking.
          </span>
        </div>
        <div>
          <PaginationNo
            post_per_page={state.post_per_page}
            getPrevPage={getPrevPage}
            totalPost={state.totalPost}
            getNextPage={getNextPage}
            currentPage={state.page}
          />
        </div>
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-md border-2 border-gray-400">
        <table className="w-full h-full text-sm text-left text-gray-500">
          <thead className="text-md text-center text-gray-800 bg-gray-50">
            <tr>
              {/* {console.log(bookingdata)} */}
              <th scope="col" className="px-6 py-6">
                Booking Id
              </th>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6  py-3">
                Guide Id
              </th>
              <th scope="col" className="px-6  py-3">
                City
              </th>
              <th scope="col" className="px-6 py-3">
                No Of Days
              </th>

              <th scope="col" className="px-6 py-3">
                Amount
              </th>
              <th scope="col" className="px-6 py-3">
                Payment Status
              </th>
            </tr>
          </thead>

          <tbody>
            {bookingdata.map((entry, index) => {
              const originalDate = entry.created_at; // Assuming created_at is the date field
              const dateObject = new Date(originalDate);
              const formattedDate = `${dateObject.getDate()}-${
                dateObject.getMonth() + 1
              }-${dateObject.getFullYear()}`;
              return (
                <tr
                  key={index}
                  className={`border border-[#1D242E4D] bg-white ${
                    index % 2 === 0 ? "even" : "odd"
                  }`}
                >
                  <Link
                    to={`/booking/BookingDetails/${entry.booking_id}`}
                    style={{ display: "contents", height: "50%" }}
                  >
                    <td className="px-6 text-center py-4 pt-6 font-medium text-gray-900 whitespace-nowrap">
                      <CopyId id={entry.booking_id} />
                    </td>
                    <td className="px-6 text-gray-900 text-center py-4">
                      {formattedDate}
                    </td>
                    <td className="px-6 text-gray-900 text-center py-4">
                      {entry.guide_name}
                    </td>

                    <td className="px-6 text-gray-900 text-center py-5">
                      {entry.city}
                    </td>
                    <td className="px-6 text-gray-900 py-5 text-center">
                      {entry.total_days}
                    </td>

                    <td className="px-6 text-gray-900 py-5 text-center">
                      {entry.total_price}
                    </td>
                    <td className="px-6 py-5 text-center">
                      <div>
                        {entry.payment_status === "Confirm" ? (
                          <button className="green-button bg-[#D6EEE0] py-2 px-4 text-[#55B280]">
                            Confirmed
                          </button>
                        ) : entry.payment_status === "Declined" ? (
                          <button className="reddish-button text-[#DB5F52] py-2 px-4 bg-[#FADBD7]">
                            Declined
                          </button>
                        ) : (
                          <button className="orange-button text-[#F86F03] py-2 px-4 bg-[#FFD89C]">
                            Pending
                          </button>
                        )}
                      </div>
                    </td>
                  </Link>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
{
  /* <div
                      className="confirmed mr-9"
                      style={{ position: "relative" }}
                    >
                      <div>
                        {
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                            }}
                          >
                            <circle
                              cx="12"
                              cy="12"
                              r="11.75"
                              fill="white"
                              stroke="black"
                              stroke-width="0.5"
                            />
                          </svg>
                        }
                      </div>
                      <div className="absolute justify-center px-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="10"
                          viewBox="0 0 14 10"
                          fill="none"
                          style={{ position: "absolute", top: 7, left: 5 }}
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M13.0333 0.29312C13.2208 0.480647 13.3261 0.734956 13.3261 1.00012C13.3261 1.26528 13.2208 1.51959 13.0333 1.70712L5.53796 9.20245C5.4389 9.30153 5.3213 9.38012 5.19187 9.43374C5.06244 9.48736 4.92372 9.51496 4.78362 9.51496C4.64352 9.51496 4.5048 9.48736 4.37537 9.43374C4.24594 9.38012 4.12834 9.30153 4.02929 9.20245L0.305288 5.47912C0.209778 5.38687 0.133596 5.27653 0.0811869 5.15452C0.0287779 5.03252 0.00119157 4.9013 3.77566e-05 4.76852C-0.00111606 4.63574 0.0241857 4.50406 0.0744666 4.38117C0.124748 4.25827 0.199001 4.14662 0.292893 4.05272C0.386786 3.95883 0.498438 3.88458 0.621334 3.8343C0.744231 3.78402 0.87591 3.75872 1.00869 3.75987C1.14147 3.76102 1.27269 3.78861 1.39469 3.84102C1.5167 3.89343 1.62704 3.96961 1.71929 4.06512L4.78329 7.12912L11.6186 0.29312C11.7115 0.200193 11.8218 0.126477 11.9431 0.0761817C12.0645 0.0258869 12.1946 0 12.326 0C12.4573 0 12.5874 0.0258869 12.7088 0.0761817C12.8302 0.126477 12.9404 0.200193 13.0333 0.29312Z"
                            fill="#00B954"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="pending" style={{ position: "relative" }}>
                      <div>
                        {
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                            }}
                          >
                            <circle
                              cx="12"
                              cy="12"
                              r="11.75"
                              fill="white"
                              stroke="black"
                              stroke-width="0.5"
                            />
                          </svg>
                        }
                      </div>
                      <div className="">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          style={{ position: "absolute", top: 6, left: 5 }}
                        >
                          <path
                            d="M1.74951 0.288L5.86684 4.40533L9.96284 0.309333C10.0533 0.213032 10.1623 0.135994 10.2833 0.0828397C10.4043 0.0296852 10.5347 0.00150863 10.6668 0C10.9497 0 11.221 0.112381 11.4211 0.312419C11.6211 0.512458 11.7335 0.783769 11.7335 1.06667C11.736 1.19744 11.7117 1.32735 11.6622 1.44841C11.6127 1.56947 11.5389 1.67913 11.4455 1.77067L7.29617 5.86667L11.4455 10.016C11.6213 10.188 11.7244 10.4209 11.7335 10.6667C11.7335 10.9496 11.6211 11.2209 11.4211 11.4209C11.221 11.621 10.9497 11.7333 10.6668 11.7333C10.5309 11.739 10.3953 11.7163 10.2686 11.6667C10.1419 11.6171 10.0269 11.5417 9.93084 11.4453L5.86684 7.328L1.76017 11.4347C1.67005 11.5278 1.56238 11.6021 1.44338 11.6533C1.32438 11.7046 1.1964 11.7318 1.06684 11.7333C0.783943 11.7333 0.512632 11.621 0.312593 11.4209C0.112554 11.2209 0.000173554 10.9496 0.000173554 10.6667C-0.00231337 10.5359 0.02195 10.406 0.0714748 10.2849C0.121 10.1639 0.194741 10.0542 0.288174 9.96267L4.43751 5.86667L0.288174 1.71733C0.112371 1.54534 0.00928248 1.31244 0.000173554 1.06667C0.000173554 0.783769 0.112554 0.512458 0.312593 0.312419C0.512632 0.112381 0.783943 0 1.06684 0C1.32284 0.0032 1.56817 0.106667 1.74951 0.288Z"
                            fill="#F0483E"
                          />
                        </svg>
                      </div>
                    </div> */
}
