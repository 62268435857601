import React from "react";
import { createContext, useState, useContext, useEffect } from "react";
import { language } from "../../../constants/urls";
const LanguageContext = createContext();

export default function LanguageProvider({ children }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function fetchInfo() {
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          `Token ${localStorage.getItem("token")}`
        );
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        const response = await fetch(language, requestOptions);
        const result = await response.json();
        if (response.ok) {
          setData(result);
        } else {
          console.log(`Error: ${result && result.error}`);
        }
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    }

    fetchInfo();
  }, []);

  return (
    <LanguageContext.Provider value={{ data, setData }}>
      {children}
    </LanguageContext.Provider>
  );
}
export function useLanguageContext() {
  return useContext(LanguageContext);
}
