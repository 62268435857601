import React from "react";

export default function MultiArrayList({ text, array }) {
  return (
    <div className="mb-4 mx-6 ">
      <div>
        <div className="flex">
          <label
            for={text}
            class="block mb-2 text-md font-medium text-gray-900 "
          >
            {text}
          </label>
        </div>
        <div className="">
          {array?.map((activity, index) => (
            <button
              className="actcenterbtn pl-3 py-2"
              key={index}
              style={{
                backgroundColor: "#A7DBF5",
                color: "black",
                border: "none",
                cursor: "pointer",
                fontSize: "15px",
                borderRadius: "7px",
              }}
            >
              {activity.name.length > 15
                ? activity.name.slice(0, 14) + "..."
                : activity.name}
              <button className="px-2" style={{ position: "relative" }}>
                <i
                  className="fa-solid fa-circle"
                  style={{ color: "lightgray", fontSize: "17px" }}
                ></i>
                <i
                  className="fa-solid fa-times"
                  style={{
                    color: " black",
                    fontSize: "12px",
                    position: "absolute",
                    top: 5,
                    left: 12,
                  }}
                ></i>
              </button>
            </button>
          ))}
        </div>
        <div className="bg-white-200 text-gray-900 text-sm rounded focus:ring-blue-500 block w-full border-[#1d242e4d] placeholder-gray-400"></div>
      </div>
    </div>
  );
}
