import React, { useEffect, useRef, useState, useCallback } from "react";
import AddButton from "../components/AddButton";
import BasicTable from "../sepcomponents/GuideComponent";
import { Link } from "react-router-dom";
import { getshopview, stayurl } from "../../../constants/urls";
import axios from "axios";
import CopyId from "../components/CopyId";
import PaginationNo from "../components/PaginationNo";
import Loading from "../components/Loading";
import { debounce } from "lodash";

export default function Shop() {
  const inputRef = useRef(null);
  const [shopdata, setshopdata] = useState();
  const [pagination, setPagination] = useState({
    page: 1,
    totalPost: 0,
    postPerPage: 10,
  });
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  console.log(shopdata);
  const fetchData = async (page = 1) => {
    try {
      setLoading(true);
      // const token = '228701687f8db711593cf986b7cbe995d1639da3';
      const response = await axios.get(
        `https://orca-app-pgxkr.ondigitalocean.app/bookings/search?name=${searchTerm}&page=${page}&model=Shop `,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        }
      );
      // console.log(response);
      setshopdata(response.data.results);
      setPagination({
        ...pagination,
        totalPost: response.data.count,
        page,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [shopdata]);

  const handleSearchChange = useCallback(
    debounce(async (newSearchTerm) => {
      await fetchData(1, newSearchTerm);
      setSearchTerm(newSearchTerm);
    }, 100),
    [fetchData]
  );

  const getNextPage = () => {
    const nextPage = pagination.page + 1;
    fetchData(nextPage);
  };

  const getPrevPage = () => {
    const prevPage = pagination.page - 1;
    if (prevPage > 0) {
      fetchData(prevPage);
    }
  };

  return (
    <div
      className="flexbox-container pl-64 handleBack "
      style={{ backgroundColor: "#EDF1F5", height: "100vh" }}
    >
      <div className="container pt-4 px-8 ">
        <div className="flex flex-row mt-2 justify-between">
          <div className="flex flex-col">
            <h1 className="handleHeader">Shop view</h1>
            <h4 className="handleDescription">List of all Shop view</h4>
          </div>
          <div>
            <Link to="/shop/addshop">
              <AddButton text="Add a Shop" />
            </Link>
          </div>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="flex justify-between mt-2  ">
              <div className="relative mt-2 lg:w-96">
                <input
                  type="text"
                  // name={text}
                  // id={text}
                  placeholder="Search by name..."
                  className="block appearance-none w-full bg-[#E3EBF3] border border-[#1d242e4d] text-gray-700 py-3 pl-4 pr-16 rounded leading-tight focus:outline-none  focus:border-gray-500"
                  value={searchTerm}
                  onChange={(event) => handleSearchChange(event.target.value)}
                  ref={inputRef}
                />
                <div className="flex absolute inset-y-0 right-3 items-center pl-1 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-800 dark:text-black-600"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <PaginationNo
                getPrevPage={getPrevPage}
                totalPost={pagination.totalPost}
                getNextPage={getNextPage}
                currentPage={pagination.page}
                post_per_page={pagination.postPerPage}
              />
            </div>
            <div className=" mt-2">
              <div className=" container text-center py-4">
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                  <table className="w-full border border-[#1d242e4d] text-gray-700 bg-white shadow-lg tablefont">
                    <thead className="text-sm text-gray-700 font-semibold">
                      <tr>
                        <th scope="col" className=" w-1/7 py-3 text-lg">
                          <div className=" items-center justify-center text-center ">
                            Shop ID
                          </div>
                        </th>
                        <th scope="col" className="w-1/7 py-3 text-lg">
                          <div className="flex items-center justify-center ">
                            Photo
                          </div>
                        </th>
                        <th scope="col" className="w-1/7 py-3 text-lg">
                          <div className="flex items-center justify-center">
                            Name
                          </div>
                        </th>

                        <th scope="col" className="w-1/7 py-3 text-lg">
                          <div className="flex items-center justify-center">
                            Mo.Number
                          </div>
                        </th>
                        <th scope="col" className="w-1/7 py-3 text-lg">
                          <div className="flex items-center justify-center">
                            City
                          </div>
                        </th>
                        {/* <th scope="col" className="w-1/7 py-3 text-lg">
                                            <div className="flex items-center justify-center">
                                                Trips
                                                <a href="/">
                                                    <svg
                                                        class="w-3 h-3 ml-1.5"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="8"
                                                        height="13"
                                                        viewBox="0 0 8 13"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
                                                            fill="#1D242E"
                                                        />
                                                        <path
                                                            d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
                                                            fill="#1D242E"
                                                        />
                                                    </svg>
                                                </a>
                                            </div>
                                        </th> */}
                        {/* <th scope="col" className="w-1/7 py-3 text-lg">
                                            <div className="flex items-center justify-center ">
                                                Rating
                                                <a href="/">
                                                    <svg
                                                        class="w-3 h-3 ml-1.5"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="8"
                                                        height="13"
                                                        viewBox="0 0 8 13"
                                                        fill="none"
                                                    >
                                                        <path
                                                            d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
                                                            fill="#1D242E"
                                                        />
                                                        <path
                                                            d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
                                                            fill="#1D242E"
                                                        />
                                                    </svg>
                                                </a>
                                            </div>
                                        </th> */}
                        <th scope="col" className="w-1/7 py-3 text-lg">
                          <div className="flex items-center justify-center">
                            Email
                          </div>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {shopdata &&
                        shopdata.map((data) => (
                          <tr className="border-t-2 pt-3 border-gray-300 table-text">
                            <td className="pt-3">
                              <div className="w-1/7 flex justify-center items-center">
                                <CopyId id={data.id} />
                              </div>
                            </td>
                            <Link
                              //   key={row.guide_id}
                              // to={`/places/EditPlaceDetails/${row.guide_id}/`}
                              to={`/editshop/${data.id}`}
                              style={{ display: "contents" }}
                              className="block hover:bg-gray-100"
                            >
                              <td
                                className="text-center w-1/7"
                                style={{ verticalAlign: "middle" }}
                              >
                                <div className="flex items-center justify-center h-full">
                                  <img
                                    className="h-8 w-8 rounded-full ring-1 ring-[#BBBDC0] ring-offset-1"
                                    src={
                                      data.image ||
                                      "https://media.cntraveler.com/photos/57d87670fd86274a1db91acd/16:9/w_2580,c_limit/most-beautiful-paris-pont-alexandre-iii-GettyImages-574883771.jpg"
                                    }
                                    alt="tag"
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src =
                                        "https://media.cntraveler.com/photos/57d87670fd86274a1db91acd/16:9/w_2580,c_limit/most-beautiful-paris-pont-alexandre-iii-GettyImages-574883771.jpg";
                                    }}
                                  />
                                </div>
                              </td>

                              <td className="pt-3">
                                <div className="flex items-center text-center  w-1/7 text-center mb-2 justify-center">
                                  {data.name}
                                </div>
                              </td>
                              <td className="text-center w-1/7">
                                <div className="flex items-center mb-2 justify-center">
                                  {data && data.whatsapp ? data.whatsapp : "NA"}
                                </div>
                              </td>
                              <td className="text-center w-1/7">
                                <div className="flex items-center mb-4 justify-center">
                                  {data.city}
                                </div>
                              </td>
                              {/* <td className="text-center w-1/7">
                                                    <div className="flex items-center mb-2 justify-center">
                                                        abc
                                                    </div>
                                                </td> */}
                              <td className="text-center w-1/7">
                                <div className="flex items-center mb-2 justify-center">
                                  {data && data.email ? data.email : "NA"}
                                </div>
                              </td>
                            </Link>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
