import React, { useEffect, useRef } from "react";
import Select from "react-select";
import { useTagsContext } from "../../../controllers/Tag_controller";

export default function TagsDropdownCenter({ editing, ids, selectedTags, onSelect }) {
  const tagData = useTagsContext();
  const cities = tagData?.state?.tag?.tag || [];
  const containerRef = useRef(null);
  const { setSearchQuery } = useTagsContext();
  // Focus the input when the component mounts.
  useEffect(() => {
    if (containerRef && containerRef.current) {
      containerRef.current.focus();
    }
  }, []);
  const CustomOption = ({ innerProps, label, image }) => (
    <div {...innerProps}>
      <div className="flex py-2 px-4">
        <div className="flex items-center justify-center h-full">
          <img
            className="h-6 w-6 rounded-full"
            src={
              image ||
              "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ="
            }
            alt={label}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ=";
            }}
          />
        </div>
        <span className="ml-2">{label}</span>
      </div>
    </div>
  );

  const cityOptions = cities.map((city) => ({
    value: city.tag_id,
    label: city.name,
    image:
      city.image ||
      "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ=",
  }));

  useEffect(() => {
    if (editing && ids) { 
      const selectedTags = ids.map((id) => ({
        value: id.id,
        label: id.name,
      }));
      onSelect(selectedTags);
    }
  }, [editing, ids]);

  return (
    <div className="mb-4 ml-6 mr-2" ref={containerRef}>
      {/* {console.log("selectedTags", selectedTags)} */}
      <div className="flex">
        <div className="flex">
          <label
            htmlFor="dropdown"
            className="block mb-2 text-md font-medium text-gray-900"
          >
            Center Tags
          </label>
        </div>
      </div>
      <Select
        id="dropdown"
        name="dropdown"
        placeholder="Center Tags"
        options={cityOptions}
        value={selectedTags.map((tag) => ({
          value: tag.value,
          label: tag.label,
        }))}
        onInputChange={(inputValue) => {
          setSearchQuery(inputValue);
        }}
        onChange={(selectedOptions) => {
          const selectedValues = selectedOptions.map((option) => ({
            value: option.value,
            label: option.label,
          }));
          onSelect(selectedValues);
        }}
        isMulti
        // captureMenuScroll={false}
        styles={{
          control: (provided, state) => ({
            ...provided,
            width: "100%",
            zIndex: "50",
          }),
          multiValue: (provided) => ({
            ...provided,
            background: "#A7DBF5",
            borderRadius: "5px",
            padding: "2px",
            fontSize: "16px",
            fontWeight: "600",
          }),
          multiValueRemove: (provided, state) => ({
            ...provided,
            color: "black",
            backgroundColor: "#E5E5E5",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            marginTop: "3px",
            margin: "4px",
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            color: "black",
          }),
        }}
        components={{ Option: CustomOption }}
      />
    </div>
  );
}
