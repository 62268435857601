import React from "react";

function AddButton({ text, onClick }) {
  return (
    <div className="redbutton">
      <button
        type="button"
        className="flex bg-red-500 hover:bg-red-700 text-white py-3 px-3 rounded"
        onClick={onClick}
      >
        <div className=" pt-2 pr-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 14 18"
            fill="none"
          >
            <path
              d="M13 8H8V13C8 13.55 7.55 14 7 14C6.45 14 6 13.55 6 13V8H1C0.45 8 0 7.55 0 7C0 6.45 0.45 6 1 6H6V1C6 0.45 6.45 0 7 0C7.55 0 8 0.45 8 1V6H13C13.55 6 14 6.45 14 7C14 7.55 13.55 8 13 8Z"
              fill="white"
            />
          </svg>
        </div>
        {text}
      </button>
    </div>
  );
}

export default AddButton;
