import React, { useState } from "react";
import { StatisticsProvider } from "../../../controllers/Statistics_controller";
import StatiscticsComponent from "../sepcomponents/StatiscticsComponent";
import DialogBox from "../components/DialogBox";
import { statisticDownload } from "../../../constants/urls";
export default function Dashboard() {
  const handleDownload = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Token ${localStorage.getItem("token")}`);
    fetch(
      { statisticDownload },
      {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      }
    )
      .then((response) => {
        console.log("Download request successful", response);
        handleOpenDialog("Successfully Downloaded", "Thank you!");
      })
      .catch((error) => {
        // Handle errors
        console.error("Error downloading", error);
      });
  };
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState({
    title: "",
    message: "",
  });
  const handleOpenDialog = (title, message) => {
    setDialogContent({ title, message });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <StatisticsProvider>
        {/* Header and Buttons */}
        <div className=" ml-64" style={{ backgroundColor: "#EDF1F5",height:"100vh" }}>
          <div className="container handleBack py-2 px-8">
            <div className="flex  justify-between">
              <div className="pt-1 pb-0">
                <h1 className="font-bold text-3xl">Dashboard</h1>
                <span className="text-md">A quick Data Overview</span>
              </div>
              <div className="w-1/6 mt-2">
                {/* <div className="mb-2 relative">
                  <select
                    id="dropdown"
                    name="dropdown"
                    className="bg-white-200 border text-gray-1000 text-md rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 border-[#1d242e4d] placeholder-gray-400"
                    // value="Download Report" // You can remove this line if you don't want a default selected value
                    // onChange={onChange}
                    required
                  >
                    <option value="Download Report" disabled selected>
                      Download Report
                    </option>
                    <option value="Excel">
                      <i className="fa-regular fa-file-excel pr-2"></i>Excel
                    </option>
                    <option value="PDF">
                      <i className="fa-regular fa-file-pdf pr-2"></i>PDF
                    </option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-800">
                    
                  </div>
                </div> */}
                {/* <div className="mb-2 relative">
                  <button
                    id="downloadButton"
                    className="bg-white border text-gray-1000 text-md rounded focus:ring-black-500 focus:border-black-500 block w-full p-2.5 border-[#1d242e4d] placeholder-gray-400"
                    onClick={handleDownload}
                  >
                    Download Report
                  </button>
                </div> */}
                <DialogBox
                  open={openDialog}
                  onClose={handleCloseDialog}
                  title={dialogContent.title}
                  content={dialogContent.message}
                />
              </div>

              {/* <div className="flex border-2 border-gray-400 px-1 py-1 bg-white rounded">
                <button className="dropdown mx-1 text-gray-810 text-lg">
                  Download Report
                  <div class="dropdown">
                    <button class="btn px-1 pb-1">
                      <i class="fa-solid fa-chevron-down"></i>
                    </button>
                    <div class="dropdown-content">
                      <a href="/">
                        <i class="fa-regular fa-file-excel pr-2"></i>Excel
                      </a>
                      <a href="/">
                        <i class="fa-regular fa-file-pdf pr-2"></i>PDF
                      </a>
                    </div>
                  </div>
                </button>
              </div> */}

              {/* <Dropdown placeholder={"Download Report"} /> */}
            </div>
          </div>
          {/* colored Containers  */}
          <StatiscticsComponent />
        </div>
      </StatisticsProvider>
    </>
  );
}
