// const masterUrl = "https://orca-app-pgxkr.ondigitalocean.app/dashboard/";
// const Url = "https://orca-app-pgxkr.ondigitalocean.app/data/";
const masterUrl = "https://orca-app-pgxkr.ondigitalocean.app/dashboard/";
const Url = "https://orca-app-pgxkr.ondigitalocean.app/data/";
const Urlnew = "https://orca-app-pgxkr.ondigitalocean.app/";

const loginApi = `${masterUrl}login/`;
const statistics = `${masterUrl}statistics/`;
const placeHomeApi = `${masterUrl}place-home`;
const placeTags = `${masterUrl}tag-home/`;
const activity = `${masterUrl}activities-home/`;
const statisticDownload = `${masterUrl}statistics-download/`;
const guideHome = `${masterUrl}guide-home/`;
const guideDetail = `${masterUrl}guide-detail/`;
const guideDetail2 = `${Url}guide/`;
const centerDetail = `${masterUrl}center-detail/`;
const CenterHome = `${masterUrl}center-home/`;
const Booking = `${masterUrl}booking-home/`;
const sendNotification = `${Url}send-notification/`;
const activityDetail = `${Url}activities/`;
const tagsDetail = `${Url}tags/`;
const CityList = `${masterUrl}get-all-cities`;
const CityDropdownn = `${masterUrl}city-list/`;
const PlaceList = `${masterUrl}get-all-places/`;
const addCenter = `${Url}center/`;
const addCity = `${Url}city/`;
const addGuide = `${Url}guide/`;
const addCustomGuide = `${Url}add-guide/`;
const language = `${Url}language/`;
const country = `${Url}country-data/`;
const stateurl = `${Url}state-data/`;
const hobbies = `${Url}interest/`;
const centerType = `${Url}center-types/`;
const AddActivities = `${Url}activities/`;
const notificationHome = `${masterUrl}notification-home`;
const addstay = `${Urlnew}bookings/stay/`;
const AmenitiesTags = `${Urlnew}bookings/stay-amenity`;
const stayurl = `${Urlnew}bookings/stay-view`;
const stayCategories = `${Urlnew}bookings/stay-category?page_size=80`;
const addrentvehicle = `${Urlnew}bookings/rent-vehicle/`;
const getrentvehicle = `${Urlnew}bookings/rentvehicle-view`;
const rentvehicleCategories = `${Urlnew}bookings/rent-vehicle-category/`;
const getselfcaming = `${Urlnew}bookings/selfcamping-view`;
const Addselfcaming = `${Urlnew}bookings/selfcamping/`;
const getCafe = `${Urlnew}bookings/cafe-view`;
const AddCafe = `${Urlnew}bookings/cafe/`;
const rentvehiclesubcategory = `${Urlnew}bookings/rent-vehicle-subcategory`;
const getshopview = `${Urlnew}bookings/shop-view`;
const addshopview = `${Urlnew}bookings/shop/`;
const getshopgear = `${Urlnew}bookings/shopgear-view`;
const addshopgear = `${Urlnew}bookings/shopgear/`;
const FacilityTags = `${Urlnew}bookings/shopgear-facility`;
const placeCRUDApi = `${Urlnew}data/place/`;

export {
  masterUrl,
  notificationHome,
  country,
  stateurl,
  statisticDownload,
  centerType,
  tagsDetail,
  guideDetail2,
  hobbies,
  AddActivities,
  sendNotification,
  activityDetail,
  guideDetail,
  centerDetail,
  loginApi,
  statistics,
  placeHomeApi,
  placeTags,
  activity,
  guideHome,
  CenterHome,
  Booking,
  CityDropdownn,
  CityList,
  PlaceList,
  addCenter,
  addCity,
  addGuide,
  language,
  addstay,
  AmenitiesTags,
  stayurl,
  stayCategories,
  addrentvehicle,
  rentvehicleCategories,
  getselfcaming,
  Addselfcaming,
  getCafe,
  AddCafe,
  getrentvehicle,
  rentvehiclesubcategory,
  getshopview,
  addshopview,
  getshopgear,
  addshopgear,
  FacilityTags,
  Urlnew,
  addCustomGuide,
  placeCRUDApi,
};
