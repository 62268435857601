import { placeHomeApi } from "../constants/urls";
import { useLocation } from "react-router-dom";
import React, {
  createContext,
  useReducer,
  useContext,
  useEffect,
  useState,
} from "react";

const CityContext = createContext();
const PlaceContext = createContext();
const initialState = {
  cities: [],
  query: "",
  isloading: true,
  totalPost: 0,
  page: 1,
  post_per_page: 12,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_CITIES":
      return {
        ...state,
        cities: action.payload,
        totalPost: action.payload.totalPost,
        post_per_page: 12,
        isloading: false,
      };
    case "SET_QUERY":
      return { ...state, query: action.payload };
    case "SET_LOADING":
      return { ...state, isloading: true };

    case "NEXT":
      let pageNumInc = state.page + 1;
      if (pageNumInc > Math.ceil(state.totalPost / state.post_per_page)) {
        pageNumInc = 1;
      }
      return { ...state, page: pageNumInc };
    case "PREV":
      let pageNum = state.page - 1;
      if (pageNum <= 1) {
        pageNum = 1;
      }
      return { ...state, page: pageNum };
    case "SET_PAGE":
      return { ...state, page: action.payload };

    default:
      return state;
  }
}

export function CityProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const location = useLocation();
  useEffect(() => {
    async function fetchInfo() {
      const urlSearchParams = new URLSearchParams(location.search);
      const pageFromUrl = parseInt(urlSearchParams.get("page")) || 1;

      dispatch({ type: "SET_LOADING" });
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          `Token ${localStorage.getItem("token")}`
        );

        const queryParams = new URLSearchParams();
        queryParams.append("page", state.page);
        queryParams.append("name", state.query);

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const apiUrlWithQuery = `${placeHomeApi}?${queryParams.toString()}&page_size=${
          state.post_per_page
        }`;

        const response = await fetch(apiUrlWithQuery, requestOptions);
        const result = await response.json();
        if (response.ok) {
          console.log(result);
          dispatch({
            type: "SET_CITIES",
            payload: { cities: result.results.cities, totalPost: result.count },
          });
          console.log("statepage", apiUrlWithQuery);
          setPage(pageFromUrl);
        } else {
          console.log(`Error: ${result.error}`);
        }
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    }

    fetchInfo();
  }, [state.query, state.page]);

  const setSearchQuery = (searchQuery) => {
    dispatch({ type: "SET_QUERY", payload: searchQuery });
  };
  const getNextPage = () => {
    dispatch({ type: "NEXT" });
  };
  const getPrevPage = () => {
    dispatch({ type: "PREV" });
  };
  const setPage = (newPage) => {
    dispatch({ type: "SET_PAGE", payload: newPage });
  };

  return (
    <CityContext.Provider
      value={{ state, setSearchQuery, getNextPage, getPrevPage, setPage }}
    >
      {children}
    </CityContext.Provider>
  );
}

export function PlaceProvider({ children }) {
  const [placevalue, setPlaceValue] = useState(0);
  const [cityvalue, setCityValue] = useState(0);

  useEffect(() => {
    async function fetchInfo() {
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          `Token ${localStorage.getItem("token")}`
        );

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(placeHomeApi, requestOptions);
        const result = await response.json();

        if (response.ok) {
          // console.log(result);
          setCityValue(result.results.total_cities);
          setPlaceValue(result.results.total_places);
        } else {
          console.log(`Error: ${result.error}`);
        }
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    }

    fetchInfo();
  }, []);
  return (
    <PlaceContext.Provider value={{ placevalue, cityvalue }}>
      {children}
    </PlaceContext.Provider>
  );
}

export function useCityContext() {
  return useContext(CityContext);
}
export function usePlaceContext() {
  return useContext(PlaceContext);
}
// export { fetchInfo };
