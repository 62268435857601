import React from "react";

export default function EditImgPlaces({ url, key, onDelete }) {
  //   const handleDelete = () => {
  //     // onDelete(index);
  //     console.log(key);
  //   };

  return (
    <div className="image-container">
      <div className="img-upload">
        <div className="image-item">
          <img
            src={
              url ||
              "https://media.cntraveler.com/photos/57d87670fd86274a1db91acd/16:9/w_2580,c_limit/most-beautiful-paris-pont-alexandre-iii-GettyImages-574883771.jpg"
            }
            alt="img"
            style={{ objectFit: "cover", width: "100%", height: "100%" }}
          />
          <button className="delete-button-img-upload" onClick={onDelete}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="11"
              height="14"
              viewBox="0 0 11 14"
              fill="none"
            >
              <path
                d="M0.777778 12.4444C0.777778 13.3 1.47778 14 2.33333 14H8.55556C9.41111 14 10.1111 13.3 10.1111 12.4444V4.66667C10.1111 3.81111 9.41111 3.11111 8.55556 3.11111H2.33333C1.47778 3.11111 0.777778 3.81111 0.777778 4.66667V12.4444ZM3.11111 4.66667H7.77778C8.20556 4.66667 8.55556 5.01667 8.55556 5.44444V11.6667C8.55556 12.0944 8.20556 12.4444 7.77778 12.4444H3.11111C2.68333 12.4444 2.33333 12.0944 2.33333 11.6667V5.44444C2.33333 5.01667 2.68333 4.66667 3.11111 4.66667ZM8.16667 0.777778L7.61444 0.225556C7.47444 0.0855555 7.27222 0 7.07 0H3.81889C3.61667 0 3.41444 0.0855555 3.27444 0.225556L2.72222 0.777778H0.777778C0.35 0.777778 0 1.12778 0 1.55556C0 1.98333 0.35 2.33333 0.777778 2.33333H10.1111C10.5389 2.33333 10.8889 1.98333 10.8889 1.55556C10.8889 1.12778 10.5389 0.777778 10.1111 0.777778H8.16667Z"
                fill="#F0483E"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
