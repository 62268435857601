import React from "react";

export default function Description({
  text,
  placeholder,
  value,
  onChange,
  validationError,
  required = true,
}) {
  return (
    <div>
      <form>
        <div className="m-4 pl-4 pr-0 lg:px-4">
          <div className="flex">
            <label
              htmlFor="textarea"
              className="block mb-1 font-semibold text-md text-gray-900 box-heading"
            >
              {text}
            </label>
            {required && (
              <i
                className="fa-solid fa-asterisk"
                style={{
                  color: "#ff0a2f",
                  paddingLeft: "3px",
                  paddingTop: "8px",
                  fontSize: "10px",
                }}
              ></i>
            )}
          </div>
          <textarea
            id="textarea"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className="bg-white-200 border-2 font-poppins text-gray-900 text-md rounded-lg block w-full h-28 border-[#1d242e4d] placeholder-gray-500 pl-3 pt-2 mt-1 resize-none"
            required={required}
          ></textarea>
          {validationError && (
            <p className="text-red-500 text-sm mt-1">{validationError}</p>
          )}
        </div>
      </form>
    </div>
  );
}
