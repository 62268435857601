
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import PaginationNo from "../components/PaginationNo";
import Search from "../components/Search";
import CopyId from "../components/CopyId";

export default function Webenquiry() {
    const [webenquiry, setWebenquiry] = useState([]);
    const [pagination, setPagination] = useState({
        page: 1,
        totalPost: 0,
        postPerPage: 10, // Adjust as needed
    });

    const cities = [
        { value: 4, label: "City" },
        { value: 1, label: "New York" },
        { value: 2, label: "Los Angeles" },
        { value: 3, label: "Chicago" },
    ];
    const [selectedCity, setSelectedCity] = React.useState("City");

    const handleCityChange = (e) => {
        setSelectedCity(e.target.value);
    };

    const token = localStorage.getItem("token");

    const fetchData = async (page = 1) => {
        try {
            const response = await axios.get(
                `https://orca-app-pgxkr.ondigitalocean.app/website/website-enquiry-list?page=${page}`,
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );

            setWebenquiry(response.data.results);
            setPagination({
                ...pagination,
                totalPost: response.data.count,
                page,
            });
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const getNextPage = () => {
        const nextPage = pagination.page + 1;
        fetchData(nextPage);
    };

    const getPrevPage = () => {
        const prevPage = pagination.page - 1;
        if (prevPage > 0) {
            fetchData(prevPage);
        }
    };
    return (
        <>
            <div className="flexbox-container pl-64 handleBack " style={{ backgroundColor: "#EDF1F5", height: "100vh" }}>
                <div className="flex flex-row justify-between mx-5 pl-5">
                    <div className="flex flex-col mt-5">
                        <h1 className="handleHeader">Website Enquiries</h1>
                        <h4 className="handleDescription">List of all Website Enquiries</h4>
                    </div>
                    <div className="mt-5">
                        <Link
                        // to="/webenquiry/addnewweb"
                        >
                            {/* <AddButton text="Add a Web" /> */}
                        </Link>
                    </div>
                </div>
                <div className="flex justify-between mt-3 mx-4 pl-4">
                    <div className="flex ">
                        <Search text="Search for Guide." />
                        {/* <div style={{ paddingLeft: "1rem", width: "200px" }}>
                            <DaysDropdown
                                options={cities}
                                selectedValue={selectedCity}
                                onChange={handleCityChange}
                            />
                        </div> */}
                    </div>
                    <PaginationNo
                        getPrevPage={getPrevPage}
                        totalPost={pagination.totalPost}
                        getNextPage={getNextPage}
                        currentPage={pagination.page}
                        post_per_page={pagination.postPerPage}
                    />
                </div>
                <div className="container pt-4 px-8 ">
                    <div className=" mt-2">
                        <div className=" container text-center py-4 ">
                            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                <table className="w-full border border-[#1d242e4d] text-gray-700 bg-white shadow-lg tablefont">
                                    <thead className="text-sm text-gray-700 font-semibold">
                                        <tr>
                                            <th scope="col" className=" w-1/7 py-3 text-lg">
                                                <div className=" items-center justify-center text-center ">
                                                    Web Id
                                                </div>
                                            </th>
                                            <th scope="col" className=" w-1/7 py-3 text-lg">
                                                <div className=" items-center justify-center text-center ">
                                                    Email
                                                </div>
                                            </th>
                                            <th scope="col" className="w-1/7 py-3 text-lg">
                                                <div className="flex items-center justify-center ">
                                                    Phone Number
                                                </div>
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {webenquiry && webenquiry.map((data) => {
                                            return (
                                                <tr className="border-t-2 pt-3 border-gray-300 table-text" key={data.id}>
                                                    <td className="pt-3">
                                                        <div className="w-1/7 flex justify-center items-center">
                                                            {/* {data.website_enquiry_id} */}
                                                            <CopyId id={data.website_enquiry_id} />
                                                        </div>
                                                    </td>
                                                    <td className="pt-3">
                                                        <div className="w-1/7 flex justify-center items-center">
                                                            {data.gmail || "NA"}
                                                        </div>
                                                    </td>
                                                    {/* <Link
                                                        to={`/guideenquiry/guideenquirydetails`}
                                                        style={{ display: "contents" }}
                                                        className="block hover:bg-gray-100"
                                                    > */}
                                                    <td className="pt-3">
                                                        <div className="flex items-center text-center  w-1/7 text-center mb-2 justify-center">
                                                            {data.phone || "NA"}
                                                        </div>
                                                    </td>

                                                    {/* </Link> */}
                                                </tr>
                                            )
                                        })}


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}
