import React from "react";
import TextBox from "./TextBox";
export default function AccountDetail() {
  return (
    <div className=" py-4 ">
      <div className="border-2 border-[#1d242e4d] rounded bg-white">
        <h2 className="py-3 px-6 font-bold">Account Details</h2>
        <h2 className="py-3 px-6 font-bold">Will come soon</h2>
        <div className="border-t-2 border-[#1d242e4d]">
          <form className="">
            <div className="flex flex-row mt-4">
              <div className="flex flex-col w-3/4">
                <TextBox
                  text="Bank Name"
                  placeholder="Bank Name"
                  type="text"
                  htmlfor="Bank Name"
                />
                <TextBox
                  text="Account Number"
                  placeholder="Account Number"
                  type="string"
                  htmlfor="Account Number"
                />
              </div>
              <div className="flex flex-col ml-6 w-3/4">
                <TextBox
                  text="Account Holder Number"
                  placeholder="Account Holder Number"
                  type="text"
                  htmlfor="Account Holder Number"
                />
                <TextBox
                  text="IFSC Number"
                  placeholder="IFSC Number"
                  type="string"
                  htmlfor="IFSC Number"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
