import React from "react";
import { Link } from "react-router-dom";
import ListOFCityComponent from "../sepcomponents/ListOFCityComponent";
import { CityListProvider } from "../../../controllers/CityList_controller";

function ListOfCities() {
  return (
    <>
      <div className="bg-gray-100 ml-64" style={{ backgroundColor: "#EDF1F5",height:"100vh" }}>
        <div className="container py-4 px-8">
          <CityListProvider>
            {/* City and Add City button  */}

            {/* Search and Pagination  */}

            <ListOFCityComponent />
            {/* List of Cities  */}
            {/* <div className="container py-4 px-16">
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-800 bg-white shadow-lg">
              <thead className="text-xs text-gray-700 uppercase">
                <tr>
                  <th scope="col" className="p-6">
                    <div className="flex items-center justify-center">
                      City ID
                      <a href="#">
                        <svg
                          class="w-3 h-3 ml-1.5"
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="13"
                          viewBox="0 0 8 13"
                          fill="none"
                        >
                          <path
                            d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
                            fill="#1D242E"
                          />
                          <path
                            d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
                            fill="#1D242E"
                          />
                        </svg>
                      </a>
                    </div>
                  </th>
                  <th scope="col" className="p-6">
                    <div className="flex items-center justify-center">
                      City Name
                      <a href="#">
                        <svg
                          class="w-3 h-3 ml-1.5"
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="13"
                          viewBox="0 0 8 13"
                          fill="none"
                        >
                          <path
                            d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
                            fill="#1D242E"
                          />
                          <path
                            d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
                            fill="#1D242E"
                          />
                        </svg>
                      </a>
                    </div>
                  </th>

                  <th scope="col" className="p-6">
                    <div className="flex items-center justify-center">
                      Total places
                      <a href="#">
                        <svg
                          class="w-3 h-3 ml-1.5"
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="13"
                          viewBox="0 0 8 13"
                          fill="none"
                        >
                          <path
                            d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
                            fill="#1D242E"
                          />
                          <path
                            d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
                            fill="#1D242E"
                          />
                        </svg>
                      </a>
                    </div>
                  </th>
                  <th scope="col" className="p-6 items-center justify-center">
                    <div className="flex items-center justify-center">
                      No of Activity Center
                      <a href="#">
                        <svg
                          class="w-3 h-3 ml-1.5"
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="13"
                          viewBox="0 0 8 13"
                          fill="none"
                        >
                          <path
                            d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
                            fill="#1D242E"
                          />
                          <path
                            d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
                            fill="#1D242E"
                          />
                        </svg>
                      </a>
                    </div>
                  </th>
                  <th scope="col" className="p-6">
                    <div className="flex items-center justify-center">
                      City Total No of Guides
                      <a href="#">
                        <svg
                          class="w-3 h-3 ml-1.5"
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="13"
                          viewBox="0 0 8 13"
                          fill="none"
                        >
                          <path
                            d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
                            fill="#1D242E"
                          />
                          <path
                            d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
                            fill="#1D242E"
                          />
                        </svg>
                      </a>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={index} className=" border-t border-gray-200">
                    <Link to="/places/listOfCities/listOfPlaces">
                      <td className=" px-6 py-4 ">{row.cityId}</td>
                      <td className=" px-6 py-4">{row.cityName}</td>
                      <td className=" px-6 py-4">{row.totalPlaces}</td>
                      <td className=" px-6 py-4">{row.noOfActivityCenters}</td>
                      <td className="text-center px-6 py-4">
                        {row.cityTotalNoOfGuides}
                      </td>
                    </Link>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div> */}
          </CityListProvider>
        </div>
      </div>
    </>
  );
}

export default ListOfCities;
