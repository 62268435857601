import { Booking } from "../constants/urls";
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useReducer,
} from "react";

const BookingContext = createContext();

const initialState = {
  booking: [],
  isloading: true,
  totalPost: 0,
  page: 1,
  post_per_page: 8,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_BOOKING":
      return {
        ...state,
        booking: action.payload,
        totalPost: action.payload.totalPost,
        post_per_page: 8,
        isloading: false,
      };
    case "SET_LOADING":
      return { ...state, isloading: true };

    case "NEXT":
      let pageNumInc = state.page + 1;
      if (pageNumInc > Math.ceil(state.totalPost / state.post_per_page)) {
        pageNumInc = 1;
      }
      return { ...state, page: pageNumInc };
    case "PREV":
      let pageNum = state.page - 1;
      if (pageNum <= 1) {
        pageNum = 1;
      }
      return { ...state, page: pageNum };
    default:
      return state;
  }
}

export function BookingProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    async function fetchInfo() {
      dispatch({ type: "SET_LOADING" });
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          `Token ${localStorage.getItem("token")}`
        );
        const queryParams = new URLSearchParams();
        queryParams.append("page", state.page);
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        const apiUrlWithQuery = `${Booking}?${queryParams.toString()}&page_size=${
          state.post_per_page
        }`;
        const response = await fetch(apiUrlWithQuery, requestOptions);
        const result = await response.json();

        if (response.ok) {
          dispatch({
            type: "SET_BOOKING",
            payload: {
              booking: result.results,
              totalPost: result.count,
            },
          });
        } else {
          console.log(`Error: ${result && result.error}`);
        }
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    }

    fetchInfo();
  }, [state.page]);
  const getNextPage = () => {
    dispatch({ type: "NEXT" });
  };
  const getPrevPage = () => {
    dispatch({ type: "PREV" });
  };
  return (
    <BookingContext.Provider value={{ state, getNextPage, getPrevPage }}>
      {children}
    </BookingContext.Provider>
  );
}
export function useBookingContext() {
  return useContext(BookingContext);
}
