import React, { useState } from "react";
import { useTagsContext } from "../../../controllers/Tag_controller";
import AddButton from "../components/AddButton";
import Popup from "../components/Popup";
import PaginationNo from "../components/PaginationNo";
import Search from "../components/Search";
import ActivityTagBox from "../components/ActivityTagBox";
import TagDetail from "../pages/TagDetail";
import Popup2 from "../components/Popup2";
export default function TagComponent() {
  function openForm() {
    document.getElementById("myForm2").style.display = "block";
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedActivityId, setSelectedActivityId] = useState(null);
  const openModal = (id) => {
    setSelectedActivityId(id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const { state, getNextPage, getPrevPage, setSearchQuery } = useTagsContext();

  let tagsData = state?.tag?.tag || [];
  const handleFormSubmit = (formData) => {
    fetch("https://orca-app-pgxkr.ondigitalocean.app/data/tags/", {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Data posted:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="container pt-4 px-11">
      <div>
        <div className="flex justify-between">
          <div className="px-1">
            <h1 className="handleHeader">Place Tag</h1>
            <span className="handleDescription">List of all Place Tags.</span>
          </div>
          {console.log("totalPost", state)}
          <div>
            <AddButton text="Add Tag" onClick={openForm} />
          </div>
        </div>

        <Popup2
          onFormSubmit={handleFormSubmit}
          text="Add New Tag"
          firsttext="Tag Id"
          secondtext="Tag Name"
        />

        <div className="flex justify-between">
          <Search
            text="Search for Place Tag.."
            setSearchQuery={setSearchQuery}
          />
          <PaginationNo
            post_per_page={state.post_per_page}
            getPrevPage={getPrevPage}
            totalPost={state.totalPost}
            getNextPage={getNextPage}
            currentPage={state.page}
          />
        </div>
      </div>
      <div className=" pl-1 text-center py-4  ">
        {state.isloading ? (
          <div>Loading...</div>
        ) : (
          <div>
            <ul className="grid gap-x-6 gap-y-8 sm:grid-cols-4 sm:gap-y-4 xl:col-span-2">
              {tagsData.map((person) => (
                <li key={person.tag_id}>
                  {/* <Link to="/places/listOfCities"> */}
                  <ActivityTagBox
                    key={person.tag_id}
                    image={person.image}
                    name={person.name}
                    total_places={person.total_places}
                    id={person.tag_id}
                    openModal={openModal}
                  />
                  {/* </Link> */}
                </li>
              ))}
            </ul>
            {isModalOpen && (
              <TagDetail
                id={selectedActivityId}
                isModalOpen={isModalOpen}
                closeModal={closeModal}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
