import { statistics } from "../constants/urls";
import React, { createContext, useState, useContext, useEffect } from "react";

const StatisticsContext = createContext();

export function StatisticsProvider({ children }) {
  const [statistic, setstatistic] = useState([]);

  useEffect(() => {
    async function fetchInfo() {
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          `Token ${localStorage.getItem("token")}`
        );
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        const response = await fetch(statistics, requestOptions);
        const result = await response.json();
        if (response.ok) {
          setstatistic(result);
        } else {
          console.log(`Error: ${result && result.error}`);
        }
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    }

    fetchInfo();
  }, []);

  return (
    <StatisticsContext.Provider value={{ statistic, setstatistic }}>
      {children}
    </StatisticsContext.Provider>
  );
}
export function useStatisticsContext() {
  return useContext(StatisticsContext);
}
