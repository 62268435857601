import React, { useMemo } from "react";
import { useTable } from "react-table";
import { Link } from "react-router-dom";
import Search from "../components/Search";
import PaginationNo from "../components/PaginationNo";
import { useGuideContext } from "../../../controllers/Guide_controller";
import CopyId from "../components/CopyId";
import DaysDropdown from "../components/DaysDropdown";
import Loading from "../components/Loading";

export default function GuideComponent() {
  const { state, setSearchQuery, getPrevPage, getNextPage } = useGuideContext();
  const guideData = state?.guide?.guide || [];
  const cities = [
    { value: 4, label: "City" },
    { value: 1, label: "New York" },
    { value: 2, label: "Los Angeles" },
    { value: 3, label: "Chicago" },
  ];
  const [selectedCity, setSelectedCity] = React.useState("City");
  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };
  return (
    <>
      <div className="flex justify-between">
        {console.log(state)}
        <div className="flex ">
          <Search text="Search for Guide." setSearchQuery={setSearchQuery} />
          <div style={{ paddingLeft: "1rem", width: "200px" }}>
            <DaysDropdown
              options={cities}
              selectedValue={selectedCity}
              onChange={handleCityChange}
            />
          </div>
        </div>
        <PaginationNo
          getPrevPage={getPrevPage}
          totalPost={state.totalPost}
          getNextPage={getNextPage}
          currentPage={state.page}
          post_per_page={state.post_per_page}
        />
      </div>
      {/* <div className="my-3 relative overflow-x-auto shadow-md sm:rounded-md border-2 border-gray-400">
        {console.log(state)}
        {console.log(guideData)}

      </div> */}
      <div className=" container text-center py-4 ">
        {state.isloading ? (
          <div>
            <Loading />
          </div>
        ) : (
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full border border-[#1d242e4d] text-gray-700 bg-white shadow-lg tablefont">
              <thead className="text-sm text-gray-700 font-semibold">
                <tr>
                  <th scope="col" className=" w-1/7 py-3 text-lg">
                    <div className=" items-center justify-center text-center ">
                      Guide ID
                    </div>
                  </th>
                  <th scope="col" className="w-1/7 py-3 text-lg">
                    <div className="flex items-center justify-center ">
                      Photo
                    </div>
                  </th>
                  <th scope="col" className="w-1/7 py-3 text-lg">
                    <div className="flex items-center justify-center">Name</div>
                  </th>

                  <th scope="col" className="w-1/7 py-3 text-lg">
                    <div className="flex items-center justify-center">
                      Gender
                    </div>
                  </th>
                  <th scope="col" className="w-1/7 py-3 text-lg">
                    <div className="flex items-center justify-center">City</div>
                  </th>
                  <th scope="col" className="w-1/7 py-3 text-lg">
                    <div className="flex items-center justify-center">
                      Trips
                      <a href="/">
                        <svg
                          class="w-3 h-3 ml-1.5"
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="13"
                          viewBox="0 0 8 13"
                          fill="none"
                        >
                          <path
                            d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
                            fill="#1D242E"
                          />
                          <path
                            d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
                            fill="#1D242E"
                          />
                        </svg>
                      </a>
                    </div>
                  </th>
                  <th scope="col" className="w-1/7 py-3 text-lg">
                    <div className="flex items-center justify-center ">
                      Rating
                      <a href="/">
                        <svg
                          class="w-3 h-3 ml-1.5"
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="13"
                          viewBox="0 0 8 13"
                          fill="none"
                        >
                          <path
                            d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
                            fill="#1D242E"
                          />
                          <path
                            d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
                            fill="#1D242E"
                          />
                        </svg>
                      </a>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody>
                {guideData.map((row) => (
                  <tr className="border-t-2 pt-3 border-gray-300 table-text">
                    <td className="pt-3">
                      <div className="w-1/7 flex justify-center items-center">
                        <CopyId id={row.guide_id} />
                      </div>
                    </td>
                    <Link
                      key={row.guide_id}
                      // to={`/places/EditPlaceDetails/${row.guide_id}/`}
                      to={`/guide/guideDetail/${row.guide_id}`}
                      style={{ display: "contents" }}
                      className="block hover:bg-gray-100"
                    >
                      <td
                        className="text-center w-1/7"
                        style={{ verticalAlign: "middle" }}
                      >
                        <div className="flex items-center justify-center h-full">
                          <img
                            className="h-8 w-8 rounded-full ring-1 ring-[#BBBDC0] ring-offset-1"
                            src={
                              row.image ||
                              "https://media.cntraveler.com/photos/57d87670fd86274a1db91acd/16:9/w_2580,c_limit/most-beautiful-paris-pont-alexandre-iii-GettyImages-574883771.jpg"
                            }
                            alt="tag"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src =
                                "https://media.cntraveler.com/photos/57d87670fd86274a1db91acd/16:9/w_2580,c_limit/most-beautiful-paris-pont-alexandre-iii-GettyImages-574883771.jpg";
                            }}
                          />
                        </div>
                      </td>

                      <td className="pt-3">
                        <div className="flex items-center text-center  w-1/7 text-center mb-2 justify-center">
                          {row.name}
                        </div>
                      </td>
                      <td className="text-center w-1/7">
                        <div className="flex items-center mb-2 justify-center">
                          {row.gender}
                        </div>
                      </td>
                      <td className="text-center w-1/7">
                        <div className="flex items-center mb-4 justify-center">
                          {row.city}
                        </div>
                      </td>
                      <td className="text-center w-1/7">
                        <div className="flex items-center mb-2 justify-center">
                          {row.total_trips}
                        </div>
                      </td>
                      <td className="text-center w-1/7">
                        <div className="flex items-center mb-2 justify-center">
                          {row.rating === null ? 0 : row.rating}
                        </div>
                      </td>
                    </Link>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}
