import React from "react";

export default function TextBox({
  text,
  type,
  placeholder,
  htmlfor,
  value,
  onChange,
}) {
  return (
    <div className="mb-4 mx-6 w-2/3">
      <div class="">
        <label
          for={htmlfor}
          class="block mb-2 text-sm font-medium text-gray-900 "
        >
          {text}
        </label>
        <input
          type={type}
          id=""
          class=" bg-slate-200 border  text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 border-[#1d242e4d] placeholder-gray-400 "
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
