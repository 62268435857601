import React from "react";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Search from "../components/Search";
import ThreeInfoBox from "../components/ThreeInfoBox";
import TwoInfoBox from "../components/TwoInfoBox";
import ActButton from "../components/ActButton";
import CopyId from "../components/CopyId";
export default function BookingDetails() {
  const { booking_id } = useParams();
  const [booking, setBooking] = useState({ status: "" });
  const [updatedStatus, setUpdatedStatus] = useState({ status: "" });
  const [Statusbooking, setStatusBooking] = useState(booking.status);
  const apiUrl = `https://orca-app-pgxkr.ondigitalocean.app/dashboard/booking-detail/${booking_id}`;

  useEffect(() => {
    async function fetchInfo() {
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          `Token ${localStorage.getItem("token")}`
        );
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(apiUrl, requestOptions);
        const result = await response.json();
        // console.log(result);
        if (response.ok) {
          setBooking(result);
          setStatusBooking(result.status);
        } else {
          console.log(`Error: ${result.error}`);
        }
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    }

    fetchInfo();
  }, [apiUrl]);

  // const apiUrlForStatus = `https://orca-app-pgxkr.ondigitalocean.app/dashboard/booking-detail/${booking_id}`;
  // useEffect(() => {
  //   async function fetchBookingStatus() {
  //     try {
  //       // Fetch booking status from your server/database
  //       const response = await fetch(apiUrlForStatus); // Replace with your API endpoint
  //       const result = await response.json();
  //       if (response.ok) {
  //         setUpdatedStatus(result.status); // Set the fetched status
  //         // console.log(result.status);
  //       } else {
  //         console.log(`Error fetching status: ${result.error}`);
  //       }
  //     } catch (error) {
  //       console.error(`Error: ${error}`);
  //     }
  //   }
  //   fetchBookingStatus();
  // }, [apiUrlForStatus]);

  const updateStatus = async (newStatus) => {
    try {
      const updateStatusUrl = `https://orca-app-pgxkr.ondigitalocean.app/dashboard/booking-detail/${booking_id}/update-status/`;

      // Send a PATCH request with the updated status
      const response = await fetch(updateStatusUrl, {
        method: "PATCH",

        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({ status: newStatus }), // Send the updated status
      });

      const result = await response.json();
      if (response.ok) {
        setUpdatedStatus(newStatus); // Update the status in the component state
        // console.log("Booking status updated:", newStatus);
        setStatusBooking(newStatus);
      } else {
        console.log(`Error: ${result.error}`);
      }
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  };

  return (
    <div className="bg-[#EDF1F5] ml-64">
      {/* heading Content  */}
      {/* {console.log(booking)} */}
      <div className="container py-4 px-8">
        <div className="flex justify-between">
          <div className="">
            <h1 className="handleHeader">Booking</h1>
            <p className="handleDescription py-1">Details of Booking</p>
          </div>
          <div className="flex flex-row mr-6">
            <button
              onClick={() => {
                if (updatedStatus !== "Confirmed") {
                  updateStatus("Confirmed");
                }
              }}
              disabled={updatedStatus === "Confirmed"}
              className={`bg-[#01A768] text-white  px-4 py-2 my-auto rounded ${
                updatedStatus === "Confirmed"
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
            >
              Confirm
            </button>

            <button
              onClick={() => {
                if (updatedStatus !== "Cancelled") {
                  updateStatus("Cancelled");
                }
              }}
              disabled={updatedStatus === "Cancelled"}
              className={`bg-[#F0483E] ml-3 text-white px-4 py-2 my-auto rounded ${
                updatedStatus === "Cancelled"
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
            >
              Cancel
            </button>
          </div>
        </div>
        <Search text={"Search in Booking Details"} />
        <ThreeInfoBox
          heading={"Details"}
          fbottom={"Booking Id"}
          ftop=<CopyId id={booking.booking_id} />
          stop={booking.start_date}
          sbottom={"Booking Order"}
          ttop={booking.city_name}
          tbottom={"Booking City"}
        />
        <div className="flex flex-row">
          <div className="w-1/2">
            <TwoInfoBox
              heading={"Guide Details"}
              ftop=<CopyId id={booking.guide} />
              fbottom={"Guide Id"}
              stop={booking.guide_name}
              sbottom={"Guide Name"}
            />
          </div>
          {/* {console.log(Statusbooking)} */}
          <div className="w-1/2 pl-4">
            <TwoInfoBox
              heading={"Status"}
              ftop={Statusbooking}
              fbottom={"Booking Status"}
              stop={booking.payment_status}
              sbottom={"Payment Status"}
            />
          </div>
        </div>

        <ThreeInfoBox
          heading={"Package"}
          ftop={booking.total_days}
          fbottom={"No of Days"}
          // stop={"5000"}
          // sbottom={"Selected Package"}
          ttop={booking.total_price}
          tbottom={"Booking Amount"}
        />
        <ThreeInfoBox
          heading={"User Details"}
          ftop=<CopyId id={booking.user} />
          fbottom={"USer Id"}
          stop={booking.user_name}
          sbottom={"User Name"}
          ttop={booking.no_of_persons}
          tbottom={"No of Members"}
        />
        {/* Package Details */}
        <div className="">
          <div className="py-4 pr-6">
            <div className="border-2 border-[#1d242e4d] rounded bg-white">
              <h2 className="py-3 px-6 font-bold">Package Details</h2>
              <div className="border-t-2 border-[#1d242e4d]">
                <table className="w-full">
                  {/* <thead>
                    <tr className="text-center w-full">
                      <th className="px-2d py-2">Place ID</th>
                      <th className="px-2d py-2">Place</th>
                      <th className="text-left px-16 py-2">Activities</th>
                    </tr>
                  </thead> */}
                  <tbody>
                    <div className="text-center py-4 text-xl ">
                      Coming soon...
                      {/* {BookingData.map((row) => (
                        <tr
                          key={row.PlaceId}
                          className="w-full border-t border-gray-200"
                        >
                          <td className="text-center px-1 py-2">
                            {row.PlaceId}
                          </td>
                          <td className="text-center px-3 py-2">{row.Place}</td>
                          <td className="text-center  py-2">
                            <ActButton text={row.Activities} />
                          </td>
                        </tr>
                      ))} */}
                    </div>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="w-full">
          <TwoInfoBox
            heading={"Payment"}
            ftop={"Pending"}
            fbottom={"Will be coming soon."}
            stop={"CNI02000800594"}
            sbottom={"Transaction Id"}
          />
          <div className="text-center py-4 text-xl ">Will be coming soon.</div>
        </div> */}
      </div>
    </div>
  );
}
