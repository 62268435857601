import React, { useEffect } from "react";
import { useState } from "react";
import darkLogo from "../assets/logoDark.png";
import Search from "./Search";
export default function Header({ logout }) {
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.toLocaleString("default", { month: "long" });
  const year = currentDate.getFullYear();

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const formattedTime = currentTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  let greetingMessage = "";
  const currentHour = currentTime.getHours();
  const isDaytime = currentHour >= 5 && currentHour < 18;

  const circleFill = isDaytime ? "#FED600" : "#FFFFFF";
  if (currentHour >= 5 && currentHour < 12) {
    greetingMessage = "Good Morning";
  } else if (currentHour >= 12 && currentHour < 18) {
    greetingMessage = "Good Afternoon";
  } else {
    greetingMessage = "Good Night";
  }

  return (
    <>
      <aside id="default-sidebar colorsidebar ">
        <nav className="color header border-b-2 border-gray-200 lg:px-16 py-1 dark:bg-[#F7FAFD] ">
          <div className="flex flex-wrap justify-between items-center">
            <div className="flex justify-start items-center pb-2.5">
              <a href="/" className="flex mr-4 ">
                <img
                  src={darkLogo}
                  className="mx-6 h-12 w-40 "
                  alt="GoWild Logo"
                />
              </a>

              {/* <button
                aria-expanded="true"
                aria-controls="sidebar"
                className="p-2 mr-2 text-gray-600 rounded-lg cursor-pointer lg:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 dark:focus:bg-gray-700 focus:ring-2 focus:ring-gray-100 dark:focus:ring-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                <svg
                  aria-hidden="true"
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <svg
                  aria-hidden="true"
                  className="hidden w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Toggle sidebar</span>
              </button> */}
              {/* <form action="#" method="GET" className="hidden lg:block lg:pl-2">
                <label for="topbar-search" className="sr-only">
                  Search
                </label>
                <div className="relative mt-1 lg:w-96">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </div>
                  <input
                    type="text"
                    name="email"
                    id="topbar-search"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Search"
                  />
                </div>
              </form> */}
              <Search text={"Search for anything here.."} />
            </div>
            <div className="flex items-center lg:order-2 lg:pr-0 pr-3">
              {/* <button
                id="toggleSidebarMobileSearch"
                type="button"
                className="p-2 text-gray-500 rounded-lg lg:hidden hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
              >
                <span className="sr-only">Search</span>
              
                <svg
                  aria-hidden="true"
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  ></path> 
                </svg>
              </button> */}

              {/* <div className="mx-8">
                <button
                  type="button"
                  className="flex items-center p-2 w-full text-base font-normal text-gray-900 rounded-lg transition duration-75 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                  aria-controls="dropdown-sales"
                  data-collapse-toggle="dropdown-sales"
                >
                  <svg
                    aria-hidden="true"
                    className="w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.725 12.5583L8.60833 10.4667L8.63333 10.4417C10.0833 8.82499 11.1167 6.96666 11.725 4.99999H14.1667V3.33332H8.33333V1.66666H6.66666V3.33332H0.833328V4.99166H10.1417C9.58333 6.59999 8.69999 8.12499 7.49999 9.45832C6.72499 8.59999 6.08333 7.65832 5.57499 6.66666H3.90833C4.51666 8.02499 5.34999 9.30832 6.39166 10.4667L2.14999 14.65L3.33333 15.8333L7.49999 11.6667L10.0917 14.2583L10.725 12.5583ZM15.4167 8.33332H13.75L9.99999 18.3333H11.6667L12.6 15.8333H16.5583L17.5 18.3333H19.1667L15.4167 8.33332ZM13.2333 14.1667L14.5833 10.5583L15.9333 14.1667H13.2333Z"
                      fill="#fff"
                    />
                  </svg>
                  <span className="flex-1 ml-3 text-sm text-left whitespace-nowrap">
                    English (US)
                  </span>
                  <svg
                    aria-hidden="true"
                    className="w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div> */}

              <div className="">
                <div
                  className="flex  justify-end text-sm text-gray-900 bg-white-800 rounded-full   "
                  id="user-menu-button"
                >
                  {/* <span className="pr-10"></span> */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <circle cx="9" cy="9" r="9" fill={circleFill} />
                  </svg>
                  <span className=" ml-2 text-black text-sm font-semibold">
                    {greetingMessage}
                  </span>
                </div>
                <span className="text-xs dark:text-black">
                  {day + " "}
                  {month + " "}
                  {year}
                </span>
                <span className="ml-3 text-xs dark:text-black">
                  {formattedTime}
                </span>
              </div>
            </div>
          </div>
        </nav>
      </aside>
    </>
  );
}
