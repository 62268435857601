import React from "react";
import { Link } from "react-router-dom";
import AddButton from "../components/AddButton";
import DaysDropdown from "../components/DaysDropdown";
import Search from "../components/Search";
import PaginationNo from "../components/PaginationNo";
import ImgActivity from "../components/ImgActivity";
import { useCenterContext } from "../../../controllers/Center_controller";
export default function CenterComponent({ cities, place }) {
  const { state, getNextPage, getPrevPage, setSearchQuery } =
    useCenterContext();
  const Centerdata = state?.center?.center || [];
  const [selectedPlace, setSelectedPlace] = React.useState("");
  const [selectedCity, setSelectedCity] = React.useState("City");
  const handleCityChange = (e) => {
    setSelectedCity(e.target.value);
  };
  const handlePlaceChange = (e) => {
    setSelectedPlace(e.target.value);
  };
  return (
    <div>
      <div className="ml-60 ">
        {/* places and add new Places  */}
        {/* {console.log("data", state.center)} */}
        <div className=" pt-5 px-16">
          <div className="">
            <div className="">
              <div className="flex justify-between">
                <div>
                  <h1 className="handleHeader">Activity Centers</h1>
                  <span className="handleDescription">
                    List of all Activity Centers.
                  </span>
                </div>
                <div>
                  <Link to="/activityCenter/addActivityCenter">
                    <AddButton text={"Add Activity Center"} />
                  </Link>
                </div>
              </div>
              <div className="flex flex-row ">
                <Search
                  text={"Search for Activity Center"}
                  setSearchQuery={setSearchQuery}
                />
                <div style={{ paddingLeft: "1rem", width: "150px" }}>
                  <DaysDropdown
                    options={cities}
                    selectedValue={selectedCity}
                    onChange={handleCityChange}
                  />
                </div>
                <div style={{ paddingLeft: "1rem", width: "160px" }}>
                  <DaysDropdown
                    options={place}
                    selectedValue={selectedPlace}
                    onChange={handlePlaceChange}
                  />
                </div>
                <div className="absolute grid place-items-end right-16 py-2">
                  <PaginationNo
                    post_per_page={state.post_per_page}
                    getPrevPage={getPrevPage}
                    totalPost={state.totalPost}
                    getNextPage={getNextPage}
                    currentPage={state.page}
                  />
                </div>
              </div>
              <div className="pt-4 flex flex-wrap">
                {Centerdata &&
                  Array.isArray(Centerdata) &&
                  Centerdata.map((item) => (
                    <Link to={`/activityCenter/details/${item.center_id}`}>
                      <ImgActivity
                        key={item.center_id}
                        img={item.images[0]}
                        title={item.name}
                        subtitle={item.type}
                        activites={item.activities}
                      />
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
