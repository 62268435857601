import React, { useEffect, useRef } from "react";
import Select from "react-select";
import { useActivityContext } from "../../../controllers/Activity_controller";

export default function ActivityDropdown({
  editing,
  selectedActivities,
  onSelect,
  ids,
}) {
  const activityData = useActivityContext();
  const activities = activityData?.state?.activity?.activity || [];
  const { setSearchQuery } = useActivityContext();
  const containerRef = useRef(null);

  const CustomOption = ({ innerProps, label, image }) => (
    <div {...innerProps}>
      <div className="flex py-2 px-4">
        <div className="flex items-center justify-center h-full">
          <img
            className="h-6 w-6 rounded-full"
            src={
              image ||
              "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ="
            }
            alt={label}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ=";
            }}
          />
        </div>
        <span className="ml-2">{label}</span>
      </div>
    </div>
  );

  const activityOptions = activities.map((activity) => ({
    value: activity.activity_id,
    label: activity.name,
    image:
      activity.image ||
      "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ=",
  }));
  useEffect(() => {
    if (editing && ids) {
      const selectedActivities = ids.map((id) => ({
        value: id.id,
        label: id.name,
      }));
      onSelect(selectedActivities);
    }
  }, [editing, ids]);

  // const activityOptions = activityData?.state?.activity?.activity || [];
  return (
    <div className="mb-4 ml-6 mr-2" ref={containerRef}>
      {/* {console.log(ids)} */}
      {/* {console.log("selectedActivities", selectedActivities)} */}

      <div className="flex">
        <label
          htmlFor="dropdown"
          className="block mb-2 text-md font-medium text-gray-900"
        >
          Center Activities
        </label>
      </div>

      <Select
        id="dropdown"
        name="dropdown"
        placeholder="Place Activity"
        options={activityOptions}
        value={selectedActivities.map((tag) => ({
          value: tag.value,
          label: tag.label,
        }))}
        onInputChange={(inputValue) => {
          setSearchQuery(inputValue);
        }}
        onChange={(selectedOptions) => {
          const selectedValues = selectedOptions.map((option) => ({
            value: option.value,
            label: option.label,
          }));
          onSelect(selectedValues);
        }}
        isMulti
        styles={{
          control: (provided, state) => ({
            ...provided,
            width: "100%",
            zIndex: "50",
          }),
          multiValue: (provided) => ({
            ...provided,
            background: "#A7DBF5",
            borderRadius: "5px",
            padding: "2px",
            fontSize: "16px",
            fontWeight: "600",
          }),
          multiValueRemove: (provided, state) => ({
            ...provided,
            color: "black",
            backgroundColor: "#E5E5E5",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            marginTop: "3px",
            margin: "4px",
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            color: "black", // Change the color to black
          }),
        }}
        components={{ Option: CustomOption }}
      />
    </div>
  );
}
// import React, { useEffect, useRef } from "react";
// import Select from "react-select";
// import { useActivityContext } from "../../../controllers/Activity_controller";

// export default function ActivityDropdown({
//   editing,
//   selectedActivities,
//   onSelect,
//   ids,
// }) {
//   const activityData = useActivityContext();
//   const activities = activityData?.state?.activity?.activity || [];

//   const containerRef = useRef(null);

//   const CustomOption = ({ innerProps, label, image }) => (
//     <div {...innerProps}>
//       <div className="flex py-2 px-4">
//         <div className="flex items-center justify-center h-full">
//           <img
//             className="h-6 w-6 rounded-full"
//             src={
//               image ||
//               "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ="
//             }
//             alt={label}
//             onError={(e) => {
//               e.target.onerror = null;
//               e.target.src =
//                 "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ=";
//             }}
//           />
//         </div>
//         <span className="ml-2">{label}</span>
//       </div>
//     </div>
//   );

//   const activityOptions = activities.map((activity) => ({
//     value: activity.activity_id,
//     label: activity.name,
//     image:
//       activity.image ||
//       "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ=",
//   }));
//   useEffect(() => {
//     if (editing && ids) {
//       const id = ids.map((activity) => activity.id);
//       if (id) {
//         onSelect([...selectedActivities, ...id]);
//       }
//     }
//   }, [editing, ids]);

//   // const activityOptions = activityData?.state?.activity?.activity || [];
//   return (
//     <div className="mb-4 ml-6 mr-2" ref={containerRef}>
//       {/* {console.log(activityData)} */}
//       {console.log("selectedActivities", selectedActivities)}

//       <div className="flex">
//         <div className="flex">
//           <label
//             htmlFor="dropdown"
//             className="block mb-2 text-md font-medium text-gray-900"
//           >
//             Place Activity
//           </label>
//         </div>
//       </div>
//       <Select
//         id="dropdown"
//         name="dropdown"
//         placeholder="Place Activity"
//         options={activityOptions}
//         value={activityOptions.filter((option) =>
//           selectedActivities.includes(option.value)
//         )}
//         onChange={(selectedOptions) => {
//           // Map the selectedActivities array to the selectedValues array.
//           let selectedValues = selectedActivities.map((option) => option);
//           selectedValues = selectedOptions.map((option) => ({
//             id: option.value,
//             name: option.label,
//           }));

//           onSelect(selectedValues);
//         }}
//         isMulti
//         styles={{
//           control: (provided, state) => ({
//             ...provided,
//             width: "100%",
//             zIndex: "50",
//           }),
//           multiValue: (provided) => ({
//             ...provided,
//             background: "#A7DBF5",
//             borderRadius: "5px",
//             padding: "2px",
//             fontSize: "16px",
//             fontWeight: "600",
//           }),
//           multiValueRemove: (provided, state) => ({
//             ...provided,
//             color: "black",
//             backgroundColor: "#E5E5E5",
//             borderRadius: "50%",
//             width: "20px",
//             height: "20px",
//             marginTop: "3px",
//             margin: "4px",
//           }),
//           dropdownIndicator: (provided) => ({
//             ...provided,
//             color: "black", // Change the color to black
//           }),
//         }}
//         components={{ Option: CustomOption }}
//       />
//     </div>
//   );
// }
