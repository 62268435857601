import React, { useEffect, useState } from "react";
import SampleModal from "../components/SampleModal";
import { activityDetail } from "../../../constants/urls";
export default function ActivityDetail({ isModalOpen, closeModal, id }) {
  const [activityData, setActivityData] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState("");
  const [tid, setTid] = useState("");
  const [image, setImage] = useState("");
  const [editing, setEditing] = useState(false);
  useEffect(() => {
    // setIsLoading(true);
    const apiUrl = `${activityDetail}${id}`;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Token ${localStorage.getItem("token")}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        setIsLoading(false);
        setActivityData(data);
        setTid(data.activity_id);
        setImage(data.image);
        setName(data.name);
      })

      .catch((error) => console.log("Error:", error));
  }, [id]);
  const [isModalOpe, setIsModalOpe] = useState(isModalOpen);
  const saveModal = (event) => {
    const bodyData = {
      name: name,
    };

    if (image !== null && image instanceof File) {
      bodyData.image = image;
    }

    const formData = new FormData();
    formData.append("activity_id", tid);
    formData.append("name", name);

    if (image !== null && image instanceof File) {
      formData.append("image", image);
    } else {
      bodyData.image = image;
      console.log("bodydata");
    }
    const jsonData = {
      name: name,
    };

    formData.append(
      "data",
      new Blob([JSON.stringify(jsonData)], { type: "application/json" })
    );

    fetch(`${activityDetail}${id}/`, {
      method: "PATCH",
      body: formData,
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Data posted:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setIsModalOpe(false);
    setEditing(false);
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
    const imgElement = document.getElementById("selectedImage");
    imgElement.src = URL.createObjectURL(selectedImage);
  };
  return (
    <div>
      <SampleModal
        isOpen={isModalOpe}
        onClose={() => setIsModalOpe(false)}
        closeModal={closeModal}
        h1="Activity Id"
        h2="Activity Name"
        h3={name}
        name={name}
        image={image}
        tid={tid}
        setImage={setImage}
        setName={setName}
        saveModal={saveModal}
        editing={editing}
        setEditing={setEditing}
        handleImageChange={handleImageChange}
      />
    </div>
  );
}
{
  /* <div className="form-popup backdrop-blur-md bg-black/30" id="tag-details">
        <form action="/" class="form-container">
          <h1 style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
            {activityData?.name || ""}
          </h1>
          <hr className="my-4" />
          <div className="flex">
            <div className="w-1/3 flex justify-center items-center">
              <img
                className="h-24 w-24 rounded-full  my-3 ring-1 ring-[#BBBDC0] ring-offset-1"
                src={
                  activityData.image ||
                  "https://www.intrepidtravel.com/adventures/wp-content/uploads/2015/02/Intrepid-Travel-Chile-Patagonia-trekking-002.jpg"
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    "https://www.intrepidtravel.com/adventures/wp-content/uploads/2015/02/Intrepid-Travel-Chile-Patagonia-trekking-002.jpg";
                }}
                alt="act"
              />
            </div>
            <div className="inputFeild px-2 w-1/2">
              <label for={"Activity Id"} className="font-semibold">
                Activity Id
              </label>
              <p>{activityData?.activity_id || ""}</p>

              <label for={"Activity name"} className="font-semibold">
                Activity name
              </label>
              <p>{activityData?.name || ""}</p>
            </div>
          </div>
          <div className="flex  bottomButtom">
            <button
              type="button"
              className="px-2 btn cancel"
              onClick={closeForm}
            >
              Cancel
            </button>
            <button type="submit" className="px-2 btn">
              Save
            </button>
          </div>
        </form>
      </div> */
}
