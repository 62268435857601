import React from "react"; // Import FontAwesome icons

export default function ActButton({ text }) {
  return (
    <div>
      {text.map((activity, index) => (
        <button
          className="actcenterbtn pl-1 py-1"
          key={index}
          style={{
            backgroundColor: "#A7DBF5",
            color: "black",
            border: "none",
            cursor: "pointer",
            fontSize: "14px",
            borderRadius: "7px",
          }}
        >
          {activity.length > 15 ? activity.slice(0, 10) + "..." : activity}
          <button className="px-2" style={{ position: "relative" }}>
            <i
              className="fa-solid fa-circle"
              style={{ color: "lightgray", fontSize: "17px" }}
            ></i>
            <i
              className="fa-solid fa-times"
              style={{
                color: " black",
                fontSize: "12px",
                position: "absolute",
                top: 5,
                left: 12,
              }}
            ></i>
          </button>
        </button>
      ))}
    </div>
  );
}
