import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Header from "./Header";
import SideBar from "./SideBar";
import Home from "../pages/Home";
import Booking from "../pages/Booking";
import Places from "../pages/Places";
import Guide from "../pages/Guide";
import Notifications from "../pages/Notifications";
import NoPage from "../pages/NoPage";
import ListOfCities from "../pages/ListOfCities";
import ListOfPlaces from "../pages/ListOfPlaces";
import AddNewPlacePage from "../pages/AddNewPlacePage";
import EditPlaceDetailsPage from "../pages/EditPlaceDetailsPage";
import Revenue from "../pages/Revenue";
import ActivityTag from "../pages/ActivityTag";
import AddNewGuide from "../pages/AddNewGuide";
import BookingDetails from "../pages/BookingDetails";
import ActivityCenters from "../pages/ActivityCenters";
import AddActivityCenter from "../pages/AddActivityCenter";
import ActivityCentersDetails from "../pages/ActivityCentersDetails";
import TotalPlacesOfCity from "../pages/TotalPlacesOfCity";
import AddCity from "../pages/AddCity";
import DetailsCity from "../pages/DetailsCity";
import GuideDetails from "../pages/GuideDetails";
import Popup from "./Popup";
import AddNotification from "../pages/AddNotification";
import Guideenquirydetails from "../pages/Guideenquirydetails";
import Guideenquiry from "../pages/Guideenquiry";
import Webenquiry from "../pages/Webenquiry";
import Addstay from "../pages/Addstay";
import Addrentvehicle from "../pages/Addrentvehicle";
import Addcafe from "../pages/Addcafe";
import Addshopgear from "../pages/Addshopgear";
import Stay from "../pages/Stay";
import Addselfcamping from "../pages/Addselfcamping";
import Selfcaming from "../pages/Selfcaming";
import Cafe from "../pages/Cafe";
import Rentvehicle from "../pages/Rentvehicle";
import Editstay from "./Editstay";
import Editrentvehicle from "../pages/Editrentvehicle";
import Editcafe from "../pages/Editcafe";
import Editselfcaming from "../pages/Editselfcaming";
import Shop from "../pages/Shop";
import Addshop from "../pages/Addshop";
import Editshop from "../pages/Editshop";
import Shopgear from "../pages/Shopgear";
import EditShopGear from "../pages/EditShopGear";

export default function Dashboard({ logout }) {
  return (
    <>
      <BrowserRouter>
        <SideBar logout={logout} />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Booking" element={<Booking />} />
          <Route path="/places" element={<Places />} />
          <Route path="/places/addNewPlaces" element={<AddNewPlacePage />} />
          <Route path="/places/addCity" element={<AddCity />} />
          <Route
            path="/places/EditPlaceDetails/:place_id"
            element={<EditPlaceDetailsPage />}
          />
          <Route
            path="/places/DetailsCity/:city_id"
            element={<DetailsCity />}
          />
          <Route
            path="/booking/BookingDetails/:booking_id"
            element={<BookingDetails />}
          />
          <Route path="/places/listOfCities" element={<ListOfCities />} />
          <Route path="/places/listOfPlaces" element={<ListOfPlaces />} />
          <Route
            path="/places/listOfCities/:city_id/listOfPlaces"
            element={<TotalPlacesOfCity />}
          />
          <Route path="/activityCenter" element={<ActivityCenters />} />
          <Route
            path="/activityCenter/addActivityCenter"
            element={<AddActivityCenter />}
          />
          <Route
            path="/activityCenter/details/:center_id"
            element={<ActivityCentersDetails />}
          />
          <Route path="/guide" element={<Guide />} />
          <Route
            path="/guide/guideDetail/:guide_id"
            element={<GuideDetails />}
          />
          <Route path="/guide/addNewGuide" element={<AddNewGuide />} />
          <Route path="/revenue" element={<Revenue />} />
          <Route path="/activityandtags" element={<ActivityTag />} />
          <Route path="/activityandtags/:activity_id" element={<Popup />} />
          {/* <Route  path='/Users' element={ <Users/>}/> */}
          <Route path="/Notifications" element={<Notifications />} />
          <Route path="/guideenquiry" element={<Guideenquiry />} />
          <Route
            path="/guideenquiry/guideenquirydetails"
            element={<Guideenquirydetails />}
          />
          <Route path="/webenquiry" element={<Webenquiry />} />
          <Route path="/stay" element={<Stay />} />
          <Route path="/stay/addstay" element={<Addstay />} />
          <Route path="/editstay/:stay_id" element={<Editstay />} />
          <Route
            path="/rentvehicle/addrentvehicle"
            element={<Addrentvehicle />}
          />
          <Route path="/cafe/addcafe" element={<Addcafe />} />
          <Route path="/cafe" element={<Cafe />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/shop/addshop" element={<Addshop />} />
          <Route path="/editshop/:shop_id" element={<Editshop />} />
          <Route path="/editcafe/:cafe_id" element={<Editcafe />} />
          <Route
            path="/editrentvehicle/:rent_id"
            element={<Editrentvehicle />}
          />
          <Route path="/rentvehicle" element={<Rentvehicle />} />
          <Route path="/shopgear" element={<Shopgear />} />
          <Route path="/shopgear/addshopgear" element={<Addshopgear />} />
          <Route
            path="/selfcaming/addselfcaming"
            element={<Addselfcamping />}
          />
          <Route path="/selfcaming" element={<Selfcaming />} />
          <Route
            path="/editselfcaming/:selfcaming_id"
            element={<Editselfcaming />}
          />
          <Route path="/editshopgear/:shopgear_id" element={<EditShopGear />} />
          <Route
            path="/Notifications/addNotification"
            element={<AddNotification />}
          />
          <Route path="/*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
