import React from "react";
import { useStatisticsContext } from "../../../controllers/Statistics_controller";
import { Link } from "react-router-dom";
export default function StatiscticsComponent() {
  const { statistic } = useStatisticsContext();
  const currentDate = new Date();
  const lastMonthDate = new Date(currentDate);

  lastMonthDate.setMonth(currentDate.getMonth() - 1);

  const lastMonthString = lastMonthDate.toLocaleString("en-US", {
    month: "long",
    year: "numeric",
  });
  return (
    <div style={{ backgroundColor: "#EDF1F5", height: "100vh" }}>
      <div className="container handleBack py-4 px-8">
        <div className="grid grid-cols-4 gap-10 ">
          <div className=" bg-white border-2 border-[#01A768] flex-col items-center justify-center text-center">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 40 25"
                fill="none"
                className="mx-auto mt-6"
              >
                <path
                  d="M6 12C8.20625 12 10 10.2063 10 8C10 5.79375 8.20625 4 6 4C3.79375 4 2 5.79375 2 8C2 10.2063 3.79375 12 6 12ZM34 12C36.2062 12 38 10.2063 38 8C38 5.79375 36.2062 4 34 4C31.7938 4 30 5.79375 30 8C30 10.2063 31.7938 12 34 12ZM36 14H32C30.9 14 29.9062 14.4438 29.1812 15.1625C31.7 16.5438 33.4875 19.0375 33.875 22H38C39.1063 22 40 21.1063 40 20V18C40 15.7937 38.2062 14 36 14ZM20 14C23.8687 14 27 10.8687 27 7C27 3.13125 23.8687 0 20 0C16.1313 0 13 3.13125 13 7C13 10.8687 16.1313 14 20 14ZM24.8 16H24.2812C22.9813 16.625 21.5375 17 20 17C18.4625 17 17.025 16.625 15.7188 16H15.2C11.225 16 8 19.225 8 23.2V25C8 26.6562 9.34375 28 11 28H29C30.6562 28 32 26.6562 32 25V23.2C32 19.225 28.775 16 24.8 16ZM10.8188 15.1625C10.0938 14.4438 9.1 14 8 14H4C1.79375 14 0 15.7937 0 18V20C0 21.1063 0.89375 22 2 22H6.11875C6.5125 19.0375 8.3 16.5438 10.8188 15.1625Z"
                  fill="#01A768"
                />
              </svg>
              <h2 className=" text-3xl font-extrabold">
                {console.log(statistic)}
                {statistic?.total_users || 0}
              </h2>
              <p className="mb-3 font-semibold">Total users</p>
            </div>

            {/* <div className="border-t-2 border-[#01A768] bg-[#01a7684d] flex justify-center px-4 py-1">
              <span className="mr-3"><Link to="/places/listOfCities">View Detailed Report</Link></span>
              <p className="mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                >
                  <path
                    d="M0.209396 1.22595L2.98702 4.00358L0.209396 6.78121C-0.0697987 7.0604 -0.0697987 7.51141 0.209396 7.7906C0.488591 8.0698 0.939597 8.0698 1.21879 7.7906L4.5047 4.5047C4.78389 4.2255 4.78389 3.7745 4.5047 3.4953L1.21879 0.209396C0.939597 -0.0697985 0.48859 -0.0697985 0.209396 0.209396C-0.0626402 0.488591 -0.069799 0.946756 0.209396 1.22595Z"
                    fill="#1D242E"
                  />
                  <path
                    d="M4.2094 1.22595L6.98702 4.00358L4.2094 6.78121C3.9302 7.0604 3.9302 7.51141 4.2094 7.7906C4.48859 8.0698 4.9396 8.0698 5.21879 7.7906L8.5047 4.5047C8.78389 4.2255 8.78389 3.7745 8.5047 3.4953L5.21879 0.209396C4.9396 -0.0697985 4.48859 -0.0697985 4.2094 0.209396C3.93736 0.488591 3.9302 0.946756 4.2094 1.22595Z"
                    fill="#1D242E"
                  />
                </svg>
              </p>
            </div> */}
          </div>
          <div className="bg-white border-2 border-[#F0483E] flex-col items-center justify-center text-center">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 37 37"
                fill="none"
                className="mx-auto mt-5 mb-1"
              >
                <path
                  d="M19 27.5263C11.265 27.5263 5 30.3526 5 33.8421V37H33V33.8421C33 30.3526 26.735 27.5263 19 27.5263ZM12 18.0526C12 19.7277 12.7375 21.3341 14.0503 22.5186C15.363 23.703 17.1435 24.3684 19 24.3684C20.8565 24.3684 22.637 23.703 23.9497 22.5186C25.2625 21.3341 26 19.7277 26 18.0526M18.125 7C17.6 7 17.25 7.33158 17.25 7.78947V12.5263H15.5V8.57895C15.5 8.57895 11.5625 9.93684 11.5625 14.5C11.5625 14.5 10.25 14.7211 10.25 16.4737H27.75C27.6625 14.7211 26.4375 14.5 26.4375 14.5C26.4375 9.93684 22.5 8.57895 22.5 8.57895V12.5263H20.75V7.78947C20.75 7.33158 20.4175 7 19.875 7H18.125Z"
                  fill="#F0483E"
                />
              </svg>
              <h2 className=" font-extrabold text-3xl">
                {statistic?.total_guides || 0}
              </h2>
              <p className="mb-3 font-semibold">Total Guide</p>
            </div>

            <div className="border-t-2 border-[#F0483E] bg-[#f0483e4d] flex justify-center px-4 py-1">
              <span className="mr-3"><Link to="/guide">View Detailed Report</Link></span>
              <p className="mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                >
                  <path
                    d="M0.209396 1.22595L2.98702 4.00358L0.209396 6.78121C-0.0697987 7.0604 -0.0697987 7.51141 0.209396 7.7906C0.488591 8.0698 0.939597 8.0698 1.21879 7.7906L4.5047 4.5047C4.78389 4.2255 4.78389 3.7745 4.5047 3.4953L1.21879 0.209396C0.939597 -0.0697985 0.48859 -0.0697985 0.209396 0.209396C-0.0626402 0.488591 -0.069799 0.946756 0.209396 1.22595Z"
                    fill="#1D242E"
                  />
                  <path
                    d="M4.2094 1.22595L6.98702 4.00358L4.2094 6.78121C3.9302 7.0604 3.9302 7.51141 4.2094 7.7906C4.48859 8.0698 4.9396 8.0698 5.21879 7.7906L8.5047 4.5047C8.78389 4.2255 8.78389 3.7745 8.5047 3.4953L5.21879 0.209396C4.9396 -0.0697985 4.48859 -0.0697985 4.2094 0.209396C3.93736 0.488591 3.9302 0.946756 4.2094 1.22595Z"
                    fill="#1D242E"
                  />
                </svg>
              </p>
            </div>
          </div>
          <div className=" bg-white border-2 border-[#03A9F5] flex-col items-center justify-center text-center">
            <div>
              {/* bg-white border-2 border-[#FED600] flex-col items-center justify-center text-center"> */}
              {/* <div className="mt-5"> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="45"
                height="45"
                viewBox="0 0 32 32"
                fill="none"
                className="mx-auto mt-6 mb-1"
              >
                <path
                  d="M29.3333 22.5789H25.6667V19.1053H29.3333M29.3333 29.5263H25.6667V26.0526H29.3333M18.3333 8.68421H14.6667V5.21053H18.3333M18.3333 15.6316H14.6667V12.1579H18.3333M18.3333 22.5789H14.6667V19.1053H18.3333M18.3333 29.5263H14.6667V26.0526H18.3333M7.33333 15.6316H3.66667V12.1579H7.33333M7.33333 22.5789H3.66667V19.1053H7.33333M7.33333 29.5263H3.66667V26.0526H7.33333M22 15.6316V5.21053L16.5 0L11 5.21053V8.68421H0V33H33V15.6316H22Z"
                  fill="#03A9F5"
                />
              </svg>
              {/* </div> */}
              <h2 className=" text-3xl font-extrabold">
                {statistic?.total_cities || 0}
              </h2>
              <p className="mb-3 font-semibold">Total Cities</p>
            </div>

            <div className="border-t-2 border-[#03A9F5] bg-[#03a9f54d] flex justify-center px-4 py-1">
              <span className="mr-3"><Link to="/places/listOfCities">View Detailed Report</Link></span>
              <p className="mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                >
                  <path
                    d="M0.209396 1.22595L2.98702 4.00358L0.209396 6.78121C-0.0697987 7.0604 -0.0697987 7.51141 0.209396 7.7906C0.488591 8.0698 0.939597 8.0698 1.21879 7.7906L4.5047 4.5047C4.78389 4.2255 4.78389 3.7745 4.5047 3.4953L1.21879 0.209396C0.939597 -0.0697985 0.48859 -0.0697985 0.209396 0.209396C-0.0626402 0.488591 -0.069799 0.946756 0.209396 1.22595Z"
                    fill="#1D242E"
                  />
                  <path
                    d="M4.2094 1.22595L6.98702 4.00358L4.2094 6.78121C3.9302 7.0604 3.9302 7.51141 4.2094 7.7906C4.48859 8.0698 4.9396 8.0698 5.21879 7.7906L8.5047 4.5047C8.78389 4.2255 8.78389 3.7745 8.5047 3.4953L5.21879 0.209396C4.9396 -0.0697985 4.48859 -0.0697985 4.2094 0.209396C3.93736 0.488591 3.9302 0.946756 4.2094 1.22595Z"
                    fill="#1D242E"
                  />
                </svg>
              </p>
            </div>
          </div>
          <div className="bg-white border-2 border-[#FED600] flex-col items-center justify-center text-center">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="48"
                viewBox="0 0 30 25"
                fill="none"
                className="mx-auto mt-6 mb-1"
              >
                <path
                  d="M21.3333 27L14.077 13.905L19.8095 4.5L32 27H21.3333ZM9.14286 10.5L18.2857 27H0L9.14286 10.5ZM5.33333 7.5C4.32298 7.5 3.35402 7.10491 2.63959 6.40165C1.92517 5.69839 1.52381 4.74456 1.52381 3.75C1.52381 2.75544 1.92517 1.80161 2.63959 1.09835C3.35402 0.395088 4.32298 0 5.33333 0C6.34368 0 7.31265 0.395088 8.02707 1.09835C8.7415 1.80161 9.14286 2.75544 9.14286 3.75C9.14286 4.74456 8.7415 5.69839 8.02707 6.40165C7.31265 7.10491 6.34368 7.5 5.33333 7.5Z"
                  fill="#FED600"
                />
              </svg>
              <h2 className=" text-2xl font-extrabold">
                {statistic?.total_places || 0}
              </h2>
              <p className="mb-3 font-semibold">Total Places</p>
            </div>

            <div className="border-t-2 border-[#FED600] bg-[#f5d6004d] flex justify-center px-4 py-1">
              <span className="mr-3"><Link to="/places/listOfPlaces">View Detailed Report</Link></span>
              <p className="mt-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                >
                  <path
                    d="M0.209396 1.22595L2.98702 4.00358L0.209396 6.78121C-0.0697987 7.0604 -0.0697987 7.51141 0.209396 7.7906C0.488591 8.0698 0.939597 8.0698 1.21879 7.7906L4.5047 4.5047C4.78389 4.2255 4.78389 3.7745 4.5047 3.4953L1.21879 0.209396C0.939597 -0.0697985 0.48859 -0.0697985 0.209396 0.209396C-0.0626402 0.488591 -0.069799 0.946756 0.209396 1.22595Z"
                    fill="#1D242E"
                  />
                  <path
                    d="M4.2094 1.22595L6.98702 4.00358L4.2094 6.78121C3.9302 7.0604 3.9302 7.51141 4.2094 7.7906C4.48859 8.0698 4.9396 8.0698 5.21879 7.7906L8.5047 4.5047C8.78389 4.2255 8.78389 3.7745 8.5047 3.4953L5.21879 0.209396C4.9396 -0.0697985 4.48859 -0.0697985 4.2094 0.209396C3.93736 0.488591 3.9302 0.946756 4.2094 1.22595Z"
                    fill="#1D242E"
                  />
                </svg>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* long containers  */}
      <div className="container  grid grid-cols-2 gap-10 py-10 px-8">
        <div className="border-2 border-[#1d242e4d]">
          <div className="flex justify-between px-8 py-2 text-sm">
            <h2 className=" text-lg font-bold">App Users</h2>
            <p className="flex gap-2 text-sm">
              {lastMonthString}
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                  className="mt-2"
                >
                  <path
                    d="M0.209396 1.22595L2.98702 4.00358L0.209396 6.78121C-0.0697987 7.0604 -0.0697987 7.51141 0.209396 7.7906C0.488591 8.0698 0.939597 8.0698 1.21879 7.7906L4.5047 4.5047C4.78389 4.2255 4.78389 3.7745 4.5047 3.4953L1.21879 0.209396C0.939597 -0.0697985 0.48859 -0.0697985 0.209396 0.209396C-0.0626402 0.488591 -0.069799 0.946756 0.209396 1.22595Z"
                    fill="#1D242E"
                  />
                  <path
                    d="M4.2094 1.22595L6.98702 4.00358L4.2094 6.78121C3.9302 7.0604 3.9302 7.51141 4.2094 7.7906C4.48859 8.0698 4.9396 8.0698 5.21879 7.7906L8.5047 4.5047C8.78389 4.2255 8.78389 3.7745 8.5047 3.4953L5.21879 0.209396C4.9396 -0.0697985 4.48859 -0.0697985 4.2094 0.209396C3.93736 0.488591 3.9302 0.946756 4.2094 1.22595Z"
                    fill="#1D242E"
                  />
                </svg>
              </span>
            </p>
          </div>
          <div className="flex justify-between px-4 border-t-2 border-[#1d242e4d] px-8  py-6">
            <div className="">
              <h2 className="text-3xl font-bold   pb-2">
                {statistic?.last_month_data?.users_added_last_month || "0"}
              </h2>
              <p className=" text-md font-semibold">Total Users</p>
            </div>
            <div className="">
              <h2 className="text-3xl font-bold pb-2">
                {statistic?.last_month_data?.subscribe_users || "0"}
              </h2>
              <p className=" text-md font-semibold">Subscribed Users</p>
            </div>
          </div>
        </div>
        <div className=" border-2 border-[#1d242e4d] ">
          <div className="flex justify-between px-8 py-2 text-sm">
            <h2 className=" text-lg font-bold">Guide Booking</h2>
            <p className="flex gap-2 text-sm">
              {lastMonthString}
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="5"
                  viewBox="0 0 8 5"
                  fill="none"
                  className="mt-2"
                >
                  <path
                    d="M6.77405 0.209396L3.99642 2.98702L1.21879 0.209396C0.939597 -0.0697987 0.488591 -0.0697987 0.209396 0.209396C-0.0697987 0.488591 -0.0697987 0.939597 0.209396 1.21879L3.4953 4.5047C3.7745 4.78389 4.2255 4.78389 4.5047 4.5047L7.7906 1.21879C8.0698 0.939597 8.0698 0.488591 7.7906 0.209396C7.51141 -0.0626398 7.05324 -0.0697987 6.77405 0.209396Z"
                    fill="#1D242E"
                  />
                </svg>
              </span>
            </p>
          </div>
          <div className="flex justify-between px-4 border-t-2 border-[#1d242e4d] px-8 py-6">
            <div className="">
              <h2 className="text-3xl font-bold pb-2">
                {statistic?.last_month_data?.total_revenue_last_month || "0"}
              </h2>
              <p className=" text-md font-semibold">Revenue Generated</p>
            </div>
            <div className="">
              <h2 className="text-3xl font-bold pb-2">
                {statistic?.last_month_data?.guide_bookings_last_month || "0"}
              </h2>
              <p className=" text-md font-semibold">Total Guides Booked</p>
            </div>
          </div>
        </div>
        <div className="border-2 border-[#1d242e4d] ">
          <div className="flex justify-between px-8 py-2 text-sm">
            <h2 className=" text-lg font-bold">App Places</h2>
            <p className="flex gap-2 text-sm">
              {lastMonthString}
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                  className="mt-2"
                >
                  <path
                    d="M0.209396 1.22595L2.98702 4.00358L0.209396 6.78121C-0.0697987 7.0604 -0.0697987 7.51141 0.209396 7.7906C0.488591 8.0698 0.939597 8.0698 1.21879 7.7906L4.5047 4.5047C4.78389 4.2255 4.78389 3.7745 4.5047 3.4953L1.21879 0.209396C0.939597 -0.0697985 0.48859 -0.0697985 0.209396 0.209396C-0.0626402 0.488591 -0.069799 0.946756 0.209396 1.22595Z"
                    fill="#1D242E"
                  />
                  <path
                    d="M4.2094 1.22595L6.98702 4.00358L4.2094 6.78121C3.9302 7.0604 3.9302 7.51141 4.2094 7.7906C4.48859 8.0698 4.9396 8.0698 5.21879 7.7906L8.5047 4.5047C8.78389 4.2255 8.78389 3.7745 8.5047 3.4953L5.21879 0.209396C4.9396 -0.0697985 4.48859 -0.0697985 4.2094 0.209396C3.93736 0.488591 3.9302 0.946756 4.2094 1.22595Z"
                    fill="#1D242E"
                  />
                </svg>
              </span>
            </p>
          </div>
          <div className="flex justify-between px-4 border-t-2 border-[#1d242e4d] px-8 py-6">
            <div className="">
              <h2 className="text-3xl font-bold pb-2">
                {statistic?.last_month_data?.places_added_last_month || "0"}
              </h2>
              <p className=" text-md font-semibold">Total no of Places</p>
            </div>
            <div className="">
              <h2 className="text-3xl font-bold pb-2">
                {statistic?.last_month_data?.cities_added_last_month || "0"}
              </h2>
              <p className=" text-md font-semibold">Total no of Cities</p>
            </div>
          </div>
        </div>
        <div className="border-2 border-[#1d242e4d] ">
          <div className="flex justify-between px-8 py-2 text-sm">
            <h2 className=" text-lg font-bold">Total Activity Centers</h2>
            <p className="flex gap-2 text-sm">
              {lastMonthString}
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                  className="mt-2"
                >
                  <path
                    d="M0.209396 1.22595L2.98702 4.00358L0.209396 6.78121C-0.0697987 7.0604 -0.0697987 7.51141 0.209396 7.7906C0.488591 8.0698 0.939597 8.0698 1.21879 7.7906L4.5047 4.5047C4.78389 4.2255 4.78389 3.7745 4.5047 3.4953L1.21879 0.209396C0.939597 -0.0697985 0.48859 -0.0697985 0.209396 0.209396C-0.0626402 0.488591 -0.069799 0.946756 0.209396 1.22595Z"
                    fill="#1D242E"
                  />
                  <path
                    d="M4.2094 1.22595L6.98702 4.00358L4.2094 6.78121C3.9302 7.0604 3.9302 7.51141 4.2094 7.7906C4.48859 8.0698 4.9396 8.0698 5.21879 7.7906L8.5047 4.5047C8.78389 4.2255 8.78389 3.7745 8.5047 3.4953L5.21879 0.209396C4.9396 -0.0697985 4.48859 -0.0697985 4.2094 0.209396C3.93736 0.488591 3.9302 0.946756 4.2094 1.22595Z"
                    fill="#1D242E"
                  />
                </svg>
              </span>
            </p>
          </div>
          <div className="flex justify-between px-4 border-t-2 border-[#1d242e4d] px-8 py-6">
            <div className="">
              <h2 className="text-3xl font-bold pb-2">
                {statistic?.total_center || "0"}
              </h2>
              <p className=" text-md font-semibold">Total no of Centers</p>
            </div>
            <div className="">
              {/* <h2 className="text-3xl font-bold pb-2 pb-2">Manali</h2>
              <p className=" text-md font-semibold">Most Booked Place</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
