import React, { useEffect, useState } from "react";
import Select from "react-select";
import { centerType } from "../../../constants/urls";
import { useCenterTypeContext } from "../../../controllers/CenterType_controller";
export default function CenterTypeDropdown({
  setType,
  onChange,
  selectedActivities,
  onSelect,
}) {
  const cityData = useCenterTypeContext();
  const { setSearchQuery } = useCenterTypeContext();
  const cities = cityData?.data || [];
  const [selectedValue, setSelectedValue] = useState(null);
  const [data, setData] = useState([]);
  const [city, setCity] = useState("");

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#3182ce" : "white",
      color: state.isSelected ? "white" : "black",
    }),
  };
  const CustomOption = ({ innerProps, label, image }) => (
    <div {...innerProps}>
      <div className="flex py-2 px-4">
        <div className="flex items-center justify-center h-full"></div>
        <span className="ml-2">{label}</span>
      </div>
    </div>
  );

  useEffect(() => {
    if (selectedValue) {
      const apiUrl = `${centerType}`;

      async function fetchInfo() {
        try {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append(
            "Authorization",
            `Token ${localStorage.getItem("token")}`
          );
          var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
          };

          const response = await fetch(apiUrl, requestOptions);
          const result = await response.json();
          // console.log(result);
          if (response.ok) {
            const resultData = result?.results;

            setData(resultData);
            console.log("data", data);
            console.log(selectedValue);
          } else {
            console.log(`Error: ${result.error}`);
          }
        } catch (error) {
          console.error(`Error: ${error}`);
        }
      }

      fetchInfo();
    }
  }, [selectedValue]);
  const cityOptions = cities?.map((city) => ({
    value: city.center_type_id,
    label: city.name,
  }));

  const handleCitySelection = (selectedOption) => {
    setSelectedValue(selectedOption.value);
    setType(selectedOption.value);
    setCity(selectedOption.value);
  };

  return (
    <>
      {/* {console.log("type", cityData.data)} */}
      <div className="mb-3">
        <div class="flex pb-2">
          <label class="block mb-2 text-sm font-medium text-gray-900 ">
            Center Type
          </label>
          <i
            class="fa-solid fa-asterisk fa-xs"
            style={{
              color: "#ff0a2f",
              paddingLeft: "3px",
              fontSize: "10px",
              paddingTop: "8px",
            }}
          ></i>
        </div>
        <Select
          id="dropdown"
          name="dropdown"
          options={cityOptions}
          value={cityOptions.find((option) => option.value === selectedValue)}
          //   onInputChange={(inputValue) => {
          //     setSearchQuery(inputValue);
          //   }}
          onChange={handleCitySelection}
          isSearchable
          styles={customStyles}
          components={{ Option: CustomOption }}
        />
      </div>
    </>
  );
}
