import React from "react";
import { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Search from "../components/Search";
import AddButton from "../components/AddButton";
import PaginationNo from "../components/PaginationNo";
import { placeCRUDApi } from "../../../constants/urls";
export default function PlaceComponentList() {
  const { city_id } = useParams();
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [placesData, setPlacesData] = useState([]);
  const updateUrl = () => {
    const newApiUrl = `https://orca-app-pgxkr.ondigitalocean.app/dashboard/city/${city_id}/places/?name=${searchText}&page=${currentPage}&page_size=8`;
    return newApiUrl;
  };
  const data = placesData?.results || [];
  const handleNextPage = () => {
    setCurrentPage((currentPage) => {
      const nextPage = currentPage + 1;
      return nextPage <= Math.ceil(placesData?.count / 8)
        ? nextPage
        : currentPage;
    });
  };

  const handlePrevPage = () => {
    setCurrentPage((currentPage) => {
      const prevPage = currentPage - 1;
      return prevPage >= 1 ? prevPage : 1;
    });
  };
  useEffect(() => {
    const apiUrl = updateUrl();
    async function fetchInfo() {
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          `Token ${localStorage.getItem("token")}`
        );
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(apiUrl, requestOptions);
        const result = await response.json();
        if (response.ok) {
          setPlacesData(result);
          console.log(placesData);
        } else {
          console.log(`Error: ${result.error}`);
        }
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    }

    fetchInfo();
  }, [searchText, currentPage]);
  const togglePlaceStatus = async (placeId, currentStatus) => {
    const newStatus = !currentStatus;

    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Token ${localStorage.getItem("token")}`);

      var requestOptions = {
        method: "PATCH",
        headers: myHeaders,
        body: JSON.stringify({ is_active: newStatus }),
        redirect: "follow",
      };

      const apiUrl = `${placeCRUDApi}${placeId}/`;
      const response = await fetch(apiUrl, requestOptions);
      const result = await response.json();

      if (response.ok) {
        setPlacesData((prevData) => {
          const updatedData = prevData.results.map((place) =>
            place.place_id === placeId ? { ...place, is_active: newStatus } : place
          );
          return { ...prevData, results: updatedData };
        });
        console.log(`Place ${newStatus ? 'activated' : 'deactivated'} successfully.`);
      } else {
        console.error(`Error: ${result.error}`);
      }
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  };

  return (
    <div className="ml-60 bg-[#EDF1F5] px-16 py-4" style={{ backgroundColor: "#EDF1F5", height: "100vh" }}>
      {console.log(placesData)}
      {placesData?.count === 0 ? (
        <div className="  text-center text-3xl text-bold py-20 flex justify-center items-center">
          No places to show
        </div>
      ) : (
        <>
          <div className="flex justify-between container">
            <div className="px-2">
              <h1 className="font-bold pt-2 text-xl font-poppins text-gray-500">
                {placesData?.results?.[0]?.city_name} {" > "}
                {/* <span className="text-gray-500">List of Cities (298)</span> */}
                <span className="text-gray-800">
                  List of Places({placesData?.count})
                </span>
              </h1>
              {/* <span className=" font-semibold text-sm">
            List of Total Places in {name}, Madhya Pradesh, India - 452001
          </span> */}
            </div>

            <Link to="/places/addNewPlaces">
              <AddButton text="Add New Places" />
            </Link>
          </div>
          {/* Search and Pagination  */}
          <div className=" container pb-2">
            <div className="flex justify-between">
              <Search
                text="Search for places..."
                setSearchQuery={setSearchText}
              />
              <PaginationNo
                post_per_page={8}
                totalPost={placesData?.count}
                currentPage={currentPage}
                getPrevPage={handlePrevPage}
                getNextPage={handleNextPage}
              />
              {/* <div className=" w-1/7"> */}
              {/* <select className="form-select block w-full border-2 border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded px-8 py-1">
              {Activities.map((list) => (
                <option>{list}</option>
              ))}
            </select> */}
              {/* </div> */}
            </div>
          </div>
          {/* List of Cities  */}

          <div className="container py-4 ">
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full border border-[#1d242e4d] bg-white shadow-lg">
                <thead className="table-heading">
                  <tr>
                    <th scope="col" className="p-6">
                      <div className="flex items-center justify-center">
                        Place ID
                        <a href="/">
                          <svg
                            class="w-3 h-3 ml-1.5"
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="13"
                            viewBox="0 0 8 13"
                            fill="none"
                          >
                            <path
                              d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
                              fill="#1D242E"
                            />
                            <path
                              d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
                              fill="#1D242E"
                            />
                          </svg>
                        </a>
                      </div>
                    </th>
                    <th scope="col" className="p-6">
                      <div className="flex items-center justify-center">
                        Name Of Place
                        <a href="/">
                          <svg
                            class="w-3 h-3 ml-1.5"
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="13"
                            viewBox="0 0 8 13"
                            fill="none"
                          >
                            <path
                              d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
                              fill="#1D242E"
                            />
                            <path
                              d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
                              fill="#1D242E"
                            />
                          </svg>
                        </a>
                      </div>
                    </th>
                    <th scope="col" className="p-6">
                      <div className="flex items-center justify-center">
                        Total Activity Center
                        <a href="/">
                          <svg
                            class="w-3 h-3 ml-1.5"
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="13"
                            viewBox="0 0 8 13"
                            fill="none"
                          >
                            <path
                              d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
                              fill="#1D242E"
                            />
                            <path
                              d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
                              fill="#1D242E"
                            />
                          </svg>
                        </a>
                      </div>
                    </th>
                    <th scope="col" className="p-6">
                      <div className="flex items-center justify-center">
                        Total Guide
                        <a href="/">
                          <svg
                            class="w-3 h-3 ml-1.5"
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="13"
                            viewBox="0 0 8 13"
                            fill="none"
                          >
                            <path
                              d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
                              fill="#1D242E"
                            />
                            <path
                              d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
                              fill="#1D242E"
                            />
                          </svg>
                        </a>
                      </div>
                    </th>
                    <th scope="col" className="p-6">
                      <div className="flex items-center justify-center">
                        Reviews
                        <a href="/">
                          <svg
                            class="w-3 h-3 ml-1.5"
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="13"
                            viewBox="0 0 8 13"
                            fill="none"
                          >
                            <path
                              d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
                              fill="#1D242E"
                            />
                            <path
                              d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
                              fill="#1D242E"
                            />
                          </svg>
                        </a>
                      </div>
                    </th>
                    <th scope="col" className="p-6">
                      <div className="flex items-center justify-center">
                        Action
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row) => (
                    <tr key={row.placeId} className="table-text border-t border-gray-200">
                      <Link to={`/places/EditPlaceDetails/${row.place_id}/`} style={{ display: "contents" }}>
                        <td className="text-center px-6 py-4">{row.place_id}</td>
                        <td className="text-center px-6 py-4">{row.name}</td>
                      </Link>
                      <td className="text-center px-6 py-4">{row.total_activity_centers}</td>
                      <td className="text-center px-6 py-4">{row.total_guides}</td>
                      <td className="text-center px-6 py-4">{row.total_reviews}</td>
                      {/* <td className="text-center px-6 py-4">{row.rating}</td> */}
                      <td className="text-center px-6 py-4">
                        <label className="relative inline-flex items-center cursor-pointer">
                          <input
                            type="checkbox"
                            checked={row.is_active}
                            onChange={() => togglePlaceStatus(row.place_id, row.is_active)}
                            className="sr-only peer"
                          />
                          <div
                            className={`w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 
        ${row.is_active ? 'peer-checked:bg-green-500' : 'peer-checked:bg-red-500'}`}
                          ></div>
                          <span
                            className={`absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform 
        ${row.is_active ? 'translate-x-full' : ''}`}
                          ></span>
                        </label>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
