import React from "react";
import { Link } from "react-router-dom";

function NoPage() {
  return (
    <>
      <div class="flex items-center justify-center pl-64 h-screen">
        <div class="flex-col space-y-4 text-left px-6">
          <div class="text-7xl font-bold text-violet-700">404</div>
          <div class="text-stone-500 font-medium">
            Oops, you still haven't found what you lookng for?
          </div>
          <div class="flex space-x-4 items-center justify-start">
            <Link to="/">
              <div class="bg-violet-700 px-4 py-1 text-white font-medium border-2 border-gray-400  hover:scale-105 cursor-pointer">
                Back to Home Page
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default NoPage;
