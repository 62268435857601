import React from "react";
import AddButton from "../components/AddButton";
import BasicTable from "../sepcomponents/GuideComponent";
import { Link } from "react-router-dom";
import { GuideProvider } from "../../../controllers/Guide_controller";

export default function Guide() {
  const column = [
    {
      Header: "Guide ID",
      accessor: "guide_id",
    },
    {
      Header: "Photo",
      accessor: "image",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Gender",
      accessor: "gender",
    },
    {
      Header: "City",
      accessor: "city",
    },
    {
      Header: "Trips",
      accessor: "total_trips",
    },
    {
      Header: "Rating",
      accessor: "rating",
    },
  ];

  return (
    <div className="flexbox-container pl-64 handleBack " style={{ backgroundColor: "#EDF1F5",height:"100vh" }}>
      <GuideProvider>
        <div className="container pt-4 px-8 ">
          <div className="flex flex-row mt-2 justify-between">
            <div className="flex flex-col">
              <h1 className="handleHeader">Guide</h1>
              <h4 className="handleDescription">List of all Guides</h4>
            </div>
            <div>
              <Link to="/guide/addNewGuide">
                <AddButton text="Add a Guide" />
              </Link>
            </div>
          </div>

          <div className=" mt-2">
            <BasicTable column={column} />
          </div>
        </div>
      </GuideProvider>
    </div>
  );
}
