import React, { useState } from "react";

export default function CopyId({ id }) {
  const [isCopied, setIsCopied] = useState(false);
  const [text, setText] = useState(id);
  const copyToClipboard = () => {
    // Create a text area element to hold the centerId
    const textArea = document.createElement("textarea");
    textArea.value = id;

    document.body.appendChild(textArea);

    textArea.select();
    document.execCommand("copy");
    setIsCopied(true);
    document.body.removeChild(textArea);
    setText("copied");
    // Show an alert saying "Copied"
    <div className="text-blue-900 text-sm">{"Copied"}</div>;

    // Reset isCopied to false after 3 seconds
    setTimeout(() => {
      setIsCopied(false);
      setText(id);
    }, 2000);
  };

  return (
    <div className=" absolute inline-grid  ">
      <div className="cursor-pointer inline-block">
        <span
          className="text-blue-500 hover:text-blue-900"
          onMouseEnter={() => setIsCopied(true)}
          onMouseLeave={() => setIsCopied(false)}
          onClick={copyToClipboard}
        >
          <i className="fa-regular fa-copy"></i>
        </span>
        {isCopied === true ? (
          <div
            className="text-blue-900 text-center text-sm"
            style={{
              backgroundColor: "#fff",
              zIndex: "4",
              display: "block",
              color: "#000",
            }}
          >
            {text}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
