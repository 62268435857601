// Import useEffect and useState from React
import React, { useEffect, useState } from "react";
import DaysDropdown from "./DaysDropdown";

export default function CityAndPlaceDropdown({ setStateId }) {
  // State to store the list of states from the API
  const [states, setStates] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");

  useEffect(() => {
    // Fetch state data from the API
    async function fetchStates() {
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          `Token ${localStorage.getItem("token")}`
        );
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };
        const response = await fetch(
          "https://orca-app-pgxkr.ondigitalocean.app/data/state-data/",
          requestOptions
        );
        const result = await response.json();

        if (response.ok) {
          setStates(result);
        } else {
          console.log(`Error fetching states: ${result.error}`);
        }
      } catch (error) {
        console.error(`Error fetching states: ${error}`);
      }
    }

    fetchStates();
  }, []);

  const handleCityChange = (event) => {
    const selectedStateId = event.target.value;
    setSelectedCity(selectedStateId);

    setStateId(selectedStateId);
  };

  return (
    <div className="flex">
      <div style={{ paddingLeft: "1rem", width: "160px" }}>
        <DaysDropdown
          options={[
            { value: "", label: "State" },
            ...states.map((state) => ({
              value: state.state_id,
              label: state.name,
            })),
          ]}
          selectedValue={selectedCity}
          onChange={handleCityChange}
        />
      </div>
      {/* ... Other code ... */}
    </div>
  );
}
