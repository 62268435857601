import React from "react";
import loginBG from "../assets/loginBG.png";
export default function ComingSoon() {
  return (
    <div style={{ backgroundColor: "#EDF1F5",height:"100vh" }}>
      {/* <div className="relative">
        <img
          src={loginBG}
          alt="Hero Background"
          className="w-full h-screen object-cover"
        />
      </div> */}
      <div className="ml-60 text-center text-5xl text-bold py-60 flex justify-center items-center">
        Coming soon...
      </div>
    </div>
  );
}
