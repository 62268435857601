import React from "react";

export default function BlueButtonCross({ text, index }) {
  return (
    <div>
      <button
        className="actcenterbtn px-2 py-2 "
        key={index}
        style={{
          backgroundColor: "#A7DBF5",
          color: "black",
          border: "none",
          cursor: "pointer",
          fontSize: "14px",
          borderRadius: "7px",
          marginTop: "5px",
        }}
      >
        {text}
        <button className="px-2" style={{ position: "relative" }}>
          <i
            className="fa-solid fa-circle"
            style={{ color: "lightgray", fontSize: "16px" }}
          ></i>
          <i
            className="fa-solid fa-times"
            style={{
              color: " black",
              fontSize: "12px",
              position: "absolute",
              top: 5,
              left: 12,
            }}
          ></i>
        </button>
      </button>
    </div>
  );
}
