import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Search from "../components/Search";
import loadin_gif from "../assets/loadin_gif.gif";
import { useCityContext } from "../../../controllers/city_controllers";
import PaginationNo from "../components/PaginationNo";
import Loading from "../components/Loading";
export default function CityComponent() {
  const { state, setSearchQuery, getPrevPage, getNextPage, setPage } = useCityContext();
  const itemsToDisplay = state?.cities?.cities || [];

  const loadingState = useCityContext();
  const totalPost = state?.cities?.totalPost || 0;
  const currentPage = state.page;

  const location = useLocation();
  const navigate = useNavigate(); // Use useNavigate instead of useHistory
  const urlSearchParams = new URLSearchParams(location.search);
  // const pageFromUrl = parseInt(urlSearchParams.get('page')) || 1;

  // useEffect(() => {
  //   console.log("Page from URL:", pageFromUrl);
  //   setPage(pageFromUrl);
  // }, [pageFromUrl, setPage]);


  // let height = window.screen.height;
  return (
    <div className="pb-2">
      <div className="flex justify-between pb-4">
        {/* {console.log("height", height)} */}

        <Search text={"Search for City..."} setSearchQuery={setSearchQuery} />
        <PaginationNo
          post_per_page={state.post_per_page}
          getPrevPage={() => {
            getPrevPage();
            navigate(`/places/?page=${currentPage - 1}`); // Use navigate instead of history.push
          }}
          totalPost={totalPost}
          getNextPage={() => {
            getNextPage();
            navigate(`/places/?page=${currentPage + 1}`); // Use navigate instead of history.push
          }}
          currentPage={currentPage}
        />
      </div>
      {loadingState.state.isloading ? (
        <div className="center-screen">
          <div className="flex items-center justify-center ">
            <img src={loadin_gif} alt="loading..." />
          </div>
        </div>
      ) : (
        <div>
          {/* {console.log("dfsdf", state.page, state.cities.totalPost)} */}
          <ul className="grid gap-x-6 mt-4 gap-y-8 sm:grid-cols-4 sm:gap-y-4 xl:col-span-2 ml-1 ">
            {/* {("cities", console.log(state.cities.cities))} */}
            {itemsToDisplay.map((person) => (
              <li key={person.city_id}>
                <div className="flex items-center gap-x-2 border border-[#BBBDC0] rounded-md   bg-white shadow-xl">
                  <img
                    className="h-12 w-12 rounded-full m-3 ring-1 ring-[#BBBDC0] ring-offset-1"
                    src={
                      person.image ||
                      "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ="
                    }
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src =
                        "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ=";
                    }}
                    alt="img"
                  />
                  <div>
                    <Link to={`/places/DetailsCity/${person.city_id}/`}>
                      <h3 className="text-base font-semibold leading-6 tracking-tight text-gray-900">
                        {person.name}
                      </h3>
                    </Link>
                    {person.total_places > 0 ? (
                      <Link
                        to={`/places/listOfCities/${person.city_id}/listOfPlaces`}
                      >
                        <p className="text-sm leading-6 text-gray-400">
                          {person.total_places} Places
                        </p>
                      </Link>
                    ) : (
                      <p className="text-sm leading-6 text-gray-400">
                        {person.total_places} Places
                      </p>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
{
  /* Pagination Controls */
}
{
  /* <div className="flex justify-center mt-4">
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-300"
        >
          Previous Page
        </button>
        <span className="text-gray-600 mx-4">
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-300"
        >
          Next Page
        </button>
      </div> */
}

// <div className="py-3">
//   <div className="  flex justify-between pb-2">
//     <div className="">
//       <form action="#" method="GET" className="hidden lg:block lg:pl-2">
//         <label for="topbar-search" className="sr-only">
//           Search
//         </label>
//         <div className="relative mt-1 lg:w-96">
//           <div className="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none">
//             <svg
//               className="w-5 h-5 text-gray-500 dark:text-gray-400"
//               fill="currentColor"
//               viewBox="0 0 20 20"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 fillRule="evenodd"
//                 d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
//                 clip-rule="evenodd"
//               ></path>
//             </svg>
//           </div>
//           <input
//             type="text"
//             name="email"
//             id="topbar-search"
//             className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5  focus:ring-primary-500 focus:border-primary-500"
//             placeholder="Search for City..."
//           />
//         </div>
//       </form>
//     </div>
//     <div className="">
//       {/* <PaginationNo
//         totalPosts={currentPosts.length}
//         postsPerPAge={postsPerPage}
//         setCurrentPage={setcurrentPage}
//         currentPage={currentPage}
//       /> */}
//       <div className="flex justify-between items-center">
//         <button
//           onClick={() => setCurrentPage(currentPage - 1)}
//           disabled={currentPage === 1}
//           className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-300"
//         >
//           Previous Page
//         </button>
//         <span className="text-gray-600">
//           Page {currentPage} of{" "}
//           {Math.ceil(totalItems.length / postsPerPage)}
//         </span>
//         <button
//           onClick={() => setCurrentPage(currentPage + 1)}
//           disabled={indexOfLastItem >= totalItems.length}
//           className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 disabled:bg-gray-300"
//         >
//           Next Page
//         </button>
//       </div>
//     </div>
//   </div>
//   <div>
//     <ul className="grid gap-x-6 gap-y-8 sm:grid-cols-4 sm:gap-y-4 xl:col-span-2 mx-2">
//       {/* {console.log(totalPlaces.recentCities.cities)} */}
//       {currentItems.map((person) => (
//         <li key={person.city_id}>
//           <Link to="/places/listOfCities">
//             <div className="flex items-center gap-x-2 border border-[#BBBDC0] rounded-md shadow-lg bg-white">
//               <img
//                 className="h-12 w-12 rounded-full m-3 ring-1 ring-[#BBBDC0] ring-offset-1"
//                 src={person.image}
//                 alt=""
//               />
//               <div>
//                 <h3 className="text-base font-semibold leading-6 tracking-tight text-gray-900">
//                   {person.name}
//                 </h3>
//                 <p className="text-sm  leading-6 text-gray-400">
//                   {person.total_places} Places
//                 </p>
//               </div>
//             </div>
//           </Link>
//         </li>
//       ))}
//     </ul>
//   </div>
// </div>
