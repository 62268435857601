import { React, useState } from "react";
import Login from "./config/frontend/pages/Login";
import Dashboard from "./config/frontend/components/Dashboard";
import login from "./controllers/login_controllers";
function App() {
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogin = async (email, password, isRememberMeChecked) => {
    try {
      const loginResult = await login(email, password, isRememberMeChecked);
      if (loginResult) {
        if (isRememberMeChecked) {
          localStorage.setItem("token", loginResult.token);
          // localStorage.setItem("userAuth", userToken);
          // localStorage.setItem("autoLogin", isRememberMeChecked);
        }
        // localStorage.setItem("userAuth", userToken);
        setIsLoggedin(true);
        setErrorMessage("");
      } else {
        setIsLoggedin(false);
        setErrorMessage(
          "User is not valid. Please check your email and password."
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleLogout = () => {
    // localStorage.removeItem("userAuth");
    // localStorage.removeItem("autoLogin");
    localStorage.clear();
    // navigate("/");

    window.location.reload();
    setIsLoggedin(false);
  };

  return (
    <>
      {localStorage.getItem("token") === null ? (
        <Login login={handleLogin} errorMessage={errorMessage} />
      ) : (
        <Dashboard logout={handleLogout} />
      )}
    </>
  );
}

export default App;
