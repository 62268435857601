import React, { useEffect, useRef } from "react";
import Select from "react-select";
import { useHobbiesContext } from "../sepcomponents/HobbyDropdownController";

export default function HobbiesDropdown({
  editing,
  ids,
  selectedHobbies,
  onSelect,
}) {
  const data = useHobbiesContext();
  const hobby = data?.data?.results || [];
  const containerRef = useRef(null);

  const CustomOption = ({ innerProps, label, image }) => (
    <div {...innerProps}>
      <div className="flex py-2 px-4">
        <div className="flex items-center justify-center h-full">
          <img
            className="h-6 w-6 rounded-full"
            src={
              image ||
              "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ="
            }
            alt={label}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ=";
            }}
          />
        </div>
        <span className="ml-2">{label}</span>
      </div>
    </div>
  );

  const cityOptions = hobby?.map((city) => ({
    value: city.interest_id,
    label: city.name,
    image:
      city.image ||
      "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ=",
  }));

  useEffect(() => {
    if (editing && hobby) {
      const id = hobby?.map((activity) => activity.id);
      if (id) {
        onSelect([...selectedHobbies, ...id]);
      }
    }
  }, [editing, ids]);

  return (
    <div className="mb-4 ml-6 mr-2" ref={containerRef}>
      <div className="flex">
        <label
          htmlFor="dropdown"
          className="block mb-2 text-md font-medium text-gray-900"
        >
          Hobbies
        </label>
      </div>
      <Select
        id="dropdown"
        name="dropdown"
        placeholder="Hobbies"
        options={cityOptions}
        value={cityOptions.filter((option) =>
          selectedHobbies.includes(option.value)
        )}
        onChange={(selectedOptions) => {
          const selectedValues = selectedOptions.map((option) => option.value);
          onSelect(selectedValues); // Pass the selected values to the parent component
        }}
        isMulti
        styles={{
          control: (provided) => ({
            ...provided,
            width: "100%",
            zIndex: "50",
          }),
          multiValue: (provided) => ({
            ...provided,
            background: "#A7DBF5",
            borderRadius: "5px",
            padding: "2px",
            fontSize: "16px",
            fontWeight: "600",
          }),
          multiValueRemove: (provided) => ({
            ...provided,
            color: "black",
            backgroundColor: "#E5E5E5",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            marginTop: "3px",
            margin: "4px",
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            color: "black",
          }),
        }}
        components={{ Option: CustomOption }}
      />
    </div>
  );
}
