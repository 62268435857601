import React from "react";

import CenterComponent from "../sepcomponents/CenterComponent";
import { CenterProvider } from "../../../controllers/Center_controller";
export default function ActivityCenters() {
  const cities = [
    { value: 4, label: "City" },
    { value: 1, label: "New York" },
    { value: 2, label: "Los Angeles" },
    { value: 3, label: "Chicago" },
  ];
  const place = [
    { value: 4, label: "Place" },
    { value: 1, label: "Temple" },
    { value: 2, label: "Lake" },
    { value: 3, label: "Mountain" },
  ];

  return (
    <div className="handleBack" style={{ backgroundColor: "#EDF1F5",height:"100vh" }}>
      <CenterProvider>
        <CenterComponent cities={cities} place={place} />
      </CenterProvider>
    </div>
  );
}
