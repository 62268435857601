import React from "react";

export default function TwoInfoBox({ heading, ftop, fbottom, stop, sbottom }) {
  return (
    <div className="py-4 pr-6">
      <div className="border-2 border-[#1d242e4d] rounded bg-white">
        <h2 className="py-3 px-6 font-bold">{heading}</h2>
        <div className="border-t-2 border-[#1d242e4d]">
          <div className="flex flex-row ">
            <div className="flex flex-col py-5 px-6 w-1/2">
              <h1 className="handleBoxTop h-1/2">{ftop}</h1>
              <p className="handleBoxPara h-1/2">{fbottom}</p>
            </div>
            <div className="flex flex-col py-5 pl-20 w-1/2">
              <h1 className="handleBoxTop  ">{stop}</h1>
              <p className="handleBoxPara ">{sbottom}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
