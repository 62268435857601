import React from "react";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Search from "../components/Search";
import MultiSelectDropdow from "../components/MultiSelectDropdow";
import CopyId from "../components/CopyId";
import Loading from "../components/Loading";
import MultiArrayList from "../components/MultiArrayList";
import MandatoryFeilds from "../components/MandatoryFeilds";
import Description from "../components/Description";
import TagsDropdown from "../components/TagsDropdown";
import ActivityDropdown from "../components/ActivityDropdown";
import { TagsProvider } from "../../../controllers/Tag_controller";
import { ActivityProvider } from "../../../controllers/Activity_controller";
export default function DetailsCity() {
  const { city_id } = useParams();
  const [placesData, setPlacesData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [editingImg, setEditingImg] = useState(false);
  const [lat, setLat] = useState(0);
  const [lon, setLon] = useState(0);
  const [tags, setTags] = useState([]);
  const [activities, setActivities] = useState([]);
  const [description, setCityDescription] = useState("");
  const handleEditClick = () => {
    setEditing(true);
  };
  const handleEditClickImg = () => {
    setEditingImg(true);
  };
  const apiUrl = `https://orca-app-pgxkr.ondigitalocean.app/dashboard/city-detail/${city_id}`;
  useEffect(() => {
    async function fetchInfo() {
      try {
        var myHeaders = new Headers();
        // myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          `Token ${localStorage.getItem("token")}`
        );
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(apiUrl, requestOptions);
        const result = await response.json();
        // console.log(result);
        if (response.ok) {
          setPlacesData(result);
          console.log(placesData);
          setLat(result?.lat);
          setLon(result?.lon);
          setIsloading(false);
          setCityDescription(result.description);
        } else {
          console.log(`Error: ${result.error}`);
        }
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    }

    fetchInfo();
  }, [apiUrl]);
  const [image, setImage] = useState("");
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
    const imgElement = document.getElementById("selectedImage");
    imgElement.src = URL.createObjectURL(selectedImage);
  };
  const handleSaveAndClose = () => {
    saveImage();
  };
  const handleSaveAndClose1 = () => {
    saveDetails();
  };
  const tagsArray = tags.map((tag) => tag.value);

  const actArray = activities.map((tag) => tag.value);
  const saveImage = (event) => {
    const bodyData = {};

    if (image !== null && image instanceof File) {
      bodyData.image = image;
    }

    const formData = new FormData();

    if (image !== null && image instanceof File) {
      formData.append("image", image);
    } else {
      bodyData.image = image;
      console.log("bodydata");
    }

    fetch(`https://orca-app-pgxkr.ondigitalocean.app/data/city/${city_id}/`, {
      method: "PATCH",
      body: formData,
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Data posted:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setEditingImg(false);
  };
  const saveDetails = (event) => {
    const formData = {
      description: description,
      lat: lat,
      lon: lon,
      activities: actArray,
      tags: tagsArray,
    };

    console.log(formData);
    const requestOptions = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",

        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify(formData),
    };
    fetch(
      `https://orca-app-pgxkr.ondigitalocean.app/data/city/${city_id}/`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Data posted:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setEditing(false);
  };
  const handleLatChange = (e) => {
    setLat(e.target.value);
  };
  const handleLonChange = (e) => {
    setLon(e.target.value);
  };
  const handleTagSelection = (selectedTags) => {
    setTags(selectedTags);
  };
  const handleActivitySelection = (selectedActivities) => {
    setActivities(selectedActivities);
  };

  return (
    <div>
      <TagsProvider>
        <ActivityProvider>
          <div className="bg-[#EDF1F5] ml-64">
            {/* {console.log("citydetails", placesData)} */}
            {isloading === true ? (
              <div>
                <Loading />
              </div>
            ) : (
              <>
                <div className="container py-4 px-8">
                  <div className="flex justify-between">
                    <div className="px-2">
                      <h2 className="font-bold text-2xl text-black">City</h2>
                      <span className="text-[#1D242E] font-semibold text-sm">
                        Details of {placesData.name}, {placesData.state},{" "}
                        {placesData.country} - {placesData.zip_code}
                      </span>
                    </div>
                    {/* <Link
                  to="/places"
                  className="bg-[#03A9F5] flex text-white px-4 py-2 my-auto rounded"
                >
                  <span className="mt-2 mr-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M0 11.247V13.6112C0 13.8289 0.171087 14 0.388835 14H2.75295C2.85405 14 2.95514 13.9611 3.02514 13.8833L11.5173 5.39897L8.60103 2.48271L0.116651 10.9671C0.0388836 11.0449 0 11.1382 0 11.247ZM13.7725 3.14373C14.0758 2.84044 14.0758 2.35051 13.7725 2.04722L11.9528 0.227468C11.6495 -0.0758228 11.1596 -0.0758228 10.8563 0.227468L9.43313 1.6506L12.3494 4.56687L13.7725 3.14373Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  Edit Details
                </Link> */}
                  </div>
                  <Search text="Search in City Details" />
                </div>

                {/*  Discription  Form */}
                <div className="container py-4 px-8">
                  <div className="border-2 border-[#1d242e4d] rounded bg-white">
                    <div className="flex justify-between p-1">
                      <h2 className="pl-6 my-auto font-bold">Details</h2>
                      {/* Button for edit discription  */}
                      {editingImg ? (
                        <div className="px-3">
                          <i
                            className="fa-solid fa-check"
                            onClick={handleSaveAndClose}
                          ></i>
                        </div>
                      ) : (
                        <button
                          className=" text-white px-4 py-2"
                          onClick={handleEditClickImg}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              d="M0 11.247V13.6112C0 13.8289 0.171087 14 0.388835 14H2.75295C2.85405 14 2.95514 13.9611 3.02514 13.8833L11.5173 5.39897L8.60103 2.48271L0.116651 10.9671C0.0388836 11.0449 0 11.1382 0 11.247ZM13.7725 3.14373C14.0758 2.84044 14.0758 2.35051 13.7725 2.04722L11.9528 0.227468C11.6495 -0.0758228 11.1596 -0.0758228 10.8563 0.227468L9.43313 1.6506L12.3494 4.56687L13.7725 3.14373Z"
                              fill="black"
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                    <div className=" border-t-2 border-[#1d242e4d]">
                      <div className="flex flex-row">
                        <div className="flex flex-col pt-3 items-center justify-center w-1/4">
                          <h1 className="handleBoxTop ">City Profile</h1>
                          {editingImg ? (
                            <div className="">
                              <label>
                                <input
                                  type="file"
                                  name="image"
                                  accept="image/*"
                                  onChange={handleImageChange}
                                  style={{ display: "none" }}
                                />

                                <img
                                  id="selectedImage"
                                  style={{
                                    objectFit: "cover",
                                    width: "100px",
                                    height: "90px",
                                  }}
                                  className="  rounded-full m-3 ring-1 ring-[#000] ring-offset-3"
                                  // src={image || URL.createObjectURL(image)}
                                  src={placesData.image}
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src =
                                      URL.createObjectURL(image) ||
                                      "https://www.intrepidtravel.com/adventures/wp-content/uploads/2015/02/Intrepid-Travel-Chile-Patagonia-trekking-002.jpg";
                                  }}
                                  alt="act"
                                />
                              </label>
                            </div>
                          ) : (
                            <img
                              className="h-30 w-30 rounded-full m-3 ring-1 ring-[#BBBDC0] ring-offset-1"
                              src={
                                placesData.image === null
                                  ? "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ="
                                  : placesData.image
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src =
                                  "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ=";
                              }}
                              style={{
                                objectFit: "cover",
                                width: "100px",
                                height: "90px",
                              }}
                              alt="img"
                            />
                          )}
                        </div>
                        <div className="flex flex-col pl-6 w-1/4 py-5">
                          <h1 className="handleBoxTop ">
                            {placesData.city_id}
                          </h1>
                          {/* <CopyId id={placesData.city_id} /> */}
                          <p className="handleBoxPara font-bold">{"City ID"}</p>
                          {/* <div className="flex flex-row pl-4 w-1/2 py-5"> */}
                          <h1 className="handleBoxTop mt-4">
                            {placesData.zip_code}
                          </h1>
                          <p className="handleBoxPara font-bold">
                            {"Zip Code"}
                          </p>
                          {/* </div> */}
                        </div>
                        <div className="flex flex-col   w-1/4 py-5">
                          <h1 className="handleBoxTop">{placesData.name}</h1>
                          <p className="handleBoxPara  font-bold">{"City"}</p>
                          <h1 className="handleBoxTop mt-4">
                            {placesData.country}
                          </h1>
                          <p className="handleBoxPara font-bold">{"Country"}</p>
                        </div>
                        <div className="flex flex-col  w-1/4 py-5">
                          <h1 className="handleBoxTop">{placesData.state}</h1>
                          <p className="handleBoxPara font-bold">{"State"}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Photos  */}
                <div className="container py-4 px-8">
                  <div className="border-2 border-[#1d242e4d] rounded bg-white">
                    <div className=" border-b-2 border-[#1d242e4d] flex justify-between">
                      <h2 className="pl-6 py-3 my-auto font-bold">
                        Geographical Details
                      </h2>
                      {editing ? (
                        <div className="px-3">
                          <i
                            className="fa-solid fa-check"
                            onClick={handleSaveAndClose1}
                          ></i>
                        </div>
                      ) : (
                        <button
                          className=" text-white px-4 py-2"
                          onClick={handleEditClick}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              d="M0 11.247V13.6112C0 13.8289 0.171087 14 0.388835 14H2.75295C2.85405 14 2.95514 13.9611 3.02514 13.8833L11.5173 5.39897L8.60103 2.48271L0.116651 10.9671C0.0388836 11.0449 0 11.1382 0 11.247ZM13.7725 3.14373C14.0758 2.84044 14.0758 2.35051 13.7725 2.04722L11.9528 0.227468C11.6495 -0.0758228 11.1596 -0.0758228 10.8563 0.227468L9.43313 1.6506L12.3494 4.56687L13.7725 3.14373Z"
                              fill="black"
                            />
                          </svg>
                        </button>
                      )}
                    </div>

                    <div>
                      <div className=" px-6">
                        {editing ? (
                          <>
                            <Description
                              text="City Description"
                              placeholder="Describe here...."
                              value={description}
                              onChange={(e) => {
                                setCityDescription(e.target.value);
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <h2 className=" pt-3 pb-1 my-auto font-bold text-sm">
                              City Description
                            </h2>
                            <p className="text-sm">{placesData.description}</p>
                          </>
                        )}
                      </div>
                      {editing ? (
                        <div className="flex">
                          <div className="w-full pl-2">
                            <TagsDropdown
                              onSelect={handleTagSelection}
                              selectedTags={tags}
                              editing={editing}
                              ids={placesData.places_tags}
                            />
                          </div>
                          <div className="w-full pl-2">
                            <ActivityDropdown
                              onSelect={handleActivitySelection}
                              selectedActivities={activities}
                              editing={editing}
                              ids={placesData.activities}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-row pt-5">
                          <div className="w-full">
                            <MultiArrayList
                              text={"Place Tags"}
                              array={placesData.places_tags}
                            />
                          </div>
                          <div className="w-full">
                            <MultiArrayList
                              text="Activity"
                              array={placesData.activities}
                            />
                          </div>
                        </div>
                      )}

                      {editing ? (
                        <>
                          <div className="flex flex-row mr-6 m-4">
                            <MandatoryFeilds
                              text="Latitude"
                              placeholder="Latitude"
                              type="text"
                              htmlfor="Latitude"
                              value={lat}
                              onChange={handleLatChange}
                            />
                            <MandatoryFeilds
                              text="Longitude"
                              placeholder="Longitude"
                              type="text"
                              htmlfor="Longitude"
                              value={lon}
                              onChange={handleLonChange}
                            />
                          </div>
                        </>
                      ) : (
                        <div className="flex flex-row px-6">
                          <div className="flex flex-col   w-full py-5">
                            <h1 className="handleBoxTop">{placesData.lat}</h1>
                            <h2 className="handleBoxPara  font-bold">
                              {"Latitude"}
                            </h2>
                          </div>
                          <div className="flex flex-col  ml-4 px-4 w-full py-5">
                            <h1 className="handleBoxTop">{placesData.lon}</h1>
                            <h2 className="handleBoxPara  font-bold">
                              {"Longitude"}
                            </h2>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Delete Button  */}
                <div className="container flex px-8 py-4 pb-10">
                  <button className="text-[#F0483E] bg-white flex  px-4 py-2 rounded border-2 border-[#F0483E]">
                    <span className="mt-2 mr-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="11"
                        height="14"
                        viewBox="0 0 11 14"
                        fill="none"
                      >
                        <path
                          d="M0.777778 12.4444C0.777778 13.3 1.47778 14 2.33333 14H8.55556C9.41111 14 10.1111 13.3 10.1111 12.4444V4.66667C10.1111 3.81111 9.41111 3.11111 8.55556 3.11111H2.33333C1.47778 3.11111 0.777778 3.81111 0.777778 4.66667V12.4444ZM3.11111 4.66667H7.77778C8.20556 4.66667 8.55556 5.01667 8.55556 5.44444V11.6667C8.55556 12.0944 8.20556 12.4444 7.77778 12.4444H3.11111C2.68333 12.4444 2.33333 12.0944 2.33333 11.6667V5.44444C2.33333 5.01667 2.68333 4.66667 3.11111 4.66667ZM8.16667 0.777778L7.61444 0.225556C7.47444 0.0855555 7.27222 0 7.07 0H3.81889C3.61667 0 3.41444 0.0855555 3.27444 0.225556L2.72222 0.777778H0.777778C0.35 0.777778 0 1.12778 0 1.55556C0 1.98333 0.35 2.33333 0.777778 2.33333H10.1111C10.5389 2.33333 10.8889 1.98333 10.8889 1.55556C10.8889 1.12778 10.5389 0.777778 10.1111 0.777778H8.16667Z"
                          fill="#F0483E"
                        />
                      </svg>
                    </span>
                    Delete City
                  </button>
                </div>
              </>
            )}
          </div>
        </ActivityProvider>
      </TagsProvider>
    </div>
  );
}
