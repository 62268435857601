import React from "react";

export default function BlueButton({ text, index }) {
  return (
    <div>
      <button
        className="actcenterbtn px-2 py-2 "
        key={index}
        style={{
          backgroundColor: "#A7DBF5",
          color: "black",
          border: "none",
          cursor: "pointer",
          fontSize: "14px",
          borderRadius: "7px",
          marginRight: "28px",
          marginBottom: "8px",
        }}
      >
        {text}
      </button>
    </div>
  );
}
