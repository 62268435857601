import React from "react";

export default function MandatoryFeilds({
  text,
  type,
  placeholder,
  htmlfor,
  value,
  onChange,
  validationError,
  isRequired,
}) {
  return (
    <div className="mb-4 mx-6 w-2/3">
      <div>
        <div class="flex">
          <label
            htmlFor={htmlfor}
            class="block mb-2 text-sm font-medium text-gray-900 "
          >
            {text}
          </label>
          {isRequired ?
            <i
              class="fa-solid fa-asterisk fa-xs"
              style={{
                color: "#ff0a2f",
                paddingLeft: "3px",
                fontSize: "10px",
                paddingTop: "8px",
              }}
            ></i>
            : <></>}
        </div>
        <input
          type={type}
          value={value}
          id={htmlfor}
          class=" bg-white border text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 border-[#1d242e4d] placeholder-gray-400 "
          placeholder={placeholder}
          // required
          onChange={onChange}
        />
        {validationError && (
          <p className="text-red-500 text-sm mt-1">{validationError}</p>
        )}
      </div>
    </div>
  );
}
