import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import FourBoxDetails from "../components/FourBoxDetails";
import Search from "../components/Search";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { guideDetail2 } from "../../../constants/urls";
import CopyId from "../components/CopyId";
import TextFeild from "../components/TextFeild";

export default function Guideenquirydetails() {
  const location = useLocation();
  const enquirydata = location.state.data


  console.log(enquirydata)

  return (
    <div className="flexbox-container  handleBack " style={{ backgroundColor: "#EDF1F5",height:"150vh" }}>
      <div className="ml-60 ">
        <div className="container pt-5 px-12">
          <div className="flex justify-between">
            <div className="">
              <div className="flex">
                <div>
                  <h1 className="handleHeader">Guide Details</h1>
                  <span className="handleDescription">Details of Guide.</span>
                </div>
              </div>
             
            </div>
          </div>
          <div className="border-2 mt-6 border-[#1d242e4d] rounded bg-white ">
            <div className="flex justify-between ">
              <h2 className="my-3 subheading px-6 font-bold">Guide Details</h2>
              <div className="py-3 px-3">
                {/* <EditButton /> */}
              </div>
            </div>
            <div className="border-t-2  py-1 border-[#1d242e4d]">
              <div className="flex">
                <div className="w-1/2">
                  <TextFeild
                    text={"Guide Enquiry Id"}
                    placeholder={"101"}
                    mandatory={false}
                    value={
                      enquirydata.guide_enquiry_id}
                  />
                </div>
                <div className="w-1/2">
                  <TextFeild
                    text={"Name"}
                    placeholder={"15 Oct 2023"}
                    mandatory={false}
                    value={
                      enquirydata.name}
                  />

                </div>
              </div>
              <div className="flex">
                <div className="w-1/2">
                  <TextFeild
                    text={"Phone"}
                    placeholder={"101"}
                    mandatory={false}
                    value={
                      enquirydata.phone}
                  />
                </div>
                <div className="w-1/2">
                  <TextFeild
                    text={"DOB"}
                    placeholder={"15 Oct 2023"}
                    mandatory={false}
                    value={
                      enquirydata.dob}
                  />

                </div>
              </div>
              <div className="flex">
                <div className="w-1/2">
                  <TextFeild
                    text={"Email"}
                    placeholder={"101"}
                    mandatory={false}
                    value={
                      enquirydata.email}
                  />
                </div>
                <div className="w-1/2">
                  <TextFeild
                    text={"Gender"}
                    placeholder={"15 Oct 2023"}
                    mandatory={false}
                    value={
                      enquirydata.gender}
                  />

                </div>
              </div>
              <div className="flex">
                <div className="w-1/2">
                  <TextFeild
                    text={"Whatsapp Number"}
                    placeholder={"101"}
                    mandatory={false}
                    value={
                      enquirydata.whatsapp_number}
                  />
                </div>
                <div className="w-1/2">
                  <TextFeild
                    text={"Year Of Experience"}
                    placeholder={"15 Oct 2023"}
                    mandatory={false}
                    value={
                      enquirydata.year_of_experience}
                  />

                </div>
              </div>
              <div className="flex">
                <div className="w-1/2">
                  <TextFeild
                    text={"Aadhar Number"}
                    placeholder={"101"}
                    mandatory={false}
                    value={
                      enquirydata.aadhar_number}
                  />
                </div>
                <div className="w-1/2">
                  <TextFeild
                    text={"Languages"}
                    placeholder={"15 Oct 2023"}
                    mandatory={false}
                    value={
                      enquirydata.languages}
                  />

                </div>
              </div>
              <div className="flex">
                <div className="w-1/2">
                  <TextFeild
                    text={"Spot City"}
                    placeholder={"101"}
                    mandatory={false}
                    value={
                      enquirydata.spot_city}
                  />
                </div>
                <div className="w-1/2">
                  <TextFeild
                    text={"State"}
                    placeholder={"15 Oct 2023"}
                    mandatory={false}
                    value={
                      enquirydata.state}
                  />

                </div>
              </div>
              <div className="flex">
                <div className="w-1/2">
                  <TextFeild
                    text={"City"}
                    placeholder={"101"}
                    mandatory={false}
                    value={
                      enquirydata.city}
                  />
                </div>
                <div className="w-1/2">
                  <TextFeild
                    text={"Country"}
                    placeholder={"15 Oct 2023"}
                    mandatory={false}
                    value={
                      enquirydata.country}
                  />

                </div>
              </div>
              <div className="flex">
                <div className="w-1/2">
                  <TextFeild
                    text={"Price Per Day"}
                    placeholder={"101"}
                    mandatory={false}
                    value={
                      enquirydata.price_per_day}
                  />
                </div>
                <div className="w-1/2">
                  <TextFeild
                    text={"Currency"}
                    placeholder={"15 Oct 2023"}
                    mandatory={false}
                    value={
                      enquirydata.currency}
                  />

                </div>
              </div>
              <div className="flex">
                <div className="w-1/2">
                  <TextFeild
                    text={"Is Approved"}
                    placeholder={"101"}
                    mandatory={false}
                    value={
                      enquirydata.is_approved}
                  />
                </div>
                <div className="w-1/2">
                  <TextFeild
                    text={"Hobbies"}
                    placeholder={"15 Oct 2023"}
                    mandatory={false}
                    value={
                      enquirydata.hobbies}
                  />

                </div>
              </div>
              <div className="flex">
                <div className="w-1/2">
                  <TextFeild
                    text={"Places"}
                    placeholder={"101"}
                    mandatory={false}
                    value={
                      enquirydata.places}
                  />
                </div>
                <div className="w-1/2">
                  <TextFeild
                    text={"Zip Code"}
                    placeholder={"15 Oct 2023"}
                    mandatory={false}
                    value={
                      enquirydata.zip_code}
                  />

                </div>
              </div>
              <div className="flex">
                <div className="w-full">
                  <TextFeild
                    text={"Activities"}
                    placeholder={"101"}
                    mandatory={false}
                    value={enquirydata.activities}
                  />
                </div>
              </div>
              <div className="flex">
                <div className="w-full">
                  <TextFeild
                    text={"Bio"}
                    placeholder={"101"}
                    mandatory={false}
                    value={enquirydata.bio}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
