import React from "react";
import { Multiselect } from "multiselect-react-dropdown";
import TextBox from "./TextBox";
import MultiSelectDropdow from "./MultiSelectDropdow";
import MandatoryFeilds from "./MandatoryFeilds";
import AddButton from "./AddButton";
export default function Package({ handlePPDChange, price_per_day }) {
  const cities = [
    "New York",
    "Los Angeles",
    "Chicago",
    "Houston",
    "San Francisco",
    // Add more city names here
  ];
  const style = {
    searchBox: {
      border: "1px solid #1d242e4d",
      borderRadius: "5px",
    },
  };
  return (
    <div className="pb-8">
      <div className=" pb-4 pt-1 border-2  border-[#1d242e4d] rounded bg-white">
        <h2 className="py-3 px-6 font-bold">Package</h2>

        <div className=" pl-2 border-t-2 border-[#1d242e4d]">
          <div className="w-1/2 pt-2">
            <MandatoryFeilds
              text="Price Per Day"
              value={price_per_day}
              onChange={handlePPDChange}
              placeholder="Rs. 1000/- per day"
              isRequired={true}
            />
          </div>
          {/* <div className="flex flex-row pb-3 justify-between">
            <div class="mx-6 block mb-2 text-md font-medium text-gray-900 ">
              Packages
            </div>
            <AddButton text={"Add Pacakges"} />
          </div> */}
        </div>
        {/* <div className="w-full">
          <div className="bg-[#FFF]">
            <div className="relative shadow-md sm:rounded-md ">
              <table className="w-full text-sm text-left text-gray-500">
                <thead className="border-2 border-[#1d242e4d] text-sm text-center text-gray-700 bg-white-50">
                  <tr>
                    <th scope="col" className="px-6 py-4">
                      Package Id
                    </th>
                    <th scope="col" className="px-6 py-3">
                      No of Days
                    </th>
                    <th scope="col" className="px-6  py-3">
                      Price
                    </th>
                    <th scope="col" className="px-6  py-3">
                      City
                    </th>
                    <th scope="col" className="py-3">
                      Places
                    </th>
                    <th scope="col" className="py-3">
                      Activities
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <td className="px-6 text-center py-4 font-medium text-gray-600 whitespace-nowrap">
                    101
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="text-center py-1 font-medium text-gray-600">
                    <div
                      className="w-50"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <div className=" w-2/3">
                        <div className="bg-white-200 text-gray-900 text-sm rounded focus:ring-blue-500  block border-[#1d242e4d] placeholder-gray-400 ">
                          <Multiselect
                            placeholder="Places"
                            options={cities}
                            isObject={false}
                            style={style}
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="text-center py-1 font-medium text-gray-600">
                    <div
                      className="w-50"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <div className=" w-2/3">
                        <div className="bg-white-200 text-gray-900 text-sm rounded focus:ring-blue-500  block border-[#1d242e4d] placeholder-gray-400 ">
                          <Multiselect
                            placeholder="Activities"
                            options={cities}
                            isObject={false}
                            style={style}
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                </tbody>
              </table>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
