import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useCityContext } from "../../../controllers/city_controllers";
import { CityDropdownn } from "../../../constants/urls";
import PlaceDropdownRelatedCity from "./PlaceDropdownRelatedCity";
import { PlaceListProvider } from "../../../controllers/PlaceList_controller";
export default function CityDropdown2({
  setCityId,
  onChange,
  selectedActivities,
  onSelect,
}) {
  const cityData = useCityContext();
  const { setSearchQuery } = useCityContext();
  const cities = cityData?.state?.cities?.cities || [];
  const [selectedValue, setSelectedValue] = useState(null);
  const [data, setData] = useState([]);
  const [city, setCity] = useState("");

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#3182ce" : "white",
      color: state.isSelected ? "white" : "black",
    }),
  };
  const CustomOption = ({ innerProps, label, image }) => (
    <div {...innerProps}>
      <div className="flex py-2 px-4">
        <div className="flex items-center justify-center h-full">
          <img
            className="h-6 w-6 rounded-full   "
            src={
              image ||
              "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ="
            }
            alt={label}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ=";
            }}
          />
        </div>
        <span className="ml-2">{label}</span>
      </div>
    </div>
  );

  useEffect(() => {
    if (selectedValue) {
      const apiUrl = `${CityDropdownn}`;

      async function fetchInfo() {
        try {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append(
            "Authorization",
            `Token ${localStorage.getItem("token")}`
          );
          var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
          };

          const response = await fetch(apiUrl, requestOptions);
          const result = await response.json();
          // console.log(result);
          if (response.ok) {
            const resultData = result?.results;

            setData(resultData);
            console.log("data", data);
            console.log(selectedValue);
          } else {
            console.log(`Error: ${result.error}`);
          }
        } catch (error) {
          console.error(`Error: ${error}`);
        }
      }

      fetchInfo();
    }
  }, [selectedValue]);
  const cityOptions = cities?.map((city) => ({
    value: city.city_id,
    label: city.name,
    image:
      city.image ||
      "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ=",
  }));

  const handleCitySelection = (selectedOption) => {
    setSelectedValue(selectedOption.value);
    setCityId(selectedOption.value);
    setCity(selectedOption.value);
  };

  return (
    <>
      <div className="mb-3">
        <div class="flex pb-2">
          <label class="block mb-2 text-sm font-medium text-gray-900 ">
            City
          </label>
          <i
            class="fa-solid fa-asterisk fa-xs"
            style={{
              color: "#ff0a2f",
              paddingLeft: "3px",
              fontSize: "10px",
              paddingTop: "8px",
            }}
          ></i>
        </div>
        <Select
          id="dropdown"
          name="dropdown"
          options={cityOptions}
          value={cityOptions.find((option) => option.value === selectedValue)}
          onInputChange={(inputValue) => {
            setSearchQuery(inputValue);
          }}
          onChange={handleCitySelection}
          isSearchable
          styles={customStyles}
          components={{ Option: CustomOption }}
        />
      </div>
      {/* <PlaceListProvider>
        <PlaceDropdownRelatedCity
          city_id={city}
          onSelect={onSelect}
          selectedActivities={selectedActivities}
        />
      </PlaceListProvider> */}
    </>
  );
}
