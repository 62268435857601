import React from "react";
import "./Modal.css";

export default function Modal({ isOpen, onClose, children }) {
  return (
    <div className={`modal ${isOpen ? "open" : ""}`}>
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-content">{children}</div>
    </div>
  );
}
