import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { usePlaceListContext } from "../../../controllers/PlaceList_controller";
import Search from "../components/Search";
import PaginationNo from "../components/PaginationNo";
import AddButton from "../components/AddButton";
import CityAndPlaceDropdown from "../components/StateAndCountry";
import { Switch } from "@mui/material";
import Loading from "../components/Loading";

export default function ListOFCityComponent() {
  const { state, getNextPage, getPrevPage, setSearchQuery, setStateId, setIsEnabled } =
    usePlaceListContext();
  const citydata = state.places.places || [];
  const currentPage = state.page;
  const [isChecked, setIsChecked] = useState(null);
  const [showDisabledPlaces, setShowDisabledPlaces] = useState(false);
  const navigate = useNavigate();

  const handleCheckboxChange = async (row) => {
    try {
      const response = await fetch(
        `https://orca-app-pgxkr.ondigitalocean.app/data/place/${row.place_id}/`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            is_active: !row.is_active,
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("API Response:", data);
        window.location.reload();
        setIsChecked((prevIsChecked) => !prevIsChecked);
        console.log("After toggle, isChecked:", isChecked);
      } else {
        console.error("Failed to update is_active state");
      }
    } catch (error) {
      console.error("Error updating is_active state:", error.message);
    }
  };


  const handleSwitchChanges = async (row) => {
    setShowDisabledPlaces(row);
    setIsEnabled(row);
  };

  return (
    <div className="px-7">
      <div>
        {console.log("places", state.totalPost)}
        <div className="flex justify-between">
          <div className="px-1">
            <h1 className="font-bold text-2xl font-poppins text-gray-400">
              All Places
              <span className="text-gray-800">
                {" > "}List of Places ({state?.totalPost})
              </span>
            </h1>
            <span className=" font-semibold text-sm font-poppins">
              List of Total places
            </span>
          </div>
          <div>
            <Link to="/places/addNewPlaces">
              <AddButton text="Add New Places" />
            </Link>
          </div>
        </div>
      </div>

      <div className="container pb-3">
        <div>
          <div className="flex justify-between pt-3">
            <div className="flex">
              <Search
                text="Search for Places..."
                setSearchQuery={setSearchQuery}
              />
              <CityAndPlaceDropdown setStateId={setStateId} />
              <div className="flex flex-row self-center ml-4">
                <label htmlFor="Enable toggle flex-1">Show Disabled</label>
                <Switch
                  checked={showDisabledPlaces}
                  onChange={() => handleSwitchChanges(!showDisabledPlaces)}
                  color="primary"
                />
              </div>
            </div>

            <div className="flex justify-end w-1/2">
              <div className="w-1/8">
                {/* <Dropdown placeholder="Select Activity" /> */}
              </div>
              <div style={{ marginLeft: "4px", marginTop: "8px" }}>
                <PaginationNo
                  post_per_page={state.post_per_page}
                  getPrevPage={() => {
                    getPrevPage();
                    navigate(`/places/listOfPlaces/?page=${currentPage - 1}`); // Use navigate instead of history.push
                  }}
                  totalPost={state?.totalPost}
                  getNextPage={() => {
                    getNextPage();
                    navigate(`/places/listOfPlaces/?page=${currentPage + 1}`); // Use navigate instead of history.push
                  }}
                  // currentPage={state?.page}
                  currentPage={state?.page}
                />
              </div>
            </div>
          </div>
          <>
            {state?.isloading ? (
              <Loading />
            ) : (
              <div className="relative pt-3 overflow-x-auto shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left border border-[#1d242e4d]   bg-white shadow-lg">
                  <thead className="table-heading ">
                    <tr>
                      <th scope="col" className="p-4 ">
                        <div className="flex items-center justify-center">
                          Place ID
                          {/* <svg
                          class="w-3 h-3 ml-1.5"
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="13"
                          viewBox="0 0 8 13"
                          fill="none"
                        >
                          <path
                            d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
                            fill="#1D242E"
                          />
                          <path
                            d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
                            fill="#1D242E"
                          />
                        </svg>  */}
                        </div>
                      </th>
                      <th scope="col" className="p-4">
                        <div className="flex items-center justify-center">
                          Place Name
                        </div>
                      </th>
                      <th scope="col" className="p-4">
                        <div className="flex items-center justify-center">
                          City Name
                        </div>
                      </th>

                      <th scope="col" className="p-4">
                        <div className="flex items-center justify-center">
                          Total Reviews
                        </div>
                      </th>

                      <th scope="col" className="p-4">
                        <div className="flex items-center justify-center">
                          Total Activity Center
                          {/* <a href="/">
                        <svg
                          class="w-3 h-3 ml-1.5"
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="13"
                          viewBox="0 0 8 13"
                          fill="none"
                        >
                          <path
                            d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
                            fill="#1D242E"
                          />
                          <path
                            d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
                            fill="#1D242E"
                          />
                        </svg>
                      </a> */}
                        </div>
                      </th>
                      <th scope="col" className="p-5">
                        <div className="flex items-center justify-center">
                          Total Guide
                          {/* <a href="/">
                        <svg
                          class="w-3 h-3 ml-1.5"
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="13"
                          viewBox="0 0 8 13"
                          fill="none"
                        >
                          <path
                            d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
                            fill="#1D242E"
                          />
                          <path
                            d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
                            fill="#1D242E"
                          />
                        </svg>
                      </a> */}
                        </div>
                      </th>
                      <th scope="col" className="p-4">
                        <div className="flex items-center justify-center">
                          is Active
                        </div>
                      </th>

                    </tr>
                  </thead>

                  <tbody>
                    {citydata.map((row) => (
                      <tr className="border-t-2 table-text border-gray-200">
                        <Link
                          key={row.city_id}
                          to={`/places/EditPlaceDetails/${row.place_id}/`}
                          style={{ display: "contents" }}
                          className="block hover:bg-gray-100"
                        >
                          <td className="text-center   pl-2 pr-6 py-4">
                            {row.place_id}
                          </td>
                          <td className="text-center   px-4 py-4">
                            {row.name}
                          </td>
                        </Link>
                        <Link
                          to={`/places/DetailsCity/${row.city_id}/`}
                          style={{ display: "contents" }}
                        >
                          <td className="text-center px-6 py-4">
                            {row.city_name}
                          </td>
                        </Link>

                        <td className="text-center px-6 py-4">
                          {row.total_reviews}
                        </td>
                        <td className="text-center px-6 py-4">
                          {row.total_activity_centers}
                        </td>
                        <td className="text-center px-6 py-4">
                          {row.total_guides}
                        </td>
                        <td className="text-center px-6 py-4">
                          <Switch
                            checked={row.is_active}
                            // onChange={handleCheckboxChange(row)}
                            onChange={() => handleCheckboxChange(row)}
                            color="primary"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}{" "}
          </>
        </div>
      </div>
    </div>
  );
}

// import React from "react";
// import { Link } from "react-router-dom";
// import { PlaceList } from "../../../constants/urls";
// import { usePlaceListContext } from "../../../controllers/PlaceList_controller";
// export default function PlaceComponentList() {
//   const placeList = usePlaceListContext();
//   return (
//     <div>
//       <div className="flex justify-between">
//         <div className="px-2">
//           <h1 className="font-bold text-lg text-gray-300">
//             All Places
//             <span className="text-gray-500">List of Cities (298)</span>
//             <span className="text-gray-800">List of Places(56)</span>
//           </h1>
//           <span className=" font-semibold text-sm">
//             List of Total Places in Indore, Madhya Pradesh, India - 452001
//           </span>
//         </div>
//         <div className="border-2 px-4 py-1 my-auto rounded bg-red-500">
//           <Link
//             to="/places/addNewPlaces"
//             className="flex my-auto text-lg text-white"
//           >
//             <span className="my-auto mr-3">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 width="14"
//                 height="14"
//                 viewBox="0 0 14 14"
//                 fill="none"
//               >
//                 <path
//                   d="M13 8H8V13C8 13.55 7.55 14 7 14C6.45 14 6 13.55 6 13V8H1C0.45 8 0 7.55 0 7C0 6.45 0.45 6 1 6H6V1C6 0.45 6.45 0 7 0C7.55 0 8 0.45 8 1V6H13C13.55 6 14 6.45 14 7C14 7.55 13.55 8 13 8Z"
//                   fill="white"
//                 />
//               </svg>
//             </span>
//             Add New Places
//           </Link>
//         </div>
//         {/* </div> */}
//       </div>
//       {/* Search and Pagination  */}
//       <div className=" container py-4 px-5">
//         <div className="flex justify-between">
//           <form action="/" method="GET" className="hidden lg:block lg:pl-2">
//             <label for="topbar-search" className="sr-only">
//               Search
//             </label>
//             <div className="relative mt-1 lg:w-96">
//               <div className="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none">
//                 <svg
//                   className="w-5 h-5 text-gray-600"
//                   fill="currentColor"
//                   viewBox="0 0 20 20"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <path
//                     fillRule="evenodd"
//                     d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
//                     clip-rule="evenodd"
//                   ></path>
//                 </svg>
//               </div>
//               <input
//                 type="text"
//                 name="email"
//                 id="topbar-search"
//                 className="bg-gray-200 focus:bg-gray-100 border-2 border-gray-300 text-gray-900 sm:text-sm rounded focus:ring-primary-500 focus:border-primary-500 block w-full p-2  focus:ring-primary-500 focus:border-primary-500"
//                 placeholder="Search for Places..."
//               />
//             </div>
//           </form>
//           <div className=" w-1/7">
//             {/* <select className="form-select block w-full border-2 border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded px-8 py-1">
//               {Activities.map((list) => (
//                 <option>{list}</option>
//               ))}
//             </select> */}
//           </div>
//         </div>
//       </div>

//       {/* List of Cities  */}
//       <div className="container py-4 px-5">
//         <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
//           <table className="w-full text-sm text-left text-gray-800 bg-white shadow-lg">
//             <thead className="text-xs text-gray-700 uppercase">
//               <tr>
//                 <th scope="col" className="p-6">
//                   <div className="flex items-center justify-center">
//                     Place ID
//                     <a href="/">
//                       <svg
//                         class="w-3 h-3 ml-1.5"
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="8"
//                         height="13"
//                         viewBox="0 0 8 13"
//                         fill="none"
//                       >
//                         <path
//                           d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
//                           fill="#1D242E"
//                         />
//                         <path
//                           d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
//                           fill="#1D242E"
//                         />
//                       </svg>
//                     </a>
//                   </div>
//                 </th>
//                 <th scope="col" className="p-6">
//                   <div className="flex items-center justify-center">
//                     Name Of Place
//                     <a href="/">
//                       <svg
//                         class="w-3 h-3 ml-1.5"
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="8"
//                         height="13"
//                         viewBox="0 0 8 13"
//                         fill="none"
//                       >
//                         <path
//                           d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
//                           fill="#1D242E"
//                         />
//                         <path
//                           d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
//                           fill="#1D242E"
//                         />
//                       </svg>
//                     </a>
//                   </div>
//                 </th>
//                 <th scope="col" className="p-6">
//                   <div className="flex items-center justify-center">
//                     Total Activity Center
//                     <a href="/">
//                       <svg
//                         class="w-3 h-3 ml-1.5"
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="8"
//                         height="13"
//                         viewBox="0 0 8 13"
//                         fill="none"
//                       >
//                         <path
//                           d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
//                           fill="#1D242E"
//                         />
//                         <path
//                           d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
//                           fill="#1D242E"
//                         />
//                       </svg>
//                     </a>
//                   </div>
//                 </th>
//                 <th scope="col" className="p-6">
//                   <div className="flex items-center justify-center">
//                     Total Guide
//                     <a href="/">
//                       <svg
//                         class="w-3 h-3 ml-1.5"
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="8"
//                         height="13"
//                         viewBox="0 0 8 13"
//                         fill="none"
//                       >
//                         <path
//                           d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
//                           fill="#1D242E"
//                         />
//                         <path
//                           d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
//                           fill="#1D242E"
//                         />
//                       </svg>
//                     </a>
//                   </div>
//                 </th>
//                 <th scope="col" className="p-6">
//                   <div className="flex items-center justify-center">
//                     Reviews
//                     <a href="/">
//                       <svg
//                         class="w-3 h-3 ml-1.5"
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="8"
//                         height="13"
//                         viewBox="0 0 8 13"
//                         fill="none"
//                       >
//                         <path
//                           d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
//                           fill="#1D242E"
//                         />
//                         <path
//                           d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
//                           fill="#1D242E"
//                         />
//                       </svg>
//                     </a>
//                   </div>
//                 </th>
//                 <th scope="col" className="p-6">
//                   <div className="flex items-center justify-center">
//                     Rating
//                     <a href="/">
//                       <svg
//                         class="w-3 h-3 ml-1.5"
//                         xmlns="http://www.w3.org/2000/svg"
//                         width="8"
//                         height="13"
//                         viewBox="0 0 8 13"
//                         fill="none"
//                       >
//                         <path
//                           d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
//                           fill="#1D242E"
//                         />
//                         <path
//                           d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
//                           fill="#1D242E"
//                         />
//                       </svg>
//                     </a>
//                   </div>
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//               <Link
//                 to="/places/EditPlaceDetails"
//                 style={{ display: "contents" }}
//               >
//                 {/* {placeData.map((row) => (
//                   <tr key={row.placeId} className=" border-t border-gray-200">
//                     <td className="text-center px-6 py-4">{row.placeId}</td>
//                     <td className="text-center px-6 py-4">{row.nameOfPlace}</td>
//                     <td className="text-center px-6 py-4">
//                       {row.totalActivityCenter}
//                     </td>
//                     <td className="text-center px-6 py-4">{row.totalGuide}</td>
//                     <td className="text-center px-6 py-4">{row.reviews}</td>
//                     <td className="text-center px-6 py-4">{row.rating}</td>
//                   </tr>
//                 ))} */}
//               </Link>
//             </tbody>
//           </table>
//         </div>
//       </div>
//     </div>
//   );
// }
