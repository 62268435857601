import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import { useState, useEffect } from "react";
import { useCityListContext } from "../../../controllers/CityList_controller";
import { usePlaceListContext } from "../../../controllers/PlaceList_controller";
import Search from "../components/Search";
import AddButton from "../components/AddButton";
import PaginationNo from "../components/PaginationNo";
import CityAndPlaceDropdown from "../components/StateAndCountry";
export default function ListOFCityComponent() {
  const { state, getNextPage, getPrevPage, setSearchQuery, setStateId } =
    useCityListContext();
  const placeList = usePlaceListContext();
  let citydata = state?.places?.cities || [];

  useEffect(() => {}, [placeList]);
  const currentPage = state?.page;
  const location = useLocation();
  const navigate = useNavigate(); // Use useNavigate instead of useHistory
  const urlSearchParams = new URLSearchParams(location.search);

  return (
    <div>
      <div className=" ">
        {/* {console.log(sta)} */}
        <div className="flex justify-between">
          <div className="px-2 ">
            <h1 className="font-bold text-2xl font-poppins text-gray-400">
              All Places
              <span className="text-gray-800">
                {" > "}List of Cities ({state?.totalPost})
              </span>
            </h1>
            <span className=" font-semibold font-poppins text-sm">
              List of Total cities
            </span>
          </div>
          <div>
            <AddButton text={"Add New Places"} />
          </div>
        </div>
      </div>
      <div className="py-2">
        <div className="flex justify-between">
          <div className="flex">
            <Search text="Search for city.." setSearchQuery={setSearchQuery} />
            <CityAndPlaceDropdown setStateId={setStateId} />
          </div>
          <PaginationNo
            post_per_page={state.post_per_page}
            getPrevPage={() => {
              getPrevPage();
              navigate(`/places/listOfCities/?page=${currentPage - 1}`); // Use navigate instead of history.push
            }}
            totalPost={state?.totalPost}
            getNextPage={() => {
              getNextPage();
              navigate(`/places/listOfCities/?page=${currentPage + 1}`); // Use navigate instead of history.push
            }}
            currentPage={currentPage}
          />
        </div>
      </div>
      <>
        {state?.isloading ? (
          <Loading />
        ) : (
          <div className="container py-3">
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full bg-white border border-[#1d242e4d]  shadow-lg">
                <thead className=" table-heading">
                  <tr>
                    <th className="p-4">
                      <div className="flex items-center justify-center">
                        City ID
                        <svg
                          class="w-3 h-3 ml-1.5"
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="13"
                          viewBox="0 0 8 13"
                          fill="none"
                        >
                          <path
                            d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
                            fill="#1D242E"
                          />
                          <path
                            d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
                            fill="#1D242E"
                          />
                        </svg>
                      </div>
                    </th>
                    <th scope="col" className="p-4">
                      <div className="flex items-center justify-center">
                        City Name
                      </div>
                    </th>
                    <th scope="col" className="p-4">
                      <div className="flex items-center justify-center">
                        Total Places
                        <a href="/">
                          <svg
                            class="w-3 h-3 ml-1.5"
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="13"
                            viewBox="0 0 8 13"
                            fill="none"
                          >
                            <path
                              d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
                              fill="#1D242E"
                            />
                            <path
                              d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
                              fill="#1D242E"
                            />
                          </svg>
                        </a>
                      </div>
                    </th>
                    <th scope="col" className="p-4">
                      <div className="flex items-center justify-center">
                        No of Activity Center
                        <a href="/">
                          <svg
                            class="w-3 h-3 ml-1.5"
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="13"
                            viewBox="0 0 8 13"
                            fill="none"
                          >
                            <path
                              d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
                              fill="#1D242E"
                            />
                            <path
                              d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
                              fill="#1D242E"
                            />
                          </svg>
                        </a>
                      </div>
                    </th>
                    <th scope="col" className="p-4">
                      <div className="flex items-center justify-center">
                        Total No of Guides
                        <a href="/">
                          <svg
                            class="w-3 h-3 ml-1.5"
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="13"
                            viewBox="0 0 8 13"
                            fill="none"
                          >
                            <path
                              d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
                              fill="#1D242E"
                            />
                            <path
                              d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
                              fill="#1D242E"
                            />
                          </svg>
                        </a>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {citydata.map((row) => (
                    <tr
                      // onClick={() => handleCityClick(row.city_id)}
                      className="border-t border-gray-200 table-text"
                    >
                      <td className="text-center px-6 py-4">{row.city_id}</td>
                      <Link
                        to={`/places/DetailsCity/${row.city_id}/`}
                        style={{ display: "contents" }}
                      >
                        <td className="text-center  px-6 py-4">{row.name}</td>
                      </Link>
                      {row.total_places > 0 ? (
                        <Link
                          key={row.city_id}
                          to={`/places/listOfCities/${row.city_id}/listOfPlaces`}
                          style={{ display: "contents" }}
                          className="block hover:bg-gray-100"
                        >
                          <td className="text-center px-6 py-4">
                            {row.total_places}
                          </td>
                        </Link>
                      ) : (
                        <td className="text-center px-6 py-4">
                          {row.total_places}
                        </td>
                      )}

                      <td className="text-center px-6 py-4">
                        {row.no_of_centers}
                      </td>
                      <td className="text-center px-6 py-4">
                        {row.total_guides}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </>
    </div>
  );
}
