import axios from "axios";
import { BASE_URL } from "../../../../constants/baseUrl";
export const editStayRoomAPi = async (data, id) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  };

  const response = await axios.patch(
    `${BASE_URL}/bookings/stay/${id}/`,
    data,
    config
  );

  return response;
};

export const socialMediaPostApi = async (data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Token ${localStorage.getItem("token")}`,
    },
  };

  const response = await axios.post(
    `${BASE_URL}/bookings/socialmedia/`,
    data,
    config
  );

  return response;
};
