import { country, stateurl } from "../constants/urls";
import React, {
  createContext,
  useReducer,
  useContext,
  useEffect,
  useState,
} from "react";

const CountryContext = createContext();
const StateContext = createContext();
const initialState = {
  country: [],
  query: "",
  isloading: true,
  post_per_page: 8,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_COUNTRY":
      return {
        ...state,
        country: action.payload,

        isloading: false,
      };
    case "SET_QUERY":
      return { ...state, query: action.payload };
    case "SET_LOADING":
      return { ...state, isloading: true };
    default:
      return state;
  }
}

export function CountryProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    async function fetchInfo() {
      dispatch({ type: "SET_LOADING" });
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          `Token ${localStorage.getItem("token")}`
        );

        const queryParams = new URLSearchParams();
        queryParams.append("name", state.query);

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const apiUrlWithQuery = `${country}?${queryParams.toString()}&page_size=${
          state.post_per_page
        }`;

        const response = await fetch(apiUrlWithQuery, requestOptions);
        const result = await response.json();
        if (response.ok) {
          dispatch({
            type: "SET_COUNTRY",
            payload: {
              country: result,
            },
          });
        } else {
          console.log(`Error: ${result.error}`);
        }
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    }

    fetchInfo();
  }, [state.query]);

  const setSearchQuery = (searchQuery) => {
    dispatch({ type: "SET_QUERY", payload: searchQuery });
  };
  return (
    <CountryContext.Provider value={{ state, setSearchQuery }}>
      {children}
    </CountryContext.Provider>
  );
}

export function StateProvider({ children }) {
  const [Statevalue, setStateValue] = useState([]);
  const [Countryvalue, setCountryValue] = useState([]);

  useEffect(() => {
    async function fetchInfo() {
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          `Token ${localStorage.getItem("token")}`
        );

        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(stateurl, requestOptions);
        const result = await response.json();

        if (response.ok) {
          //   console.log(result);

          setStateValue(result);
        } else {
          console.log(`Error: ${result.error}`);
        }
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    }

    fetchInfo();
  }, []);
  return (
    <StateContext.Provider value={{ Statevalue, Countryvalue }}>
      {children}
    </StateContext.Provider>
  );
}

export function useCountryContext() {
  return useContext(CountryContext);
}
export function useStateContext() {
  return useContext(StateContext);
}
// export { fetchInfo };
