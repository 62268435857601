import React from "react";
import loadin_gif from "../assets/loadin_gif.gif";
export default function Loading() {
  return (
    <div className="relative">
      <div className="center-screen">
        <div className="flex items-center justify-center ">
          <img src={loadin_gif} alt="loading..." />
        </div>
      </div>
    </div>
  );
}
