import React from "react";
import Search from "../components/Search";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import FourBoxDetails from "../components/FourBoxDetails";
import AddButton from "../components/AddButton";
import ActButton from "../components/ActButton";
import CopyId from "../components/CopyId";
import BlueButton from "../components/BlueButton";
import { centerDetail } from "../../../constants/urls";
import ImgPhotosPost from "../components/ImgPhotosPost";
import BlueButtonCross from "../components/BlueButtonCross";
import { addCenter } from "../../../constants/urls";
export default function ActivityCentersDetails() {
  //   const activityy = ["Hiking", "Trekking", "Camping", "Barbecues"];
  const { center_id } = useParams();
  const [centerData, setCenterData] = useState([]);
  const [editedContactInfo, setEditedContactInfo] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const apiUrl = `${centerDetail}${center_id}`;

  // const [editedContactInfo, setEditedContactInfo] = useState({
  //   contactNumber: "",
  //   alternateNumber: "",
  //   email: "",
  //   address: "",
  // });



  const handleEditClick = () => {
    setIsEditing(true);
  };

  // Function to handle edit and PATCH request
  const handleSaveClick = async () => {
    try {
      const response = await fetch(`https://orca-app-pgxkr.ondigitalocean.app/data/center/${center_id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(editedContactInfo),
      });
      const result = await response.json();
      if (response.ok) {
        setCenterData(result);
        setIsEditing(false);
      } else {
        console.log(`Error: ${result.error}`);
      }
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedContactInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  console.log(centerData)
  useEffect(() => {
    async function fetchInfo() {
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          `Token ${localStorage.getItem("token")}`
        );
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(apiUrl, requestOptions);
        const result = await response.json();
        if (response.ok) {
          setCenterData(result);
          console.log(centerData);
        } else {
          console.log(`Error: ${result.error}`);
        }
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    }

    fetchInfo();
  }, [apiUrl]);
  return (
    <div className="handleBack">
      <div className="ml-60 ">
        <div className="container py-5 px-12">
          <div className="flex justify-between">
            <div className="">
              <div className="flex">
                <div>
                  <h1 className="handleHeader">Activity Center Details</h1>
                  <span className="handleDescription">
                    Details of Activity Centers.
                  </span>
                </div>
                <div className="editbtn">
                  <Link
                    to="/activityCenter"
                    className="bg-[#03A9F5] flex text-white px-4 py-2 my-auto rounded"
                  >
                    <span className="mt-2 mr-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M0 11.247V13.6112C0 13.8289 0.171087 14 0.388835 14H2.75295C2.85405 14 2.95514 13.9611 3.02514 13.8833L11.5173 5.39897L8.60103 2.48271L0.116651 10.9671C0.0388836 11.0449 0 11.1382 0 11.247ZM13.7725 3.14373C14.0758 2.84044 14.0758 2.35051 13.7725 2.04722L11.9528 0.227468C11.6495 -0.0758228 11.1596 -0.0758228 10.8563 0.227468L9.43313 1.6506L12.3494 4.56687L13.7725 3.14373Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                    Edit Details
                  </Link>
                </div>
              </div>
              <div className="flex flex-row">
                <Search text={"Search for Activity Center Details"} />
              </div>
            </div>
          </div>

          <div className=" py-4 ">
            <div className="border-2  border-[#1d242e4d] rounded bg-white ">
              <div className="flex justify-between">
                <h2 className="py-3 px-5 font-bold box-heading">Details</h2>
                {/* Button for edit details  */}
                <button className=" text-white px-4 py-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M0 11.247V13.6112C0 13.8289 0.171087 14 0.388835 14H2.75295C2.85405 14 2.95514 13.9611 3.02514 13.8833L11.5173 5.39897L8.60103 2.48271L0.116651 10.9671C0.0388836 11.0449 0 11.1382 0 11.247ZM13.7725 3.14373C14.0758 2.84044 14.0758 2.35051 13.7725 2.04722L11.9528 0.227468C11.6495 -0.0758228 11.1596 -0.0758228 10.8563 0.227468L9.43313 1.6506L12.3494 4.56687L13.7725 3.14373Z"
                      fill="black"
                    />
                  </svg>
                </button>
              </div>
              <div className="pl-2 pb-5 border-t-2 border-[#1d242e4d]">
                <div className="flex flex-row ">
                  <div className="flex flex-col pl-4 w-full py-5">
                    <h1
                      className="handleBoxTop"
                      style={{ position: "relative", top: "2px" }}
                    >
                      <CopyId id={centerData.center_id} />
                    </h1>

                    <p
                      className="handleBoxPara font-bold"
                      style={{ position: "relative", top: "22px" }}
                    >
                      Activity Center Id
                    </p>
                  </div>
                  <div className="flex flex-col px-5 w-full py-5">
                    <h1 className="handleBoxTop"> {centerData.name}</h1>
                    <p className="handleBoxPara  font-bold">
                      {" "}
                      Activity Center Name
                    </p>
                  </div>
                  <div className="flex flex-col pl-4 w-full py-5">
                    <h1 className="handleBoxTop ">
                      {(centerData.lat, centerData.lon) || "Not Available"}
                    </h1>
                    <p className="handleBoxPara font-bold">
                      {"Latitude, Longitude"}
                    </p>
                  </div>
                  <div className="flex flex-col px-5  w-full py-5">
                    <h1 className="handleBoxTop">{centerData.city_name}</h1>
                    <p className="handleBoxPara font-bold">City</p>
                  </div>
                </div>
                <div>
                  <h2 className="pb-3 px-4 handleBoxTop">Places</h2>
                  <div className="flex flex-wrap px-4">
                    {centerData?.places_names?.map((place, index) => (
                      <BlueButton key={index} text={place} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row">
            <div className="py-4 pr-4 w-1/2">
              <div className="border-2 border-[#1d242e4d] rounded bg-white">
                <div className="flex justify-between">
                  <h2 className="py-3 px-6 font-bold">Contact</h2>
                  {/* <button className=" text-white px-4 py-2"
                    onClick={handleEditContactInfo}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M0 11.247V13.6112C0 13.8289 0.171087 14 0.388835 14H2.75295C2.85405 14 2.95514 13.9611 3.02514 13.8833L11.5173 5.39897L8.60103 2.48271L0.116651 10.9671C0.0388836 11.0449 0 11.1382 0 11.247ZM13.7725 3.14373C14.0758 2.84044 14.0758 2.35051 13.7725 2.04722L11.9528 0.227468C11.6495 -0.0758228 11.1596 -0.0758228 10.8563 0.227468L9.43313 1.6506L12.3494 4.56687L13.7725 3.14373Z"
                        fill="black"
                      />
                    </svg>
                  </button> */}

                  {isEditing ? (
                    <button className="bg-[#FFFFFF] flex text-white px-4 py-2 my-auto rounded" onClick={handleSaveClick}>
                      <span className="mt-2 mr-3">
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                          <path d="M0 11.247V13.6112C0 13.8289 0.171087 14 0.388835 14H2.75295C2.85405 14 2.95514 13.9611 3.02514 13.8833L11.5173 5.39897L8.60103 2.48271L0.116651 10.9671C0.0388836 11.0449 0 11.1382 0 11.247ZM13.7725 3.14373C14.0758 2.84044 14.0758 2.35051 13.7725 2.04722L11.9528 0.227468C11.6495 -0.0758228 11.1596 -0.0758228 10.8563 0.227468L9.43313 1.6506L12.3494 4.56687L13.7725 3.14373Z" fill="white" />
                        </svg> */}
                        <i
                          className="fa-solid fa-check"
                          style={{ color: "black" }}
                        ></i>
                      </span>
                    </button>
                  ) : (
                    <button className="bg-[#FFFFFF] flex text-white px-4 py-2 my-auto rounded" onClick={handleEditClick}>
                      <span className="mt-2 mr-3">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                          <path d="M0 11.247V13.6112C0 13.8289 0.171087 14 0.388835 14H2.75295C2.85405 14 2.95514 13.9611 3.02514 13.8833L11.5173 5.39897L8.60103 2.48271L0.116651 10.9671C0.0388836 11.0449 0 11.1382 0 11.247ZM13.7725 3.14373C14.0758 2.84044 14.0758 2.35051 13.7725 2.04722L11.9528 0.227468C11.6495 -0.0758228 11.1596 -0.0758228 10.8563 0.227468L9.43313 1.6506L12.3494 4.56687L13.7725 3.14373Z" fill="black" />
                        </svg>
                      </span>
                    </button>
                  )}
                </div>{" "}
                <div
                  className="border-t-2 border-[#1d242e4d]"
                  style={{
                    // height: centerData?.images?.length > 4 ? "270px" : "160px",
                    height: "270px",
                  }}
                >
                  <div className="flex flex-row">
                    {/* <div className="flex flex-col py-5 px-7 w-1/2">
                      <p className="py-2">Contact Number</p>
                      <p className="py-2">Alternate Number</p>
                      <p className="py-2">Email Id</p>
                      <p className="py-2">Address</p>
                    </div> */}
                    {/* <div className="flex flex-col py-5 w-1/2">
                      <p className="font-bold py-2">
                        {centerData.contact_info || "None"}
                      </p>
                      <p className="font-bold py-2">
                        {centerData.contact_info || "None"}
                      </p>
                      <p className="font-bold py-2">
                        {centerData.email || "None"}
                      </p>
                      <p
                        className="font-bold pt-2 pb-1 pr-2"
                        style={{ overflowWrap: "break-word" }}
                      >
                        {centerData.address && centerData.address.length > 140
                          ? centerData.address.slice(0, 140) + "..."
                          : centerData.address}
                      </p>
                    </div> */}
                    {/* <div className="flex flex-row">
                      <div className="flex flex-col py-5 px-7 ">
                        <div className="flex flex-row">
                          <p className="py-2 mx-4">Contact Number</p>
                          <input
                            type="text"
                            name="contactNumber"
                            defaultValue={centerData.contact_info || editedContactInfo.contactNumber}
                            onChange={handleContactInfoChange}
                          />
                        </div>
                        <div className="flex flex-row">
                          <p className="py-2 mx-4">Alternate Number</p>
                          <input
                            type="text"
                            name="alternateNumber"
                            defaultValue={centerData.contact_info || editedContactInfo.alternateNumber}
                            onChange={handleContactInfoChange}
                          />
                        </div>
                        <div className="flex flex-row">
                          <p className="py-2 mx-4">Email Id</p>
                          <input
                            type="text"
                            name="email"
                            defaultValue={centerData.email || editedContactInfo.email}
                            onChange={handleContactInfoChange}
                          />
                        </div>
                        <div className="flex flex-row">
                          <p className="py-2 mx-4">Address</p>
                          <input
                            type="text"
                            name="address"
                            defaultValue={centerData.address || editedContactInfo.address}
                            onChange={handleContactInfoChange}
                          />
                        </div>
                      </div>
                    </div> */}
                    {isEditing ? (
                      <div className="flex flex-col py-5 px-7">
                        <div className="flex flex-row">
                          <p className="py-2 mx-4">Contact Number</p>
                          <input
                            type="text"
                            name="contact_info"
                            value={editedContactInfo.contact_info || centerData.contact_info}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="flex flex-row">
                          <p className="py-2 mx-4">Alternate Number</p>
                          <input
                            type="text"
                            name="alternateNumber"
                            value={editedContactInfo.alternateNumber || centerData.contact_info}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="flex flex-row">
                          <p className="py-2 mx-4">Email Id</p>
                          <input
                            type="text"
                            name="email"
                            value={editedContactInfo.email || centerData.email}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="flex flex-row">
                          <p className="py-2 mx-4">Address</p>
                          <input
                            type="text"
                            name="address"
                            value={editedContactInfo.address || centerData.address}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col py-5 px-7">
                        <p className="py-2 mx-4">Contact Number: {centerData.contact_info}</p>
                        <p className="py-2 mx-4">Alternate Number: {centerData.contact_info}</p>
                        <p className="py-2 mx-4">Email Id: {centerData.email}</p>
                        <p className="py-2 mx-4">Address: {centerData.address}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="py-4 pl-4 w-1/2 ">
              <div className="border-2 border-[#1d242e4d] rounded bg-white">
                <div className="flex justify-between">
                  <h2 className="py-3 px-6 font-bold">Gallery</h2>
                  <AddButton text={"Add Photo"} />
                </div>
                <div
                  className="border-t-2 border-[#1d242e4d] pb-14 pt-2 px-9"
                  style={{
                    // height: centerData?.images?.length > 4 ? "270px" : "160px",
                    height: "270px",
                  }}
                >
                  <div className="img-upload-table">
                    <div className="flex flex-wrap">
                      {centerData?.images?.map((item) => (
                        <ImgPhotosPost key={item.id} url={item.image} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-10 border-2  border-[#1d242e4d] rounded bg-white">
            <div className="flex  justify-between">
              <h2 className="py-3 px-4 font-bold">Activities</h2>
              {centerData?.activities_names?.map((place, index) => (
                <BlueButtonCross key={index} text={place} />
              ))}
              <div className="flex justify-end ">
                <AddButton text={"Add Activity"} />
              </div>
            </div>
          </div>
          <div className="mb-10 border-2  border-[#1d242e4d] rounded bg-white">
            <div className="flex  justify-between">
              <h2 className="py-3 px-4 font-bold">Center Tags</h2>
              {centerData?.tags_names?.map((place, index) => (
                <BlueButtonCross key={index} text={place} />
              ))}
              <div className="flex justify-end ">
                <AddButton text={"Add Tag"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
