import React, { useEffect, useState } from "react";
import AddButton from "../components/AddButton";
import BasicTable from "../sepcomponents/GuideComponent";
import { Link, useNavigate } from "react-router-dom";
import DaysDropdown from "../components/DaysDropdown";
import Search from "../components/Search";
import axios from "axios";
import PaginationNo from "../components/PaginationNo";
import CopyId from "../components/CopyId";



export default function Guideenquiry() {
    // const [guideEnquiry, setGuideEnquiry] = useState([]);
    const [filteredGuideEnquiry, setFilteredGuideEnquiry] = useState([]);
    const [Guideenquiry, setGuideenquiry] = useState();
    const [searchTerm, setSearchTerm] = useState("");
    const [pagination, setPagination] = useState({
        page: 1,
        totalPost: 0,
        postPerPage: 10, // Adjust as needed
    });

    const cities = [
        { value: 4, label: "City" },
        { value: 1, label: "New York" },
        { value: 2, label: "Los Angeles" },
        { value: 3, label: "Chicago" },
    ];
    const [selectedCity, setSelectedCity] = React.useState("City");
    const handleCityChange = (e) => {
        setSelectedCity(e.target.value);
    };
    const token = localStorage.getItem("token");
    const fetchData = async (page = 1) => {
        try {
            const response = await axios.get(
                `https://orca-app-pgxkr.ondigitalocean.app/website/guide-enquiry-list?page=${page}`,
                {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                }
            );
            // console.log(response);
            setGuideenquiry(response.data.results);
            setPagination({
                ...pagination,
                totalPost: response.data.count,
                page,

            });
        } catch (error) {
            // console.log(error);
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearchSubmit = () => {
        // Filter the data based on the search term
        const filteredData = Guideenquiry.filter((data) =>
            data.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredGuideEnquiry(filteredData);
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        // Update filtered data when the main data changes
        setFilteredGuideEnquiry(Guideenquiry);
    }, [Guideenquiry]);


    const getNextPage = () => {
        const nextPage = pagination.page + 1;
        fetchData(nextPage);
    };

    const getPrevPage = () => {
        const prevPage = pagination.page - 1;
        if (prevPage > 0) {
            fetchData(prevPage);
        }
    };

    const navigate = useNavigate();
  const toComponentB = (data) => {
    navigate("/guideenquiry/guideenquirydetails", { state: { data } });
  };

    return (
        <>
            <div className="flexbox-container pl-64 handleBack " style={{ backgroundColor: "#EDF1F5", height: "140vh" }}>
                <div className="flex flex-row justify-between mx-5 pl-5">
                    <div className="flex flex-col mt-5">
                        <h1 className="handleHeader">Guide Enquiry</h1>
                        <h4 className="handleDescription">List of all Guide Enquiry</h4>
                    </div>
                    <div className="mt-5">
                        <Link
                            to="/webenquiry/addnewweb"
                        >
                            {/* <AddButton text="Add a Web" /> */}
                        </Link>
                    </div>
                </div>
                <div className="flex justify-between mt-3 mx-4 pl-4">
                    <div className="flex ">
                        <Search
                            text="Search for Guide."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            onSearch={handleSearchSubmit}
                        />
                        <div style={{ paddingLeft: "1rem", width: "200px" }}>
                            <DaysDropdown
                                options={cities}
                                selectedValue={selectedCity}
                                onChange={handleCityChange}
                            />
                        </div>
                    </div>
                    <PaginationNo
                        getPrevPage={getPrevPage}
                        totalPost={pagination.totalPost}
                        getNextPage={getNextPage}
                        currentPage={pagination.page}
                        post_per_page={pagination.postPerPage}
                    />
                </div>
                <div className="container pt-4 px-8 ">
                    <div className=" mt-2">
                        <div className=" container text-center py-4 ">
                            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                <table className="w-full border border-[#1d242e4d] text-gray-700 bg-white shadow-lg tablefont">
                                    <thead className="text-sm text-gray-700 font-semibold">
                                        <tr>
                                            <th scope="col" className=" w-1/7 py-3 text-lg">
                                                <div className=" items-center justify-center text-center ">
                                                    Guide ID
                                                </div>
                                            </th>
                                            <th scope="col" className="w-1/7 py-3 text-lg">
                                                <div className="flex items-center justify-center ">
                                                    Photo
                                                </div>
                                            </th>
                                            <th scope="col" className="w-1/7 py-3 text-lg">
                                                <div className="flex items-center justify-center">Name</div>
                                            </th>

                                            <th scope="col" className="w-1/7 py-3 text-lg">
                                                <div className="flex items-center justify-center">
                                                    Gender
                                                </div>
                                            </th>
                                            <th scope="col" className="w-1/7 py-3 text-lg">
                                                <div className="flex items-center justify-center">City</div>
                                            </th>
                                            <th scope="col" className="w-1/7  text-lg">
                                                <div className="flex items-center justify-center">
                                                    Experience
                                                    <a href="/">
                                                        <svg
                                                            class="w-3 h-3 ml-1.5"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="8"
                                                            height="13"
                                                            viewBox="0 0 8 13"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
                                                                fill="#1D242E"
                                                            />
                                                            <path
                                                                d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
                                                                fill="#1D242E"
                                                            />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </th>
                                            <th scope="col" className="w-1/7 py-3 text-lg">
                                                <div className="flex items-center justify-center ">
                                                    Phone
                                                    <a href="/">
                                                        <svg
                                                            class="w-3 h-3 ml-1.5"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="8"
                                                            height="13"
                                                            viewBox="0 0 8 13"
                                                            fill="none"
                                                        >
                                                            <path
                                                                d="M6.77405 4.50472L3.99642 1.72709L1.21879 4.50472C0.939597 4.78391 0.488591 4.78391 0.209396 4.50472C-0.0697987 4.22552 -0.0697987 3.77451 0.209396 3.49532L3.4953 0.209414C3.7745 -0.0697813 4.2255 -0.0697813 4.5047 0.209414L7.7906 3.49532C8.0698 3.77451 8.0698 4.22552 7.7906 4.50472C7.51141 4.77675 7.05324 4.78391 6.77405 4.50472Z"
                                                                fill="#1D242E"
                                                            />
                                                            <path
                                                                d="M6.77405 8.2094L3.99642 10.987L1.21879 8.2094C0.939597 7.9302 0.488591 7.9302 0.209396 8.2094C-0.0697987 8.48859 -0.0697987 8.9396 0.209396 9.21879L3.4953 12.5047C3.7745 12.7839 4.2255 12.7839 4.5047 12.5047L7.7906 9.21879C8.0698 8.9396 8.0698 8.48859 7.7906 8.2094C7.51141 7.93736 7.05324 7.9302 6.77405 8.2094Z"
                                                                fill="#1D242E"
                                                            />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredGuideEnquiry && filteredGuideEnquiry.map((data) => {
                                            return (
                                                <tr className="border-t-2 pt-3 border-gray-300 table-text"  onClick={() => {
                                                    toComponentB(data);
                                                  }}>
                                                    <td className="pt-3">
                                                        <div className="w-1/7 flex justify-center items-center">
                                                            {/* {data.guide_enquiry_id} */}
                                                            <CopyId id={data.guide_enquiry_id} />
                                                        </div>
                                                    </td>
                                                    <Link
                                                        // to={`/guideenquiry/guideenquirydetails`}
                                                       
                                                        style={{ display: "contents" }}
                                                        className="block hover:bg-gray-100"
                                                    >
                                                        <td className="pt-3">
                                                            <div className="flex items-center text-center w-1/7 mb-2 justify-center">
                                                                <img
                                                                    src={data.image}
                                                                    alt={data.name}
                                                                    style={{
                                                                        width: '40px',
                                                                        height: '40px',
                                                                        borderRadius: '50%',
                                                                    }}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className="text-center w-1/7">
                                                            <div className="flex items-center mb-2 justify-center">
                                                                {data.name}
                                                            </div>
                                                        </td>
                                                        <td className="text-center w-1/7">
                                                            <div className="flex items-center mb-4 justify-center">
                                                                {data.gender}
                                                            </div>
                                                        </td>
                                                        <td className="text-center w-1/7">
                                                            <div className="flex items-center mb-2 justify-center">
                                                                {data.spot_city}
                                                            </div>
                                                        </td>
                                                        <td className="text-center w-1/7">
                                                            <div className="flex items-center mb-2 justify-center">
                                                                {data.year_of_experience}
                                                            </div>
                                                        </td>
                                                        <td className="text-center w-1/7">
                                                            <div className="flex items-center mb-2 justify-center">
                                                                {data.phone}
                                                            </div>
                                                        </td>
                                                    </Link>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}
