import React, { useEffect, useRef, useState } from "react";
import SampleModal from "../components/SampleModal";
import { tagsDetail } from "../../../constants/urls";

export default function TagDetail({ isModalOpen, closeModal, id }) {
  const [activityData, setActivityData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpe, setIsModalOpe] = useState(isModalOpen);
  const [name, setName] = useState("");
  const [tid, setTid] = useState("");
  const [image, setImage] = useState("");
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    const apiUrl = `${tagsDetail}${id}/`;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Token ${localStorage.getItem("token")}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(apiUrl, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);
        setIsLoading(false);
        setActivityData(data);
        setTid(data.tag_id);
        setImage(data.image);
        setName(data.name);
      })

      .catch((error) => console.log("Error:", error));
  }, [id]);

  const saveModal = (event) => {
    const bodyData = {
      name: name,
    };

    if (image !== null && image instanceof File) {
      bodyData.image = image;
    }

    const formData = new FormData();
    formData.append("tag_id", tid);
    formData.append("name", name);

    if (image !== null && image instanceof File) {
      formData.append("image", image);
      console.log("formdata");
    } else {
      bodyData.image = image;
      console.log("bodydata");
    }
    const jsonData = {
      name: name,
    };

    formData.append(
      "data",
      new Blob([JSON.stringify(jsonData)], { type: "application/json" })
    );

    fetch(`${tagsDetail}${tid}/`, {
      method: "PATCH",
      body: formData,
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Data posted:", data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setIsModalOpe(false);
    setEditing(false);
  };
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    setImage(selectedImage);
    const imgElement = document.getElementById("selectedImage");
    imgElement.src = URL.createObjectURL(selectedImage);
  };

  return (
    <div>
      <SampleModal
        isOpen={isModalOpe}
        onClose={() => setIsModalOpe(false)}
        closeModal={closeModal}
        h1="Tag Id"
        h2="Tag Name"
        h3={name}
        name={name}
        image={image}
        tid={tid}
        setImage={setImage}
        setName={setName}
        saveModal={saveModal}
        editing={editing}
        setEditing={setEditing}
        handleImageChange={handleImageChange}
      />
    </div>
  );
}
