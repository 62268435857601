import React, { useEffect, useRef } from "react";
import { useLanguageContext } from "../sepcomponents/LanguageDropdownController";
import Select from "react-select";
export default function LanguageDropdown({
  editing,
  ids,
  selectedLanguage,
  onSelect,
}) {
  const data = useLanguageContext();
  const lang = data?.data?.results || [];
  const containerRef = useRef(null);
  // const array = data?.results;
  const CustomOption = ({ innerProps, label, value }) => (
    <div {...innerProps}>
      <div className="flex py-2 px-4">
        <span className="ml-2">{label}</span>
      </div>
    </div>
  );
  const cityOptions = lang?.map((city) => ({
    value: city.language_id,
    label: city.name,
  }));
  // useEffect(() => {
  //   if (editing && lang) {
  //     const id = lang?.map((activity) => activity.id);
  //     if (id) {
  //       onSelect([...selectedLanguage, ...id]);
  //     }
  //   }
  // }, [editing, ids]);
  useEffect(() => {
    if (editing && ids) {
      const selectedActivities = ids.map((id) => ({
        value: id.language_id,
        label: id.name,
      }));
      onSelect(selectedActivities);
    }
  }, [editing, ids]);

  return (
    <div className="w-full" ref={containerRef}>
      {/* {console.log("Languages", selectedLanguage)} */}
      <div className="flex">
        <div className="flex">
          <label
            htmlFor="dropdown"
            className="block mb-2 text-md font-medium text-gray-900"
          >
            Languages
          </label>
          <i
            class="fa-solid fa-asterisk fa-xs"
            style={{
              color: "#ff0a2f",
              paddingLeft: "3px",
              fontSize: "10px",
              paddingTop: "8px",
            }}
          ></i>
        </div>
      </div>
      <Select
        id="dropdown"
        name="dropdown"
        placeholder="Languages"
        options={cityOptions}
        value={selectedLanguage.filter((tag) => ({
          value: tag.value,
          label: tag.label,
        }))}
        // onChange={(selectedOptions) => {
        //   let selectedValues = selectedLanguage?.map((option) => option);

        //   selectedValues = selectedOptions?.map((option) => option.value);

        //   onSelect(selectedValues);
        // }}
        onChange={(selectedOptions) => {
          const selectedValues = selectedOptions.map((option) => ({
            value: option.value,
            label: option.label,
          }));
          onSelect(selectedValues);
        }}
        isMulti
        styles={{
          control: (provided, state) => ({
            ...provided,
            width: "100%",
            zIndex: "50",
          }),
          multiValue: (provided) => ({
            ...provided,
            background: "#A7DBF5",
            borderRadius: "5px",
            padding: "2px",
            fontSize: "16px",
            fontWeight: "600",
          }),
          multiValueRemove: (provided, state) => ({
            ...provided,
            color: "black",
            backgroundColor: "#E5E5E5",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            marginTop: "3px",
            margin: "4px",
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            color: "black",
          }),
        }}
        components={{ Option: CustomOption }}
      />
    </div>
  );
}
