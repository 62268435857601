import React, { useState } from "react";
import AddButton from "../components/AddButton";
import Popup from "../components/Popup";

import { TagsProvider } from "../../../controllers/Tag_controller";
import TagComponent from "../sepcomponents/TagComponent";
import ActivityComponent from "../sepcomponents/ActivityComponent";
import { ActivityProvider } from "../../../controllers/Activity_controller";

export default function ActivityTag() {
  return (
    <div className="handleBack" style={{ backgroundColor: "#EDF1F5",height:"100vh" }}>
      <div className="ml-60 ">
        {/* places and add new Places  */}

        <ActivityProvider>
          <ActivityComponent />
        </ActivityProvider>
        {/* Tag section */}
        <TagsProvider>
          <TagComponent />
        </TagsProvider>
      </div>
    </div>
  );
}
