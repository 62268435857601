import axios from "axios";
import { BASE_URL } from "../../../../constants/baseUrl";

export const getVehicleTypesApi = async (data) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found in localStorage.");
    }

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    };

    const response = await axios.get(
      `${BASE_URL}/bookings/rent-vehicle-dropdown?category=${data}`,
      config
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching shops:", error);
    throw error;
  }
};
