import React from "react";
import AddButton from "../components/AddButton";
import Search from "../components/Search";
import PaginationNo from "../components/PaginationNo";
import { useNotificationContext } from "../../../controllers/Notification_controller";
import { Link } from "react-router-dom";
export default function NotificationComponent() {
  const options = [
    { value: 4, label: "Status" },
    { value: 1, label: "New York" },
    { value: 2, label: "Los Angeles" },
    { value: 3, label: "Chicago" },
  ];
  const [selectedCity, setSelectedCity] = React.useState("City");
  const data = useNotificationContext();
  const citydata = data?.state?.notification?.notification || [];
  const { state, getNextPage, getPrevPage, setSearchQuery } =
    useNotificationContext();
  const formatDate = (apiDate) => {
    const date = new Date(apiDate);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }

    // Format the date
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  return (
    <div>
      {/* {console.log(data?.state?.notification?.notification)} */}
      <div className="container pt-4 px-8">
        <div className="flex flex-row mt-2 justify-between">
          <div className="flex flex-col">
            <h1 className="handleHeader">Notifications</h1>
            <h4 className="handleDescription">Configure your Notification.</h4>
          </div>
          <div>
            <Link to={"/Notifications/addNotification"}>
              <AddButton text="Add New Notification" />
            </Link>
          </div>
        </div>

        <div className="flex mt-2 justify-between">
          <div className="flex">
            <Search text={"Search for Notifications"} />
            <div style={{ paddingLeft: "1rem", width: "160px" }}>
              {" "}
              <div className="py-2 flex flex-row">
                <div className="relative w-full">
                  <select
                    className="block appearance-none w-full bg-[#E3EBF3] border border-[#1d242e4d] text-gray-700 py-3 pl-3 pr-16 rounded leading-tight focus:outline-none  focus:border-gray-500"
                    value={selectedCity}
                    placeholder="Status"
                    // onChange={onChange}
                  >
                    {options.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-3 flex items-center pl-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="9"
                      viewBox="0 0 14 9"
                      fill="none"
                    >
                      <path
                        d="M11.8546 0.366443L6.99374 5.22729L2.13289 0.366443C1.6443 -0.122148 0.855034 -0.122148 0.366443 0.366443C-0.122148 0.855034 -0.122148 1.6443 0.366443 2.13289L6.11678 7.88322C6.60537 8.37181 7.39463 8.37181 7.88322 7.88322L13.6336 2.13289C14.1221 1.6443 14.1221 0.855034 13.6336 0.366443C13.145 -0.10962 12.3432 -0.122148 11.8546 0.366443Z"
                        fill="#707070"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PaginationNo
            post_per_page={state.post_per_page}
            getPrevPage={getPrevPage}
            totalPost={state?.totalPost}
            getNextPage={getNextPage}
            currentPage={state?.page}
          />
        </div>
        <div className="relative pt-3 overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left border border-[#1d242e4d]   bg-white shadow-lg">
            <thead className="table-heading ">
              <tr>
                <th scope="col" className="p-4 ">
                  <div className="flex items-center justify-center">Sn</div>
                </th>
                <th scope="col" className="p-4">
                  <div className="flex items-center justify-center">Title</div>
                </th>
                <th scope="col" className="p-4">
                  <div className="flex items-center justify-center">
                    Message
                  </div>
                </th>

                <th scope="col" className="p-4">
                  <div className="flex items-center justify-center">Date</div>
                </th>
              </tr>
            </thead>

            <tbody>
              {citydata.map((row) => (
                <tr className="border-t-2 table-text border-gray-200">
                  <td className="text-center   pl-2 pr-6 py-4">{row.id}</td>
                  <td className="text-center   px-4 py-4">{row.title}</td>
                  <td className="text-center px-6 py-4">{row.message}</td>

                  <td className="text-center px-6 py-4">
                    {formatDate(row.created_at)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
