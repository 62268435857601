import React, { useState, useEffect } from "react";
// import { useCityContext } from "../../../controllers/city_controllers";

function PaginationNo({
  getPrevPage,
  currentPage,
  totalPost,
  getNextPage,
  post_per_page,
}) {
  const [first, setFirst] = useState(1);
  const [second, setSecond] = useState(post_per_page); // Assuming you have 16 items per page
  const itemsPerPage = post_per_page;
  useEffect(() => {
    const newFirst = (currentPage - 1) * itemsPerPage + 1;
    const newSecond = currentPage * itemsPerPage;

    setFirst(newFirst);
    if (currentPage === Math.ceil(totalPost / itemsPerPage)) {
      setSecond(totalPost);
    } else {
      setSecond(newSecond);
    }
  }, [currentPage, itemsPerPage, totalPost]);
  const totalPage = Math.ceil(totalPost / itemsPerPage);
  return (
    <div className="border-1 bg-[#E3EBF3] border border-[#1d242e4d] rounded my-auto py-2.5 px-4">
      {/* {console.log("currentPage", totalPage)} */}
      <div className="flex">
        {first} - {second} of {totalPost}
        {currentPage === 1 ? (
          <span className="flex gap-5 my-auto ml-2 mr-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M7.64465 2.14541L2.9309 7.00626L7.64465 11.8671C8.11845 12.3557 8.11845 13.145 7.64465 13.6336C7.17084 14.1221 6.40547 14.1221 5.93166 13.6336L0.355353 7.88322C-0.118451 7.39463 -0.118451 6.60537 0.355353 6.11678L5.93166 0.366444C6.40547 -0.122147 7.17084 -0.122148 7.64465 0.366444C8.1063 0.855034 8.11845 1.65682 7.64465 2.14541Z"
                fill="#A2A2A2"
              />
            </svg>
          </span>
        ) : (
          // Render the enabled button
          <button className="flex my-auto" onClick={() => getPrevPage()}>
            <span className="flex gap-5 my-auto ml-2 mr-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="14"
                viewBox="0 0 9 14"
                fill="none"
                transform="rotate(180)"
              >
                <path
                  d="M0.399772 11.8546L5.70273 6.99374L0.399772 2.13289C-0.133257 1.6443 -0.133257 0.855034 0.399772 0.366443C0.932802 -0.122148 1.79385 -0.122148 2.32688 0.366443L8.60023 6.11678C9.13326 6.60537 9.13326 7.39463 8.60023 7.88322L2.32688 13.6336C1.79385 14.1221 0.932801 14.1221 0.399772 13.6336C-0.119591 13.145 -0.133258 12.3432 0.399772 11.8546Z"
                  fill="#1D242E"
                />
              </svg>
            </span>
          </button>
        )}
        {/* <div className="flex my-auto"> */}
        {console.log(currentPage, totalPage)}
        {currentPage < totalPage ? (
          <button onClick={() => getNextPage()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="14"
              viewBox="0 0 9 14"
              fill="none"
            >
              <path
                d="M0.399772 11.8546L5.70273 6.99374L0.399772 2.13289C-0.133257 1.6443 -0.133257 0.855034 0.399772 0.366443C0.932802 -0.122148 1.79385 -0.122148 2.32688 0.366443L8.60023 6.11678C9.13326 6.60537 9.13326 7.39463 8.60023 7.88322L2.32688 13.6336C1.79385 14.1221 0.932801 14.1221 0.399772 13.6336C-0.119591 13.145 -0.133258 12.3432 0.399772 11.8546Z"
                fill="#1D242E"
              />
            </svg>
          </button>
        ) : (
          <span className="flex gap-5 my-auto ml-1 mr-1">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
              transform="rotate(180)"
            >
              <path
                d="M7.64465 2.14541L2.9309 7.00626L7.64465 11.8671C8.11845 12.3557 8.11845 13.145 7.64465 13.6336C7.17084 14.1221 6.40547 14.1221 5.93166 13.6336L0.355353 7.88322C-0.118451 7.39463 -0.118451 6.60537 0.355353 6.11678L5.93166 0.366444C6.40547 -0.122147 7.17084 -0.122148 7.64465 0.366444C8.1063 0.855034 8.11845 1.65682 7.64465 2.14541Z"
                fill="#A2A2A2"
              />
            </svg>
          </span>
        )}
        {/* {disabled={currentPage > totalPage? 
            (
          // Render the disabled button icon
          <span className="flex gap-5 my-auto ml-2 mr-3">
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M7.64465 2.14541L2.9309 7.00626L7.64465 11.8671C8.11845 12.3557 8.11845 13.145 7.64465 13.6336C7.17084 14.1221 6.40547 14.1221 5.93166 13.6336L0.355353 7.88322C-0.118451 7.39463 -0.118451 6.60537 0.355353 6.11678L5.93166 0.366444C6.40547 -0.122147 7.17084 -0.122148 7.64465 0.366444C8.1063 0.855034 8.11845 1.65682 7.64465 2.14541Z"
                fill="#A2A2A2"
              />
            </svg>
          </span></div>)}
           
         : */}
        {/* <button
          onClick={() => getNextPage()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="14"
            viewBox="0 0 9 14"
            fill="none"
          >
            <path
              d="M0.399772 11.8546L5.70273 6.99374L0.399772 2.13289C-0.133257 1.6443 -0.133257 0.855034 0.399772 0.366443C0.932802 -0.122148 1.79385 -0.122148 2.32688 0.366443L8.60023 6.11678C9.13326 6.60537 9.13326 7.39463 8.60023 7.88322L2.32688 13.6336C1.79385 14.1221 0.932801 14.1221 0.399772 13.6336C-0.119591 13.145 -0.133258 12.3432 0.399772 11.8546Z"
              fill="#1D242E"
            />
          </svg>
        </button> */}
        {/* ) */}
      </div>
    </div>
  );
}

export default PaginationNo;
