import React from "react";

export default function Dropdown({
  text,
  array,
  value,
  onChange,
  placeholder,
}) {
  // Check if the array is defined and not null
  const hasOptions = Array.isArray(array) && array.length > 0;

  return (
    <div className="mb-4 mx-6 w-2/3">
      <div className="flex">
        <div>
          <label
            htmlFor="dropdown"
            className="block mb-2 text-sm font-medium text-gray-900"
          >
            {text}
          </label>
        </div>
        <i
          class="fa-solid fa-asterisk fa-xs"
          style={{
            color: "#ff0a2f",
            paddingLeft: "3px",
            fontSize: "10px",
            paddingTop: "8px",
          }}
        ></i>
      </div>
      <select
        id="dropdown"
        name="dropdown"
        className="bg-white-200 border text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 border-[#1d242e4d] placeholder-gray-200"
        value={value}
        onChange={onChange}
        required
      >
        {!hasOptions && ( // Check if there are no options
          <option value="" disabled>
            {placeholder || "No options available"}
          </option>
        )}
        {hasOptions &&
          array.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
      </select>
    </div>
  );
}
