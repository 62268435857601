import React from "react";
import { useState } from "react";
import ActButton from "./ActButton";

export default function ImgActivity({
  key,
  image,
  title,
  activites,
  subtitle,
}) {
  const [imageSrc, setImageSrc] = useState(image);

  const handleImageError = () => {
    setImageSrc(
      "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ="
    );
  };
  return (
    <div>
      <div className="activitycom m-4 relative">
        <img
          className="activityimg rounded-t-20"
          src={
            imageSrc ||
            "https://media.cntraveler.com/photos/57d87670fd86274a1db91acd/16:9/w_2580,c_limit/most-beautiful-paris-pont-alexandre-iii-GettyImages-574883771.jpg"
          }
          onError={handleImageError}
          alt="img"
        />

        <div className="absolute top-0 left-0 w-full h-full rounded-t-20"></div>
        <div className="relative bg-white">
          <span className="activitytitle z-10 relative">{title}</span>
          <p className="activitysub relative z-10">{subtitle}</p>
          <div className="flex pl-1 relative z-10">
            <ActButton text={activites} />
          </div>
        </div>
      </div>
    </div>
  );
}
//
