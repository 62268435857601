import React from "react";

import { NotificationProvider } from "../../../controllers/Notification_controller";
import NotificationComponent from "../sepcomponents/NotificationComponent";

function Notifications() {
  return (
    <div className="flexbox-container pl-64 handleBack" style={{ backgroundColor: "#EDF1F5",height:"100vh" }}>
      <NotificationProvider>
        <NotificationComponent />
      </NotificationProvider>
    </div>
  );
}

export default Notifications;
