import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useCityContext } from "../../../controllers/city_controllers";
import { useShopContext } from "../../../controllers/shop_controller";
import { CityDropdownn } from "../../../constants/urls";
export default function CityDropdown({
  cityValue,
  setCityId,
  onChange,
  style,
  error,
  cityName,
  setValidationErrors,
  validationErrors,
}) {
  const cityData = useCityContext();
  const { setSearchQuery } = useCityContext();
  const cities = cityData?.state?.cities?.cities || [];

  const [selectedValue, setSelectedValue] = useState(null);
  const [data, setData] = useState([]);
  const defaultCity = cities.find(
    (city) => city.city_id === cityValue?.city_id
  );
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#3182ce" : "white",
      color: state.isSelected ? "white" : "black",
    }),
  };
  const CustomOption = ({ innerProps, label, image }) => (
    <div {...innerProps}>
      <div className="flex py-2 px-4">
        <div className="flex items-center justify-center h-full">
          <img
            className="h-6 w-6 rounded-full   "
            src={
              image ||
              "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ="
            }
            alt={label}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ=";
            }}
          />
        </div>
        <span className="ml-2">{label}</span>
      </div>
    </div>
  );

  useEffect(() => {
    if (selectedValue) {
      const apiUrl = `${CityDropdownn}`;

      async function fetchInfo() {
        try {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append(
            "Authorization",
            `Token ${localStorage.getItem("token")}`
          );
          var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
          };

          const response = await fetch(apiUrl, requestOptions);
          const result = await response.json();
          // console.log(result);
          if (response.ok) {
            const resultData = result?.results;

            setData(resultData);
            console.log("data", data);
            console.log(selectedValue);
          } else {
            console.log(`Error: ${result.error}`);
          }
        } catch (error) {
          console.error(`Error: ${error}`);
        }
      }

      fetchInfo();
    }
  }, [selectedValue]);
  const cityOptions = cities?.map((city) => ({
    value: city.city_id,
    label: city.name,
    image:
      city.image ||
      "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ=",
  }));

  const handleCitySelection = (selectedOption) => {
    setSelectedValue(selectedOption.value);
    setCityId(selectedOption.value);
    setValidationErrors({ ...validationErrors, cityName: "" });
  };

  return (
    <div className="flex flex-row">
      <div className={`flex mt-2 w-full ${style === "stay" ? "pl-0" : "pl-6"}`}>
        {/* <div className="w-1/2">
          <TextBox
            text="City Id"
            placeholder="City Id"
            type="text"
            htmlfor="City Id"
            value={selectedValue}
            onChange={setCityId}
          />
        </div> */}
        <div className={`${style === "stay" ? "w-full" : "w-1/2"}`}>
          <div
            className={`mb-4 ${
              style === "stay" ? "mx-0 w-full" : "mx-6 w-2/3"
            }`}
          >
            {/* {console.log(cityOptions)} */}
            <div className="flex">
              <div className="flex ">
                <label
                  htmlFor="dropdown"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  City Name
                </label>
                <i
                  class="fa-solid fa-asterisk fa-xs"
                  style={{
                    color: "#ff0a2f",
                    paddingLeft: "3px",
                    fontSize: "10px",
                    paddingTop: "8px",
                  }}
                ></i>
              </div>
            </div>
            {/* <Search setSearchQuery={setSearchQuery} /> */}
            <Select
              id="dropdown"
              name="dropdown"
              options={cityOptions}
              defaultValue={
                defaultCity
                  ? {
                      value: defaultCity.city_id,
                      label: defaultCity.name,
                    }
                  : null
              }
              value={cityOptions.find(
                (option) => option.value === selectedValue
              )}
              onInputChange={(inputValue) => {
                setSearchQuery(inputValue);
              }}
              onChange={handleCitySelection}
              isSearchable
              styles={customStyles}
              components={{ Option: CustomOption }}
            />
            {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
          </div>
        </div>
      </div>
    </div>
  );
}
