import React from "react";
import { Link } from "react-router-dom";
import { BookingProvider } from "../../../controllers/Booking_controllers";
import BookingTableComponent from "../components/BookingTableComponent";
import SampleModal from "../components/SampleModal";
function Booking() {
  return (
    <div className="bg-[#EDF1F5] ml-64 container-new h-full" style={{ backgroundColor: "#EDF1F5",height:"100vh" }}>
      <div className="container mx-auto px-8 py-4">
        <BookingProvider>
          <BookingTableComponent />
        </BookingProvider>
      </div>
    </div>
  );
}

export default Booking;
