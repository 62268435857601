import React, { useState } from "react";
import Modal from "./Modal";

export default function SampleModal({
  isOpen,
  onClose,
  id,
  h1,
  h2,
  h3,
  name,
  image,
  tid,
  closeModal,
  setTid,
  setImage,
  setName,
  handleImageChange,
  editModal,
  saveModal,
  editing,
  setEditing,
}) {
  const handleEditClick = () => {
    setEditing(true);
  };
  const handleSaveAndClose = () => {
    closeModal(); // Call the closeModal function
    saveModal(); // Call the saveModal function
  };
  return (
    <div className="w-1/2">
      <Modal isOpen={isOpen} onClose={onClose}>
        <div className="">
          <div className="flex justify-between">
            <h1 style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
              {h3 || ""}
            </h1>
            {/* <i className="fa-solid fa-pen-to-square" onClick={editModal}></i> */}
            {editing ? (
              <></>
            ) : (
              <i
                className="fa-solid fa-pen-to-square"
                onClick={handleEditClick}
              ></i>
            )}
          </div>
          <hr className="my-4" />
          <div className="flex pr-6">
            {editing ? (
              <div className="w-1/3 flex justify-center items-center">
                <label>
                  <input
                    type="file"
                    name="image"
                    accept="image/*"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />

                  <img
                    id="selectedImage"
                    className="h-24 w-24 rounded-full  my-3 ring-1 ring-[#BBBDC0] ring-offset-1"
                    // src={image || URL.createObjectURL(image)}
                    src={image}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src =
                        URL.createObjectURL(image) ||
                        "https://www.intrepidtravel.com/adventures/wp-content/uploads/2015/02/Intrepid-Travel-Chile-Patagonia-trekking-002.jpg";
                    }}
                    alt="act"
                  />
                </label>
              </div>
            ) : (
              <div className="w-1/3 flex justify-center items-center">
                <img
                  id="selectedImage"
                  className="h-24 w-24 rounded-full  my-3 ring-1 ring-[#BBBDC0] ring-offset-1"
                  src={
                    image ||
                    "https://www.intrepidtravel.com/adventures/wp-content/uploads/2015/02/Intrepid-Travel-Chile-Patagonia-trekking-002.jpg"
                  }
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src =
                      "https://www.intrepidtravel.com/adventures/wp-content/uploads/2015/02/Intrepid-Travel-Chile-Patagonia-trekking-002.jpg";
                  }}
                  alt="act"
                />
              </div>
            )}
            <div className="inputFeild px-2 ml-4 w-1/2">
              <p for={"Activity Id"} className="font-semibold">
                {h1}
              </p>
              {editing ? (
                <input
                  className="textbox pr-4"
                  type="text"
                  value={tid}
                  disabled
                />
              ) : (
                <p>{tid}</p>
              )}

              <p for={"Activity name"} className="font-semibold">
                {h2}
              </p>
              {editing ? (
                <input
                  type="text"
                  className="textbox mr-8"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              ) : (
                <p>{name}</p>
              )}
            </div>
          </div>
          <div className="flex  bottomButtom">
            <button
              type="button"
              className="px-2 btn cancel"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              type="button"
              className="px-2 btn save"
              onClick={handleSaveAndClose}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
