import React from "react";

export default function Bio({ heading, text, placeholder, value, onChange }) {
  return (
    <div className=" py-4 ">
      <div className="border-2  border-[#1d242e4d] rounded bg-white">
        <h2 className="py-3 px-6 font-bold">{heading}</h2>
        <div className="pl-2 border-t-2 border-[#1d242e4d]">
          <div className="mb-4 mx-6 ">
            <div class=" pr-36">
              <label
                for={text}
                class="block mb-2 text-sm font-medium text-gray-900 "
              >
                {text}
              </label>
              <textarea
                id=""
                value={value}
                onChange={onChange}
                class=" bg-white-200 border-2 font-poppins text-gray-900 text-md rounded-lg block w-full h-28 border-[#1d242e4d] placeholder-gray-500 pl-3 pt-2 mt-1 resize-none"
                placeholder={placeholder}
                required
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
