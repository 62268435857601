import React from "react";

export default function ThreeInfoBox({
  heading,
  ftop,
  fbottom,
  stop,
  sbottom,
  ttop,
  tbottom,
}) {
  return (
    <div className=" py-4 pr-6">
      <div className="border-2  border-[#1d242e4d] rounded bg-white">
        <h2 className="py-3 px-4 font-bold">{heading}</h2>
        <div className="pl-2 border-t-2 border-[#1d242e4d]">
          <div className="flex flex-row ">
            <div className="flex flex-col pl-4 w-1/2 py-5">
              <h1 className="handleBoxTop h-1/2">{ftop}</h1>
              <p className="handleBoxPara h-1/2">{fbottom}</p>
            </div>
            <div className="flex flex-col px-20 w-1/2 py-5">
              <h1 className="handleBoxTop">{stop}</h1>
              <p className="handleBoxPara">{sbottom}</p>
            </div>
            <div className="flex flex-col px-20  w-1/2 py-5">
              <h1 className="handleBoxTop">{ttop}</h1>
              <p className="handleBoxPara">{tbottom}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
