import React, { useState } from "react";

function DaysDropdown({ options, selectedValue, onChange }) {
  return (
    <div>
      <div className="py-2 flex flex-row">
        <div className="relative w-full">
          <div className="relative ">
            <div className="pointer-events-none pt-1 absolute inset-y-0 left-0 flex items-center pl-2 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="15"
                viewBox="0 0 20 16"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M13.7788 1.83376C9.80048 1.38912 5.78504 1.38912 1.80676 1.83376C1.74577 1.84075 1.68777 1.86398 1.63882 1.90103C1.58987 1.93808 1.55177 1.98759 1.52847 2.04439C1.50518 2.10119 1.49756 2.1632 1.50641 2.22395C1.51525 2.2847 1.54024 2.34196 1.57876 2.38976L5.09576 6.73776C6.35552 8.29515 7.04279 10.2376 7.04276 12.2408V15.1298L8.54276 16.2298V12.2398C8.54296 10.237 9.23021 8.29489 10.4898 6.73776L14.0068 2.38976C14.0453 2.34196 14.0703 2.2847 14.0791 2.22395C14.088 2.1632 14.0803 2.10119 14.057 2.04439C14.0338 1.98759 13.9956 1.93808 13.9467 1.90103C13.8977 1.86398 13.8398 1.84075 13.7788 1.83376ZM1.64076 0.342758C5.7297 -0.114253 9.85682 -0.114253 13.9458 0.342758C15.3918 0.504758 16.0888 2.20076 15.1738 3.33276L11.6558 7.68076C10.612 8.9713 10.0426 10.5809 10.0428 12.2408V17.7098C10.0429 17.8485 10.0045 17.9845 9.93194 18.1027C9.85939 18.2209 9.75547 18.3166 9.63175 18.3793C9.50803 18.442 9.36935 18.4692 9.23113 18.4578C9.0929 18.4464 8.96054 18.3969 8.84876 18.3148L5.84876 16.1148C5.75383 16.045 5.67666 15.9539 5.6235 15.8488C5.57033 15.7437 5.54268 15.6275 5.54276 15.5098V12.2398C5.54276 10.5798 4.97276 8.97076 3.92976 7.67976L0.412761 3.33376C-0.503239 2.20176 0.192761 0.504758 1.64076 0.342758Z"
                  fill="#646464"
                />
              </svg>
            </div>
            <select
              className="block appearance-none w-full bg-[#E3EBF3] border border-[#1d242e4d] text-gray-700 py-3 pl-6 pr-6 rounded leading-tight focus:outline-none  focus:border-gray-500"
              value={selectedValue}
              onChange={onChange}
            >
              {options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-3 pt-1 flex items-center pl-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="9"
                viewBox="0 0 14 9"
                fill="none"
              >
                <path
                  d="M11.8546 0.366443L6.99374 5.22729L2.13289 0.366443C1.6443 -0.122148 0.855034 -0.122148 0.366443 0.366443C-0.122148 0.855034 -0.122148 1.6443 0.366443 2.13289L6.11678 7.88322C6.60537 8.37181 7.39463 8.37181 7.88322 7.88322L13.6336 2.13289C14.1221 1.6443 14.1221 0.855034 13.6336 0.366443C13.145 -0.10962 12.3432 -0.122148 11.8546 0.366443Z"
                  fill="#707070"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DaysDropdown;
