import React, { useEffect, useState } from "react";
import AddButton from "../components/AddButton";
import TotalCityComponent from "../components/TotalCityComponent";
import TotalPlaceComponent from "../components/TotalPlaceComponent";
import { Link } from "react-router-dom";
import {
  CityProvider,
  LoadingProvider,
  PlaceProvider,
} from "../../../controllers/city_controllers";
// import { useCityContext } from "../../../controllers/city_controllers";
import CityComponent from "../sepcomponents/CityComponent";
import PaginationNo from "../components/PaginationNo";
import { PlaceListProvider } from "../../../controllers/PlaceList_controller";
export default function Places() {
  function openForm() {
    document.getElementById("myForm").style.display = "block";
  }
  // const totalCities = useCityContext();
  // const totalPages = totalCities.recentCities.total_Cities;
  return (
    <>
      <CityProvider>
        <PlaceProvider>
          <PlaceListProvider>
            <div className="bg-[#EDF1F5] ml-64 " style={{ backgroundColor: "#EDF1F5",height:"100vh" }}>
              {/* heading Content  */}
              <div className="container py-4 px-8">
                <div>
                  <div className="flex justify-between">
                    <div className="px-2 pb-4">
                      <h1 className="font-bold handleHeader text-3xl">
                        Places
                      </h1>
                      <span className="text-md">
                        List of total Places available
                      </span>
                    </div>

                    <Link to="/places/addNewPlaces">
                      <AddButton text="Add New Place" />
                    </Link>
                  </div>
                </div>

                {/* colored Container  */}
                <div className="mt-2 ">
                  <div className="grid grid-cols-4 gap-10 mx-2 ">
                    <TotalPlaceComponent />
                    <TotalCityComponent />
                  </div>
                </div>

                {/* City and Add City button  */}
                <div className=" ">
                  <div className="flex justify-between pt-6">
                    <div className="px-2 ">
                      <h1 className="handleHeader">Cities</h1>
                      <span className="text-md">List of all cities</span>
                    </div>
                    <div className="pt-4">
                      <Link to="/places/addCity">
                        <AddButton text="Add Cities" />
                      </Link>
                    </div>
                  </div>
                </div>
                <CityComponent />
              </div>
            </div>
          </PlaceListProvider>
        </PlaceProvider>
      </CityProvider>
    </>
  );
}

{
  /* Search and Pagination  */
}
{
  /* <div className="  flex justify-between pt-2">
                <div className="">
                  <form
                    action="#"
                    method="GET"
                    className="hidden lg:block lg:pl-2"
                  >
                    <label for="topbar-search" className="sr-only">
                      Search
                    </label>
                    <div className="relative mt-1 lg:w-96">
                      <div className="flex absolute inset-y-0 right-0 items-center pr-3 pointer-events-none">
                        <svg
                          className="w-5 h-5 text-gray-500 dark:text-gray-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <input
                        type="text"
                        name="email"
                        id="topbar-search"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5  focus:ring-primary-500 focus:border-primary-500"
                        placeholder="Search for City..."
                      />
                    </div>
                  </form>
                </div>
                <div className="">
                  <PaginationNo
                  // totalPages={totalCities.recentCities.total_Cities}
                  // totalPages={}
                  />
                </div>
              </div> */
}
