import React from "react";
// import { useCityContext } from "../../../controllers/city_controllers";
export default function Search({ text, setSearchQuery }) {
  // const { setSearchQuery } = useCityContext();
  return (
    <>
      <form
        action="#"
        method="GET"
        className="hidden lg:block lg:pl-1"
        onSubmit={(e) => e.preventDefault()}
      >
        <label for="search" className="sr-only">
          Search
        </label>
        <div className="relative mt-2 lg:w-96">
          <input
            type="text"
            name={text}
            id={text}
            className="block appearance-none w-full bg-[#E3EBF3] border border-[#1d242e4d] text-gray-700 py-3 pl-4 pr-16 rounded leading-tight focus:outline-none  focus:border-gray-500"
            placeholder={text}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <div className="flex absolute inset-y-0 right-3 items-center pl-1 pointer-events-none">
            <svg
              className="w-5 h-5 text-gray-800 dark:text-black-600"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
      </form>
    </>
  );
}
