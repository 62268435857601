import { useActivityContext } from "../../../controllers/Activity_controller";
import PaginationNo from "../components/PaginationNo";
import Search from "../components/Search";
import AddButton from "../components/AddButton";
import Popup from "../components/Popup";  
import ActivityTagBox from "../components/ActivityTagBox";
import { useState } from "react";
import ActivityDetail from "../pages/ActivityDetail";
import { AddActivities } from "../../../constants/urls";
export default function ActivityComponent() {
  function openForm() {
    document.getElementById("myForm").style.display = "block";
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedActivityId, setSelectedActivityId] = useState(null);
  const openModal = (id) => {
    setSelectedActivityId(id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const { state, getNextPage, getPrevPage, setSearchQuery } =
    useActivityContext();

  let activityData = state?.activity?.activity || [];

  const handleFormSubmit2 = (formData) => {
    fetch(`${AddActivities}`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Data posted:", data);
        console.log("formdata", formData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="container pt-4 px-11">
      <div className="">
        <div className="flex justify-between">
          <div className="ml-1">
            <h1 className="handleHeader">Activity</h1>
            <span className="handleDescription">List of all Activities.</span>
          </div>
          <div>
            <AddButton text="Add Activity" onClick={openForm} />
          </div>
        </div>
        <Popup
          onFormSubmit={handleFormSubmit2}
          text="Add New Activity"
          firsttext="Activity Id"
          secondtext="Activity Name"
        />
      </div>
      <div className="">
        <div className="flex justify-between pb-4">
          <Search
            text="Search for Activity.."
            setSearchQuery={setSearchQuery}
          />
          <PaginationNo
            post_per_page={state.post_per_page}
            getPrevPage={getPrevPage}
            totalPost={state.totalPost}
            getNextPage={getNextPage}
            currentPage={state.page}
          />
        </div>
        {/* {console.log("totalPost", state)}; */}
        {state.isloading ? (
          <div className="text-center">Loading...</div>
        ) : (
          <div>
            <ul className="grid gap-x-6 gap-y-8 sm:grid-cols-4 sm:gap-y-4 xl:col-span-2 ml-1">
              {/* {console.log(state.totalPost)} */}
              {activityData.map((person) => (
                <li>
                  {/* <Link to={`/activityandtags/${person.activity_id}`}> */}
                  <ActivityTagBox
                    key={person.activity_id}
                    image={person.image}
                    name={person.name}
                    total_places={person.total_places}
                    id={person.activity_id}
                    openModal={openModal}
                  />
                </li>
              ))}
            </ul>
            {isModalOpen && (
              <ActivityDetail
                id={selectedActivityId}
                isModalOpen={isModalOpen}
                closeModal={closeModal}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
