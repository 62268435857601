import { useLocation } from "react-router-dom";
import { PlaceList } from "../constants/urls";
import React, { createContext, useContext, useEffect, useReducer, useState } from "react";

const PlaceListContext = createContext();

const initialState = {
  toggleButton: false,
  places: [],
  query: "",
  isloading: true,
  totalPost: 0,
  state_id: "",
  page: 1,
  post_per_page: 8,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_PLACES":
      return {
        ...state,
        places: action.payload,
        totalPost: action.payload.totalPost,
        isloading: false,
        post_per_page: 8,
      };
    case "SET_STATE_ID":
      return { ...state, state_id: action.payload };
    case "IS_ENABLED":
      return { ...state, is_enabled: action.payload };
    case "SET_QUERY":
      return { ...state, query: action.payload };
    case "SET_LOADING":
      return { ...state, isloading: true };

    case "NEXT":
      let pageNumInc = state.page + 1;

      console.log("state", state.page, state.totalPost);
      if (pageNumInc > Math.ceil(state.totalPost / state.post_per_page)) {
        pageNumInc = 1;
      }
      return { ...state, page: pageNumInc };
    case "PREV":
      let pageNum = state.page - 1;
      console.log("state", state.page, state.totalPost);
      if (pageNum <= 1) {
        pageNum = 1;
      }
      return { ...state, page: pageNum };
    case "SET_PAGE":
      return { ...state, page: action.payload };
    default:
      return state;
  }
}

export function PlaceListProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const location = useLocation();
  useEffect(() => {
    async function fetchInfo() {
      const urlSearchParams = new URLSearchParams(location.search);
      const pageFromUrl = parseInt(urlSearchParams.get('page')) || 1;
      dispatch({ type: "SET_LOADING" });
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          `Token ${localStorage.getItem("token")}`
        );
        const queryParams = new URLSearchParams();
        queryParams.append("page", state.page);
        queryParams.append("name", state.query);
        queryParams.append("state_id", state.state_id);
        queryParams.append("is_enabled", !state.is_enabled);
        
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const apiUrlWithQuery = `${PlaceList}?${queryParams.toString()}&page_size=${state.post_per_page
          }&state_id=${state.state_id}`;
        const response = await fetch(apiUrlWithQuery, requestOptions);
        const result = await response.json();
        if (response.ok) {

          dispatch({
            type: "SET_PLACES",
            payload: {
              places: result.results,
              totalPost: result.count,
            },
          });
          setPage(pageFromUrl);
        } else {
          console.log(`Error: ${result.error}`);
        }
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    }
    fetchInfo();
  }, [state.query, state.state_id, state.page, state.is_enabled]);

  const setSearchQuery = (searchQuery) => {
    dispatch({ type: "SET_QUERY", payload: searchQuery });
  };
  const setIsEnabled = (isEnabled) => {
    dispatch({ type: "IS_ENABLED", payload: isEnabled });
  };
  const setStateId = (stateId) => {
    dispatch({ type: "SET_STATE_ID", payload: stateId });
  };
  const getNextPage = () => {
    dispatch({ type: "NEXT" });
  };
  const getPrevPage = () => {
    dispatch({ type: "PREV" });
  };
  const setPage = (newPage) => {
    dispatch({ type: "SET_PAGE", payload: newPage });
  };
  return (
    <PlaceListContext.Provider
      value={{ state, setSearchQuery, setStateId, getNextPage, getPrevPage, setPage, setIsEnabled }}
    >
      {children}
    </PlaceListContext.Provider>
  );
}
export function usePlaceListContext() {
  return useContext(PlaceListContext);
}
