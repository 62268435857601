import { AmenitiesTags, FacilityTags, placeTags } from "../constants/urls";
import React, { createContext, useReducer, useContext, useEffect } from "react";

const facilityTagsContext = createContext();

const initialState = {
  tag: [],
  query: "",
  isloading: true,
  totalPost: 0,
  page: 1,
  post_per_page: 8,
};

function reducer(state, action) {
  switch (action.type) {
    case "SET_TAG":
      return {
        ...state,
        tag: action.payload,
        post_per_page: 8,
        totalPost: action.payload.totalPost,
        isloading: false,
      };
    case "SET_QUERY":
      return { ...state, query: action.payload };
    case "SET_LOADING":
      return { ...state, isloading: true };

    case "NEXT":
      let pageNumInc = state.page + 1;
      if (pageNumInc > Math.ceil(state.totalPost / state.post_per_page)) {
        pageNumInc = 1;
      }
      return { ...state, page: pageNumInc };
    case "PREV":
      let pageNum = state.page - 1;
      if (pageNum <= 1) {
        pageNum = 1;
      }
      return { ...state, page: pageNum };
    default:
      return state;
  }
}

export function FacilityTagsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    async function fetchInfo() {
      dispatch({ type: "SET_LOADING" });
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          `Token ${localStorage.getItem("token")}`
        );
        const queryParams = new URLSearchParams();
        queryParams.append("page", state.page);
        queryParams.append("name", state.query);
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const apiUrlWithQuery = `${FacilityTags}?${queryParams.toString()}&page_size=${
          state.post_per_page
        }`;
        const response = await fetch(apiUrlWithQuery, requestOptions);
        const result = await response.json();

        if (response.ok) {
          // console.log("tag", result);
          dispatch({
            type: "SET_TAG",
            payload: {
              tag: result.results,
              totalPost: result.count,
            },
          });
        } else {
          console.log(`Error: ${result.error}`);
        }
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    }

    fetchInfo();
  }, [state.query, state.page]);

  const setSearchQuery = (searchQuery) => {
    dispatch({ type: "SET_QUERY", payload: searchQuery });
  };
  const getNextPage = () => {
    dispatch({ type: "NEXT" });
  };
  const getPrevPage = () => {
    dispatch({ type: "PREV" });
  };
  return (
    <facilityTagsContext.Provider
      value={{
        state,
        setSearchQuery,
        getNextPage,
        getPrevPage,
      }}
    >
      {children}
    </facilityTagsContext.Provider>
  );
}
export function useFacilityTagsContext() {
  return useContext(facilityTagsContext);
}
