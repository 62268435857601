import React, { useEffect } from "react";
import { useCountryContext } from "../../../controllers/CountryDropdown_controller";
import Select from "react-select";

export default function CountryDropdown({ setCountry }) {
  const countryData = useCountryContext();
  const { setSearchQuery } = useCountryContext();
  const data = countryData?.state?.country || [];
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#3182ce" : "white",
      color: state.isSelected ? "white" : "black",
    }),
  };
  const CustomOption = ({ innerProps, label, image }) => (
    <div {...innerProps}>
      <div className="flex py-2 px-4">
        <div className="flex items-center justify-center h-full">
          <img
            className="h-6 w-6 rounded-full   "
            src={
              image ||
              "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ="
            }
            alt={label}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ=";
            }}
          />
        </div>
        <span className="ml-2">{label}</span>
      </div>
    </div>
  );

  const cityOptions =
    data && data.country
      ? data.country.map((city) => ({
          value: city.country_id,
          label: city.name,
          image:
            city.image ||
            "https://media.istockphoto.com/id/1325342016/photo/mumbai-skyline-wadala-sewri-lalbaug.jpg?s=612x612&w=0&k=20&c=Zu7P_lttSwIU5rLJFdPmhXW4Hy-C64bAhxA7V28LvJQ=",
        }))
      : [];

  const handleCitySelection = (selectedOption) => {
    setCountry(selectedOption.label);
    // console.log(selectedOption.value);
  };
  return (
    <div>
      {/* {console.log(countryData.state.country.country)} */}
      <div className="">
        <div className="mb-4 mx-6">
          {/* {console.log(cityOptions)} */}
          <div className="flex">
            <div className="flex ">
              <label
                htmlFor="dropdown"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Country
              </label>
              <i
                class="fa-solid fa-asterisk fa-xs"
                style={{
                  color: "#ff0a2f",
                  paddingLeft: "3px",
                  fontSize: "10px",
                  paddingTop: "8px",
                }}
              ></i>
            </div>
          </div>
          {/* <Search setSearchQuery={setSearchQuery} /> */}
          <Select
            id="dropdown"
            name="dropdown"
            options={cityOptions}
            // value={cityOptions.find((option) => option.value === selectedValue)}
            onInputChange={(inputValue) => {
              setSearchQuery(inputValue);
            }}
            onChange={handleCitySelection}
            isSearchable
            styles={customStyles}
            components={{ Option: CustomOption }}
          />
        </div>
      </div>
    </div>
  );
}
