import React, { useEffect, useRef, useState, useCallback } from "react";
import AddButton from "../components/AddButton";
import BasicTable from "../sepcomponents/GuideComponent";
import { Link } from "react-router-dom";
import { getshopgear, stayurl } from "../../../constants/urls";
import axios from "axios";
import CopyId from "../components/CopyId";
import { debounce } from "lodash";
import PaginationNo from "../components/PaginationNo";
import Loading from "../components/Loading";

export default function Shopgear() {
  const inputRef = useRef(null);
  const [shopgeardata, setshopgeardata] = useState();
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    page: 1,
    totalPost: 0,
    postPerPage: 10,
  });
  const [searchTerm, setSearchTerm] = useState("");

  const fetchData = async (page = 1) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://orca-app-pgxkr.ondigitalocean.app/bookings/search?name=${searchTerm}&page=${page}&model=ShopGear  `,
        {
          headers: {
            Authorization: `Token ${localStorage.getItem("token")}`,
          },
        }
      );
      setshopgeardata(response.data.results);
      setPagination({
        ...pagination,
        totalPost: response.data.count,
        page,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [shopgeardata]);

  const handleSearchChange = useCallback(
    debounce(async (newSearchTerm) => {
      await fetchData(1, newSearchTerm);
      setSearchTerm(newSearchTerm);
    }, 100),
    [fetchData]
  );

  const getNextPage = () => {
    const nextPage = pagination.page + 1;
    fetchData(nextPage);
  };

  const getPrevPage = () => {
    const prevPage = pagination.page - 1;
    if (prevPage > 0) {
      fetchData(prevPage);
    }
  };

  return (
    <div
      className="flexbox-container pl-64 handleBack"
      style={{ backgroundColor: "#EDF1F5", height: "100vh" }}
    >
      <div className="container pt-4 px-8">
        <div className="flex flex-row mt-2 justify-between">
          <div className="flex flex-col">
            <h1 className="handleHeader">Shop Gear</h1>
            <h4 className="handleDescription">List of all Shop Gear</h4>
          </div>
          <div>
            <Link to="/shopgear/addshopgear">
              <AddButton text="Add a Shop Gear" />
            </Link>
          </div>
        </div>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className="flex justify-between mt-2">
              <div className="relative mt-2 lg:w-96">
                <input
                  type="text"
                  placeholder="Search by name..."
                  className="block appearance-none w-full bg-[#E3EBF3] border border-[#1d242e4d] text-gray-700 py-3 pl-4 pr-16 rounded leading-tight focus:outline-none  focus:border-gray-500"
                  value={searchTerm}
                  onChange={(event) => handleSearchChange(event.target.value)}
                  ref={inputRef}
                />
                <div className="flex absolute inset-y-0 right-3 items-center pl-1 pointer-events-none">
                  <svg
                    className="w-5 h-5 text-gray-800 dark:text-black-600"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
              <PaginationNo
                getPrevPage={getPrevPage}
                totalPost={pagination.totalPost}
                getNextPage={getNextPage}
                currentPage={pagination.page}
                post_per_page={pagination.postPerPage}
              />
            </div>
            <div className="mt-2">
              <div className="container text-center py-4">
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                  <table className="w-full border border-[#1d242e4d] text-gray-700 bg-white shadow-lg tablefont">
                    <thead className="text-sm text-gray-700 font-semibold">
                      <tr>
                        <th scope="col" className="w-1/7 py-3 text-lg">
                          <div className="items-center justify-center text-center">
                            Shop Gear ID
                          </div>
                        </th>
                        <th scope="col" className="w-1/7 py-3 text-lg">
                          <div className="flex items-center justify-center">
                            Photo
                          </div>
                        </th>
                        <th scope="col" className="w-1/7 py-3 text-lg">
                          <div className="flex items-center justify-center">
                            Name
                          </div>
                        </th>
                        <th scope="col" className="w-1/7 py-3 text-lg">
                          <div className="flex items-center justify-center">
                            Mo.Number
                          </div>
                        </th>
                        <th scope="col" className="w-1/7 py-3 text-lg">
                          <div className="flex items-center justify-center">
                            City
                          </div>
                        </th>
                        <th scope="col" className="w-1/7 py-3 text-lg">
                          <div className="flex items-center justify-center">
                            Email
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {shopgeardata &&
                        shopgeardata.map((data) => (
                          <tr
                            key={data.id}
                            className="border-t-2 pt-3 border-gray-300 table-text"
                          >
                            <td className="pt-3">
                              <div className="w-1/7 flex justify-center items-center">
                                <CopyId id={data.id} />
                              </div>
                            </td>
                            <Link
                              to={`/editshopgear/${data?.id}`}
                              style={{ display: "contents" }}
                              className="block hover:bg-gray-100"
                            >
                              <td
                                className="text-center w-1/7"
                                style={{ verticalAlign: "middle" }}
                              >
                                <div className="flex items-center justify-center h-full">
                                  <img
                                    className="h-8 w-8 rounded-full ring-1 ring-[#BBBDC0] ring-offset-1"
                                    src={
                                      data.images[0]?.url ||
                                      "https://media.cntraveler.com/photos/57d87670fd86274a1db91acd/16:9/w_2580,c_limit/most-beautiful-paris-pont-alexandre-iii-GettyImages-574883771.jpg"
                                    }
                                    alt="tag"
                                    onError={(e) => {
                                      e.target.onerror = null;
                                      e.target.src =
                                        "https://media.cntraveler.com/photos/57d87670fd86274a1db91acd/16:9/w_2580,c_limit/most-beautiful-paris-pont-alexandre-iii-GettyImages-574883771.jpg";
                                    }}
                                  />
                                </div>
                              </td>
                              <td className="pt-3">
                                <div className="flex items-center w-1/7 text-center mb-2 justify-center">
                                  {data.name}
                                </div>
                              </td>
                              <td className="text-center w-1/7">
                                <div className="flex items-center mb-2 justify-center">
                                  {data.whatsapp || "NA"}
                                </div>
                              </td>
                              <td className="text-center w-1/7">
                                <div className="flex items-center mb-4 justify-center">
                                  {data.city.city_name}
                                </div>
                              </td>
                              <td className="text-center w-1/7">
                                <div className="flex items-center mb-2 justify-center">
                                  {data.email || "NA"}
                                </div>
                              </td>
                            </Link>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
