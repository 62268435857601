import React from "react";
import { Link } from "react-router-dom";
import { PlaceListProvider } from "../../../controllers/PlaceList_controller";
import PlaceComponentList from "../sepcomponents/PlaceComponentList";
function ListOfPlaces() {
  // const Activities = [
  //   "-- Select Activities --",
  //   "Hiking",
  //   "Dancing",
  //   "Fishing",
  //   "Horse Riding",
  //   "Swimming",
  // ];
  // const cities = [
  //   "-- Select Cities --",
  //   "Mumbai",
  //   "Pune",
  //   "Nagpur",
  //   "Nashik",
  //   "Thane",
  //   "Aurangabad",
  //   "New Delhi",
  //   "Bengaluru",
  //   "Mysuru",
  //   "Hubballi",
  //   "Mangaluru",
  //   "Belagavi",
  //   "Chennai",
  //   "Coimbatore",
  //   "Madurai",
  //   "Salem",
  //   "Tiruchirappalli",
  //   "Lucknow",
  //   "Kanpur",
  //   "Agra",
  //   "Varanasi",
  //   "Prayagraj",
  //   "Ahmedabad",
  //   "Surat",
  //   "Vadodara",
  //   "Rajkot",
  //   "Gandhinagar",
  //   "Kolkata",
  //   "Howrah",
  //   "Durgapur",
  //   "Asansol",
  //   "Jaipur",
  //   "Jodhpur",
  //   "Udaipur",
  //   "Kota",
  //   "Thiruvananthapuram",
  //   "Kochi",
  //   "Kozhikode",
  //   "Thrissur",
  //   "Ludhiana",
  //   "Amritsar",
  //   "Jalandhar",
  //   "Visakhapatnam",
  //   "Vijayawada",
  //   "Guntur",
  //   "Nellore",
  //   "Hyderabad",
  //   "Warangal",
  //   "Karimnagar",
  //   "Indore",
  //   "Bhopal",
  //   "Jabalpur",
  //   "Bhubaneswar",
  //   "Cuttack",
  //   "Rourkela",
  //   "Raipur",
  //   "Bhilai",
  //   "Gurgaon",
  //   "Faridabad",
  //   "Panipat",
  //   "Patna",
  //   "Gaya",
  //   "Bhagalpur",
  //   "Ranchi",
  //   "Jamshedpur",
  //   "Guwahati",
  //   "Silchar",
  //   "Dehradun",
  //   "Haridwar",
  //   "Shimla",
  //   "Mandi",
  // ];

  // const placeData = [
  //   {
  //     placeId: 101,
  //     nameOfPlace: "Central Museum",
  //     totalActivityCenter: 3,
  //     totalGuide: 2,
  //     reviews: 5,
  //     rating: 5,
  //   },
  //   {
  //     placeId: 102,
  //     nameOfPlace: "Sarafa Market",
  //     totalActivityCenter: 3,
  //     totalGuide: 3,
  //     reviews: 4,
  //     rating: 4,
  //   },
  //   {
  //     placeId: 103,
  //     nameOfPlace: "Rajwada",
  //     totalActivityCenter: 2,
  //     totalGuide: 2,
  //     reviews: 5,
  //     rating: 5,
  //   },
  //   {
  //     placeId: 104,
  //     nameOfPlace: "Central Museum",
  //     totalActivityCenter: 3,
  //     totalGuide: 3,
  //     reviews: 3,
  //     rating: 3,
  //   },
  //   {
  //     placeId: 105,
  //     nameOfPlace: "Sarafa Market",
  //     totalActivityCenter: 2,
  //     totalGuide: 2,
  //     reviews: 4,
  //     rating: 4,
  //   },
  //   {
  //     placeId: 106,
  //     nameOfPlace: "Rajwada",
  //     totalActivityCenter: 3,
  //     totalGuide: 3,
  //     reviews: 3.5,
  //     rating: 3.5,
  //   },
  //   {
  //     placeId: 107,
  //     nameOfPlace: "Sarafa Market",
  //     totalActivityCenter: 2,
  //     totalGuide: 2,
  //     reviews: 5,
  //     rating: 5,
  //   },
  //   {
  //     placeId: 108,
  //     nameOfPlace: "Rajwada",
  //     totalActivityCenter: 3,
  //     totalGuide: 3,
  //     reviews: 3,
  //     rating: 3,
  //   },
  // ];

  return (
    <>
      <div className="bg-gray-100 ml-64" style={{ backgroundColor: "#EDF1F5",height:"100vh" }}>
        {/* City and Add City button  */}
        <div className="container py-4 px-5">
          <PlaceListProvider>
            <PlaceComponentList />
          </PlaceListProvider>
        </div>
      </div>
    </>
  );
}

export default ListOfPlaces;
