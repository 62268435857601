import React from "react";
import DaysDropdown from "../components/DaysDropdown";
import ComingSoon from "../components/ComingSoon";
export default function Revenue() {
  const BookingData = [
    {
      BookingId: 101,
      Date: "10/08/2023",
      GuideId: 101,
      orderItem: "Subscription",
      NoOfDays: 10,
      orderId: 101,
      Amount: 4000,
      Status: "Rejected",
    },
    {
      BookingId: 102,

      orderItem: "Booking",
      Date: "11/08/2023",
      GuideId: 102,
      NoOfDays: 20,
      orderId: 102,
      Amount: 5000,
      Status: "Confirmed",
    },
    {
      BookingId: 103,
      Date: "13/08/2023",
      GuideId: 103,
      orderItem: "Booking",
      NoOfDays: 15,
      orderId: 404,
      Amount: 4000,
      Status: "Rejected",
    },
    {
      BookingId: 104,
      Date: "14/08/2023",
      GuideId: 104,
      orderItem: "Booking",
      NoOfDays: 10,
      orderId: 141,
      Amount: 6000,
      Status: "Confirmed",
    },
    {
      BookingId: 105,
      Date: "15/08/2023",
      GuideId: 105,
      orderItem: "Subscription",
      NoOfDays: 40,
      orderId: 120,
      Amount: 8000,
      Status: "Processing",
    },
    {
      BookingId: 106,
      Date: "16/08/2023",
      GuideId: 106,
      orderItem: "Subscription",
      NoOfDays: 25,
      orderId: 110,
      Amount: 7000,
      Status: "Rejected",
    },
    {
      BookingId: 107,
      Date: "17/08/2023",
      GuideId: 107,
      orderItem: "Subscription",
      NoOfDays: 50,
      orderId: 151,
      Amount: 2000,
      Status: "Confirmed",
    },
    {
      BookingId: 108,
      Date: "18/08/2023",
      GuideId: 108,
      orderItem: "Subscription",
      NoOfDays: 20,
      orderId: 191,
      Amount: 4000,
      Status: "Processing",
    },
  ];
  const [selectedDays, setSelectedDays] = React.useState("");

  const handleDaysChange = (e) => {
    setSelectedDays(e.target.value);
  };
  const days = [
    { value: "placeholder", label: "Days" },
    { value: "7", label: "7 days" },
    { value: "14", label: "14 days" },
    { value: "30", label: "30 days" },
    { value: "90", label: "90 days" },
  ];
  return (
    <>
      <div className="bg-[#EDF1F5] ml-64">
        {/* heading Content  */}
        <div className="container py-4 px-8">
          <div className="flex justify-between">
            <div className="">
              <h1 className="handleHeader">Revenue</h1>
              <p className="handleDescription py-1">Configure your Revenue.</p>
            </div>

            {/* <DaysDropdown
              text="Days"
              options={days}
              selectedValue={selectedDays}
              onChange={handleDaysChange}
            /> */}
          </div>
          {/* <div className="py-4 flex flex-row ">
            <div className="border-2 border-[#1d242e4d] mr-4 rounded bg-white w-1/2">
              <h2 className="py-3 px-6 font-bold">Total Revenue</h2>
              <div className="border-t-2 mb-14 border-[#1d242e4d]">
                <div className="flex flex-row ">
                  <div className="flex flex-col py-5 px-6 w-1/2">
                    <h1 className="handleBoxTop ">4000/-</h1>
                    <p className="handleBoxPara">INR Initially</p>
                  </div>
                  <div className="flex flex-col py-5 pl-20 w-1/2">
                    <h1 className="handleBoxTop  ">1000/-</h1>
                    <p className="handleBoxPara ">Subscription</p>
                  </div>
                </div>
                <div className="flex flex-row ">
                  <div className="flex flex-col py-5 px-6 w-1/2">
                    <h1 className="handleBoxTop ">5</h1>
                    <p className="handleBoxPara">Total subscription</p>
                  </div>
                  <div className="flex flex-col py-5 pl-20 w-1/2">
                    <h1 className="handleBoxTop  ">5422332</h1>
                    <p className="handleBoxPara ">Total Booking</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-2 border-[#1d242e4d] ml-4 mb-10 rounded bg-white w-1/2">
              <h2 className="py-3 px-6 font-bold">Revenue</h2>
            </div>
          </div> */}
          {/* table */}
          {/* <div className="relative overflow-x-auto shadow-md sm:rounded-md border-2 border-gray-400">
            <table className="w-full h-full text-sm text-left text-gray-500">
              <thead className="text-md text-center text-gray-800 bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-6">
                    Payment Id
                  </th>
                  <th scope="col" className="text-left px-6 py-3">
                    Order Item
                  </th>
                  <th scope="col" className="px-6  py-3">
                    Date
                  </th>
                  <th scope="col" className="px-6  py-3">
                    Transaction Id
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Order Id
                  </th>

                  <th scope="col" className="px-6 py-3">
                    Amount
                  </th>
                  <th scope="col" className="pr-4 py-3">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {BookingData.map((entry, index) => (
                  <tr
                    key={index}
                    className={`border border-[#1D242E4D] bg-white ${
                      index % 2 === 0 ? "even" : "odd"
                    } ${
                      entry.Status === "Processing"
                        ? "text-yellow"
                        : entry.Status === "Rejected"
                        ? "text-red"
                        : "text-green"
                    }`}
                  >
                    <td className="px-6 text-center py-3 font-medium text-gray-900 whitespace-nowrap">
                      {entry.BookingId}
                    </td>
                    <td className="px-6 text-gray-900  py-3">
                      {entry.orderItem}
                    </td>
                    <td className="px-6 text-gray-900 text-center py-3">
                      {entry.Date}
                    </td>
                    <td className="px-6 text-gray-900 text-center py-3">
                      {entry.GuideId}
                    </td>

                    <td className="px-6 text-gray-900 py-3 text-center">
                      {entry.orderId}
                    </td>
                    <td className="px-6 text-gray-900 py-3 text-center">
                      {entry.Amount}
                    </td>
                    <td className="px-6 py-3 text-center">{entry.Status}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div> */}
        </div>
      </div>
      <ComingSoon />
    </>
  );
}
