import React from "react";
import ActivityDetail from "../pages/ActivityDetail";
import Modal from "./Modal";

export default function ActivityTagBox({
  id,
  total_places,
  name,
  image,
  openModal,
}) {
  return (
    <div>
      <button
        className="  flex border border-[#BBBDC0] rounded-sm bg-white"
        style={{ boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)", width: "265px" }}
        onClick={() => openModal(id)}
      >
        <div className="item-center pr-2">
          <img
            className="h-12 w-12 rounded-full ml-2 my-3 ring-1 ring-[#BBBDC0] ring-offset-1"
            src={
              image ||
              "https://www.intrepidtravel.com/adventures/wp-content/uploads/2015/02/Intrepid-Travel-Chile-Patagonia-trekking-002.jpg"
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://www.intrepidtravel.com/adventures/wp-content/uploads/2015/02/Intrepid-Travel-Chile-Patagonia-trekking-002.jpg";
            }}
            alt="act"
          />
        </div>
        <div className="py-3">
          <h3 className="font-poppins font-semibold leading-2 tracking-tight text-gray-900">
            {name.length > 14 ? `${name.slice(0, 14)}...` : name}
          </h3>
          <p className="text-sm leading-2 text-gray-400 text-left">
            {total_places} Places
          </p>
        </div>
      </button>
      <ActivityDetail id={id} />
    </div>
  );
}
