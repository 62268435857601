import { loginApi } from "../constants/urls";
import { setCredentials } from "./credential_controllers";

async function login(userName, password) {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var bodyData = {
      username: userName,
      password: password,
    };
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(bodyData),
      redirect: "follow",
    };

    console.log("Before API Call");
    const response = await fetch(loginApi, requestOptions);
    // console.log("response", response);
    const result = await response.json();
    if (response.ok) {
      // console.log("result", result);
      setCredentials(result.token, result.name);
      return true;
    } else {
      console.log(`Error: ${result.error}`);
      return false;
    }
  } catch (error) {
    console.error(`Error: ${error}`);
    return false;
  }
}

export default login;
