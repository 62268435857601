import React from "react";

export default function TextFeild({
  text,
  type,
  placeholder,
  htmlfor,
  value,
  onChange,
  mandatory,
  name,
  register,
}) {
  return (
    <div className="mb-2 mx-6">
      <div class="">
        <label
          for={htmlfor}
          className="block mb-2 text-md font-medium text-gray-900 "
        >
          {text}{" "}
          {mandatory === false ? (
            <></>
          ) : (
            <i
              className="fa-solid fa-asterisk fa-xs"
              style={{
                color: "#ff0a2f",
                paddingLeft: "3px",
                fontSize: "10px",
                paddingTop: "8px",
              }}
            ></i>
          )}
        </label>
        {type === "number" ? (
          <input
            type={type}
            id={htmlfor}
            className=" bg-slate-200 border-1  text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 border-[#1d242e4d] placeholder-gray-400 "
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              onChange && onChange(e);
              register(name, {
                required: true,
                valueAsNumber: true, // Convert the input value to a number
              });
            }}
          />
        ) : (
          <input
            type={type}
            id={htmlfor}
            className=" bg-slate-200 border-1  text-gray-900 text-sm rounded focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 border-[#1d242e4d] placeholder-gray-400 "
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              onChange && onChange(e);
              register(name);
            }}
          />
        )}
      </div>
    </div>
  );
}
