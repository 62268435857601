import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { usePlaceListContext } from "../../../controllers/PlaceList_controller";

export default function PlaceDropdownRelatedCity({
  selectedPlaces,
  editing,
  onSelect,
  ids,
  city_id,
}) {
  const [searchText, setSearchText] = useState("");
  const [placesData, setPlacesData] = useState([]);
  const updateUrl = () => {
    const newApiUrl = `https://orca-app-pgxkr.ondigitalocean.app/dashboard/city/${city_id}/places/?name=${searchText}&page_size=8`;
    return newApiUrl;
  };

  const { setSearchQuery } = usePlaceListContext();
  const containerRef = useRef(null);
  useEffect(() => {
    const apiUrl = updateUrl();
    async function fetchInfo() {
      try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append(
          "Authorization",
          `Token ${localStorage.getItem("token")}`
        );
        var requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        const response = await fetch(apiUrl, requestOptions);
        const result = await response.json();

        if (response.ok) {
          setPlacesData(result);
          // console.log("dropdowndata", result);
        } else {
          console.log(`Error: ${result.error}`);
        }
      } catch (error) {
        console.error(`Error: ${error}`);
      }
    }

    fetchInfo();
  }, [searchText, city_id]);
  const data = placesData?.results || [];
  const CustomOption = ({ innerProps, label, image }) => (
    <div {...innerProps}>
      <div className="flex py-2 px-4">
        <span className="ml-2">{label}</span>
      </div>
    </div>
  );

  const activityOptions = data?.map((activity) => ({
    value: activity.place_id,
    label: activity.name,
  }));
  useEffect(() => {
    if (editing && ids) {
      const selectedActivities = ids.map((id) => ({
        value: id.place_id,
        label: id.name,
      }));
      onSelect(selectedActivities);
    }
  }, [editing, ids]);
  // useEffect(() => {
  //   if (editing && data) {
  //     const id = data?.map((activity) => activity.id);
  //     if (id) {
  //       onSelect([...selectedPlaces, ...id]);
  //     }
  //   }
  // }, [editing, ids]);

  // const activityOptions = activityData?.state?.activity?.activity || [];
  return (
    <div className="" ref={containerRef}>
      {/* {console.log(ids)} */}
      {/* {console.log("selectedActivities", selectedActivities)} */}

      <div className="flex">
        <label
          htmlFor="dropdown"
          className="block mb-2 text-md font-medium text-gray-900"
        >
          Places
        </label>
      </div>
      <Select
        id="dropdown"
        name="dropdown"
        placeholder="Places"
        options={activityOptions}
        value={selectedPlaces.filter((tag) => ({
          value: tag.value,
          label: tag.label,
        }))}
        onInputChange={(inputValue) => {
          setSearchQuery(inputValue);
        }}
        // onChange={(selectedOptions) => {
        //   let selectedValues = selectedPlaces?.map((option) => option);

        //   selectedValues = selectedOptions.map((option) => ({
        //     value: option.value,
        //     label: option.label,
        //   }));

        //   onSelect(selectedValues);
        // }}
        onChange={(selectedOptions) => {
          const selectedValues = selectedOptions.map((option) => ({
            value: option.value,
            label: option.label,
          }));
          onSelect(selectedValues);
        }}
        isMulti
        styles={{
          control: (provided, state) => ({
            ...provided,
            width: "100%",
            zIndex: "50",
          }),
          multiValue: (provided) => ({
            ...provided,
            background: "#A7DBF5",
            borderRadius: "5px",
            padding: "2px",
            fontSize: "16px",
            fontWeight: "600",
          }),
          multiValueRemove: (provided, state) => ({
            ...provided,
            color: "black",
            backgroundColor: "#E5E5E5",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            marginTop: "3px",
            margin: "4px",
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            color: "black", // Change the color to black
          }),
        }}
        components={{ Option: CustomOption }}
      />
    </div>
  );
}
