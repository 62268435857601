import React, { useEffect, useState } from "react";
import TextBox from "./TextBox";
import CityDropdown from "./CityDropdown";
import CityDropdownWithoutLabel from "./CityDropdownWithoutLabel";

export default function FourBoxDetails({
  heading,
  fitop,
  fibottom,
  stop,
  sbottom,
  ttop,
  tbottom,
  fotop,
  fobottom,
  fetchInfo,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedLat, setEditedLat] = useState("");
  const [editedLon, setEditedLon] = useState("");
  const [editedCity, setEditedCity] = useState("");

  const handleCityNameChange = (e) => {
    setEditedCity(e.target.value);
  };
  useEffect(() => {
    setEditedLat(ttop || "");
    setEditedLon(fotop || "");
    setEditedCity(stop || "");
  }, [ttop, fotop, stop]);
  const handleSaveButtonClick = () => {
    setIsEditing(false);
  };
  const handleSaveAndClose = () => {
    saveDetails();
  };
  const saveDetails = (event) => {
    const formData = {
      lat: editedLat,
      lon: editedLon,
      city: editedCity,
    };

    // console.log(formData);

    fetch(`https://orca-app-pgxkr.ondigitalocean.app/data/place/${fitop}/`, {
      method: "PATCH",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        fetchInfo();
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setIsEditing(false);
  };
  return (
    <div className=" py-4 ">
      <div className="border-2  border-[#1d242e4d] rounded bg-white ">
        <div className="flex justify-between">
          <h2 className="py-3 px-4 font-bold box-heading">{heading}</h2>
          {/* Button for edit details  */}
          <button
            className="text-white px-4 py-2"
            onClick={() => setIsEditing(!isEditing)}
          >
            {isEditing ? (
              <div className="px-3" onClick={handleSaveButtonClick}>
                <i
                  className="fa-solid fa-check"
                  style={{ color: "black" }}
                  onClick={handleSaveAndClose}
                ></i>
              </div>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M0 11.247V13.6112C0 13.8289 0.171087 14 0.388835 14H2.75295C2.85405 14 2.95514 13.9611 3.02514 13.8833L11.5173 5.39897L8.60103 2.48271L0.116651 10.9671C0.0388836 11.0449 0 11.1382 0 11.247ZM13.7725 3.14373C14.0758 2.84044 14.0758 2.35051 13.7725 2.04722L11.9528 0.227468C11.6495 -0.0758228 11.1596 -0.0758228 10.8563 0.227468L9.43313 1.6506L12.3494 4.56687L13.7725 3.14373Z"
                  fill="black"
                />
              </svg>
            )}
          </button>
        </div>
        <div className="pl-2 border-t-2 border-[#1d242e4d]">
          <div className="flex flex-row ">
            <div className="flex flex-col pl-4 w-2/3 py-5">
              <h1
                className="handleBoxTop"
                style={{ position: "relative", top: "2px" }}
              >
                {fitop}
              </h1>
              <p
                className="handleBoxPara font-bold"
                style={{ position: "relative", top: "2px" }}
              >
                {fibottom}
              </p>
            </div>
            <div className="flex flex-col px-5 w-2/3 py-5">
              <h1 className="handleBoxTop">
                {isEditing ? (
                  <>
                    <CityDropdownWithoutLabel
                      value={editedCity}
                      setCityId={setEditedCity}
                      onChange={handleCityNameChange}
                    />
                  </>
                ) : (
                  stop
                )}
              </h1>
              <p className="handleBoxPara  font-bold">{sbottom}</p>
            </div>
            <div className="flex flex-col px-5  w-2/3 py-5">
              <h1 className="handleBoxTop">
                {isEditing ? (
                  <>
                    <input
                      className="border-2 border-[#1d242e4d] rounded bg-white w-2/3 py-1 px-2"
                      type="text"
                      value={editedLat}
                      onChange={(e) => setEditedLat(e.target.value)}
                    />
                  </>
                ) : (
                  ttop
                )}
              </h1>
              <p className="handleBoxPara font-bold">{tbottom}</p>
            </div>
            <div className="flex flex-col w-1/2 py-5 mr-4">
              <h1 className="handleBoxTop ">
                {isEditing ? (
                  <input
                    className="border-2 border-[#1d242e4d] rounded bg-white w-full py-1 px-2 "
                    type="text"
                    value={editedLon}
                    onChange={(e) => setEditedLon(e.target.value)}
                  />
                ) : (
                  fotop
                )}
              </h1>
              <p className="handleBoxPara font-bold">{fobottom}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
